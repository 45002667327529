import { Button, Col, Form, Modal, Row, Select, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_DID_URL, ORG_ID, DOMAIN_ID } from "../../../constants";

function AssignCredentials({
  setIsAssignCredentials,
  isAssignCredentials,
  selectedUserData,
  fetchDirectoryUsers,
  notification,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [issuerData, setIssuerData] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchIssue();
  }, []);

  const handleOk = (e) => {
    console.log(e);
    let payload = {
      ...e,
      domainId: DOMAIN_ID(),
      userId: selectedUserData.id,
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/credential/createADUserCredential`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        notification.open({
          type: "success",
          content: "Credential Assigned Successfully!",
        });
        setIsLoading(false);
        setIsAssignCredentials(false);
        fetchDirectoryUsers();
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to assign credentials",
        });
      });
  };
  const handleCancel = () => {
    setIsAssignCredentials(false);
  };
  const handleChange = (value) => {
    form.setFieldsValue({
      issuerId: value,
    });
  };

  const fetchIssue = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIssuerData(
          res?.data?.map((data) => ({
            value: data.IssuerId,
            label: data.Name,
          }))
        );
      });
  };

  return (
    <>
      <Modal
        centered
        open={isAssignCredentials}
        title="Assign Credentials"
        okText="Submit"
        onOk={form.submit}
        onCancel={handleCancel}
        footer={null}
        width={900}
      >
        <div style={{ marginTop: "2rem" }}>
          <Row>
            <Col span={24}>
              <Form form={form} layout="vertical" onFinish={handleOk}>
                <Form.Item
                  name="issuerId"
                  label="Select Issuer"
                  rules={[
                    {
                      required: true,
                      message: "Select Issuer",
                    },
                  ]}
                >
                  <Select
                    onChange={handleChange}
                    tokenSeparators={[","]}
                    showSearch
                    optionFilterProp="label"
                    options={issuerData}
                    placeholder="Select Issuer"
                  />{" "}
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button type="primary" loading={isLoading} htmlType="submit">
                    Assign
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default AssignCredentials;
