import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  TimePicker,
  message,
  notification,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TRANS_URL,
  ORG_ID,
  DOMAIN_ID,
} from "../../constants";

const VerifyingTransaction = () => {
  const [form] = Form.useForm();
  const [userdata, setUserdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotification, setIsNotification] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();

  const format = "HH";

  useEffect(() => {
    fetchDirectoryUsers();
  }, []);

  const fetchDirectoryUsers = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 1000,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })

      .then((res) => {
        setUserdata(
          res.data.users.map((user) => ({
            value: user.id,
            label: user.emailAddress,
          }))
        );
      })
      .catch((err) => {});
  };
  const handlechange = (val, data) => {
    form.setFieldsValue({
      ldapUser: val.toString(),
    });
  };

  const handleVerify = (e) => {
    let payload = {
      ldapUser: e.ldapUser,
      orgId: ORG_ID(),
      date: e["date"].format("MM-DD-YYYY"),
      hour: Number(e["hour"].format("HH")),
      userId: localStorage.getItem("userId"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TRANS_URL}/VerifyMerkleHash`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        form.resetFields();
        api["success"]({
          message: "Success",
          description: res.data.verified.message,
          placement: "top",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        isNotification.open({
          type: "error",
          content: "Unable to Verify the Transaction,Try again sometimes later",
        });
      });
  };

  return (
    <>
      {setIsNotification}
      <div className="content-conatiner" style={{ marginTop: "1rem" }}>
        <Row>
          {contextHolder}
          <Col span={24}>
            <Form form={form} layout="vertical" onFinish={handleVerify}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item
                    name="ldapUser"
                    label="Select Directory User"
                    rules={[
                      {
                        required: true,
                        message: "Select directory user",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select directory user"
                      tokenSeparators={[","]}
                      showSearch
                      optionFilterProp="label"
                      options={userdata}
                      onChange={handlechange}
                    />{" "}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name="date"
                    label="Select Date"
                    rules={[
                      {
                        required: true,
                        message: "Select Date",
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="hour"
                    label="Select Hours"
                    rules={[
                      {
                        required: true,
                        message: "Select hours",
                      },
                    ]}
                  >
                    <TimePicker format={format} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  <Space>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                      Verify
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VerifyingTransaction;
