import { useState, useEffect } from "react";
import { Row, Col, Steps, Button, Input, Alert, Select } from "antd";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, DOMAIN_ID, ORG_ID } from "../../../constants";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { Link } from "react-router-dom";
const { Step } = Steps;
const { Option } = Select;
const ADStep = () => {
  const [current, setCurrent] = useState(0);
  const [stepOneSecondVisible, setStepOneSecondVisible] = useState(false);
  const [orgUrl, setOrgUrl] = useState();
  const [adminAccount, setAdminAccount] = useState();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [domainUser, setDomainUser] = useState();
  const [account, setAccount] = useState();
  const [accountName, setAccountName] = useState();
  const [proxy, setProxy] = useState();
  const [password, setPassword] = useState();
  const [appUrl, setAppUrl] = useState();
  const [apiKey, setApiKey] = useState();
  const [selectPairs, setSelectPairs] = useState([{ select1: "Email", select2: "Username" }]);
  const [options, setOptions] = useState(["Email", "Username", "Department", "Group", "Subgroup"]);
  const handleAddPair = () => {
    setSelectPairs([...selectPairs, { select1: "Email", select2: "Username" }]);
  };

  const handleSelect1Change = (value, index) => {
    const updatedPairs = [...selectPairs];
    updatedPairs[index].select1 = value;
    setSelectPairs(updatedPairs);
  };

  const handleSelect2Change = (value, index) => {
    const updatedPairs = [...selectPairs];
    updatedPairs[index].select2 = value;
    setSelectPairs(updatedPairs);
  };

  const handleRemovePair = (index) => {
    const updatedPairs = [...selectPairs];
    updatedPairs.splice(index, 1);
    setSelectPairs(updatedPairs);
  };

  const handleSave = () => {
    // You can access the selected values in selectPairs array here
    console.log("Selected values:", selectPairs);
  };

  useEffect(() => {
    fetchDirectoryUsers();
  }, []);

  const fetchDirectoryUsers = async function () {
    let data;
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 1000,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
    };

    let res = await axios.post(`${REACT_APP_API_PAM_URL}/users/listAll`, pageDetails, {
      headers: {
        "X-Authorization": AUTH_TOKEN(),
        withCredentials: true,
      },
    });

    if (res.data.users) {
      data = res.data.users.map((user, i) => ({
        index: i,
        id: user.id,
        firstName: user.firstName,
        phone: user.phoneNumber,
        lastName: user.lastName,
        email: user.emailAddress,
        status: user.status,
        apps: user.applications ? user.applications.map((app) => Number(app.id)) : [],
        otpMethod: user.otpMethod,
        metaData: user.metadata,
        groups: user.groups,
        dc: "abc",
        cn: "asif ali",
        ou: "penang",
        ou1: "penang",
        domain: "Authnull-India",
      }));
    } else {
      data = [];
    }
    setUsers(data);
  };
  const openNewWindow = () => {
    // Define the URL of the page you want to open in the new window/tab
    const url = "https://dev-21408270-admin.AuthNull.com/admin/getting-started"; // Replace with your desired URL

    // Use window.open to open a new window/tab
    window.open(url, "_blank");
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const FirstContent = () => {
    const [subStep, setSubStep] = useState(0);

    const nextSubStep = () => {
      setSubStep(subStep + 1);
    };

    return (
      <Row>
        <Col span={20}>
          <Row style={{ marginTop: "2rem" }}>
            <Col span={24}>
              <Row>
                <h3>Download the AuthNull Active Directory agent</h3>
              </Row>
              <Row>
                <p>
                  The AuthNull Active Directory agent is a lightweight,secure connector that allows
                  AuthNull to integrate with your Active Directory domain. The agent enables
                  AuthNull features such as user import and delegated authentication.
                </p>
              </Row>
              <Row>
                <Button
                  type="primary"
                  onClick={() => {
                    setStepOneSecondVisible(true);
                    openNewWindow();
                  }}
                >
                  Download Agent
                </Button>
              </Row>
            </Col>
            {/* Your content for the first step */}
          </Row>
          {stepOneSecondVisible && (
            <Row style={{ marginTop: "2rem" }}>
              <Col span={24}>
                <Row>
                  <h3>
                    Install the AuthNull Active Directory agent on your Host Machine using these
                    values :
                  </h3>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row>
                      <p className="search-label" style={{ marginBottom: "1rem" }}>
                        Your AuthNull Organization URL
                      </p>
                    </Row>
                    <Row>
                      <Input
                        placeholder="http://dev-18631498.kota.com"
                        allowClear
                        onChange={(e) => {
                          setOrgUrl(e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row>
                      <p className="search-label" style={{ marginBottom: "1rem" }}>
                        An AuthNull administrator account
                      </p>
                    </Row>
                    <Row>
                      <Input
                        placeholder="asif@kloudone.com"
                        allowClear
                        onChange={(e) => {
                          setAdminAccount(e.target.value);
                        }}
                      />
                    </Row>
                    <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      <Alert
                        style={{ width: "100%" }}
                        message="Waiting for the agent installer to update this page..."
                        type="info"
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }} justify="space-between">
                  <Col></Col>
                  <Col>
                    <a style={{ fontSize: "16px" }} onClick={() => next()}>
                      Skip
                    </a>
                  </Col>
                </Row>
              </Col>
              {/* Your content for the first step */}
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  const SecondContent = () => {
    return (
      <Row>
        {/* Your content for the second step */}
        <Col span={20}>
          <Row style={{ marginTop: "2rem" }}>
            <h3>Connect to AD server</h3>
          </Row>
          <Row>
            <Col span={24}>
              <Row gutter={32}>
                <Col span={12}>
                  <Row>
                    <p className="search-label">
                      Select a domain user for the authnul AD Agent to run as:
                    </p>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Domain User"
                      labelInValue={true}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(option) => setDomainUser(option.label)}
                      options={users.map((user) => ({
                        value: user.id,
                        label: user.email,
                      }))}
                    />
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <p className="search-label">Create an account (Recommended)</p>
                  </Row>
                  <Row>
                    <Input
                      placeholder=""
                      allowClear
                      onChange={(e) => {
                        setAccount(e.target.value);
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <Row>
                    <p className="search-label">Account Name</p>
                  </Row>
                  <Row>
                    <Input
                      placeholder=""
                      allowClear
                      onChange={(e) => {
                        setAccountName(e.target.value);
                      }}
                    />
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <p className="search-label">Password</p>
                  </Row>
                  <Row>
                    <Input
                      placeholder=""
                      allowClear
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <Row>
                    <p className="search-label">Proxy</p>
                  </Row>
                  <Row>
                    <Input
                      placeholder=""
                      allowClear
                      onChange={(e) => {
                        setProxy(e.target.value);
                      }}
                    />
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <p className="search-label">App Url</p>
                  </Row>
                  <Row>
                    <Input
                      placeholder=""
                      allowClear
                      onChange={(e) => {
                        setAppUrl(e.target.value);
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <p className="search-label">API Key</p>
                  </Row>
                  <Row>
                    <Input
                      placeholder=""
                      allowClear
                      onChange={(e) => {
                        setApiKey(e.target.value);
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }} justify="space-between">
            <Col></Col>
            <Col>
              <Button
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  prev();
                }}
              >
                Prev
              </Button>
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const ThirdContent = () => {
    return (
      <Row>
        {/* Your content for the second step */}
        <Col span={20}>
          <Row style={{ marginTop: "2rem" }}>
            <h3>Import Users</h3>
          </Row>
          <Row>
            <Col span={24}>
              <Row gutter={32}>
                <Col span={12}>
                  <Row>
                    <p className="search-label">Select Organizational Unit</p>
                  </Row>
                  <Row>
                    <Select style={{ width: "100%" }} showSearch placeholder="" labelInValue={true}>
                      <Option value="0">kloudone</Option>
                      <Option value="1">authnull</Option>
                      <Option value="2">Authnull</Option>
                    </Select>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <p className="search-label">DID Username Format (skip this if not necessary)</p>
                  </Row>
                  <Row>
                    <Select style={{ width: "100%" }} showSearch placeholder="" labelInValue={true}>
                      <Option value="0">Email Address</Option>
                      <Option value="1">SAM Account Name</Option>
                      <Option value="2">User Principal Name (UPN)</Option>
                    </Select>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }} justify="space-between">
            <Col></Col>
            <Col>
              <Button
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  prev();
                }}
              >
                Prev
              </Button>
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  const LastContent = () => {
    return (
      <Row>
        <Col span={20}>
          <Row style={{ marginTop: "2rem" }}>
            <h3>Map Fields: These fields are mapped from AD to Authnull</h3>
          </Row>
          <Row>
            <Col span={24}>
              {selectPairs.map((pair, index) => (
                <Row gutter={16} key={index} style={{ marginBottom: "8px" }}>
                  <Col span={11}>
                    <Select
                      value={pair.select1}
                      style={{ width: "100%" }}
                      onChange={(value) => handleSelect1Change(value, index)}
                    >
                      {options.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={11}>
                    <Select
                      value={pair.select2}
                      style={{ width: "100%" }}
                      onChange={(value) => handleSelect2Change(value, index)}
                    >
                      {options.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={2} style={{ textAlign: "center" }}>
                    {index > 0 && ( // Ensure there is at least one dropdown pair before showing delete icon
                      <DeleteOutlined
                        onClick={() => handleRemovePair(index)}
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginTop: "5px",
                        }}
                      />
                    )}
                  </Col>
                </Row>
              ))}
              <Row gutter={16} style={{ justifyContent: "space-between" }}>
                <Col span={6}>
                  <Button type="dashed" onClick={handleAddPair}>
                    Add More +
                  </Button>
                </Col>
                <Col span={6}>
                  <Button type="default" style={{ marginLeft: "1rem" }}>
                    Reconfigure
                  </Button>
                </Col>
              </Row>
              <Row style={{ justifyContent: "space-between", marginTop: "2rem" }}>
                <Col></Col>
                <Col span={4}>
                  <Link to={"/directory/activeDirectory"}>
                    <Button type="primary">Save</Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const steps = [
    {
      title: "Install Agent",
      content: <FirstContent />,
    },
    {
      title: "Connect to AD servers",
      content: <SecondContent />,
    },
    {
      title: "Import Users",
      content: <ThirdContent />,
    },
    {
      title: "Field Mapping",
      content: <LastContent />,
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <Row className="content-conatiner">
      <Col span={20}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Integrate Active Directory</h2>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Steps current={current} items={items} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>{steps[current].content}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ADStep;
