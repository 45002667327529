import { Button, Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Log from "./log";
import Merkle from "./merkle";
import Ethereum from "./ethereum";
import VerifyingTransaction from "./VerifyingTransaction";

const TransactionLog = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const navigate = useNavigate();

  const items = [
    {
      key: 1,
      label: "Transaction Logs",
      children: <Log />,
    },
    {
      key: 2,
      label: "Merkle Hash",
      children: <Merkle />,
    },
    {
      key: 3,
      label: "Ethereum Address",
      children: <Ethereum />,
    },
    {
      key: 4,
      label: "Verifying Transaction",
      children: <VerifyingTransaction />,
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Transaction Log</h2>
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TransactionLog;
