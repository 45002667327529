import { Button, Modal, Row, Col, Input, Table, message } from "antd";
import React, { useState, useEffect } from "react";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../constants";
import { fetchEPMUsers, fetchEndpointGroups } from "../../../common/functions";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";

import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const Addusertogroup = ({ IsaddUsertogroup, setIsaddUsertogroup, selectedRecords }) => {
  const [epmUserData, setEPMUserData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const PAGESIZE = 5;
  let [selectedInstance, setSelectedInstances] = useState([]);
  const [notification, setNotification] = message.useMessage();

  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setIsLoading(true);

    fetchEPMUsers({
      pageId: currentPage,
      pageSize: PAGESIZE,
      search,
      filterBy: "",
      filterValue: "",
    }).then(({ totalRecords, epmUsers, res }) => {
      setTotalCount(totalRecords);
      setEPMUserData(
        epmUsers.map((data, index) => ({
          ...data,
          key: data.epmUserId,
          id: index + 1,
        }))
      );
      setIsLoading(false);
      epmUsers.forEach((user) => {
        groupInstanceIds.forEach((id) => {
          if (user.instances.map((i) => i.instanceId).includes(id)) {
            selectedInstance.push(user.userId);
          }
        });
      });
      selectedInstance = Array.from(new Set(selectedInstance));
    });

    fetchInstanceGroupDetail();
  }, [debouncedValue, currentPage]);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  let groupInstanceIds = [];
  const fetchInstanceGroupDetail = async () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      groupId: Number(selectedRowKeys),
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      pageId: 1,
      pageSize: 10,
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/fetchInstanceGroupDetail`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      groupInstanceIds = res?.data?.map((i) => parseInt(i.instanceId));
    } catch (err) {}
  };

  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");

    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 10,
      token: AUTH_TOKEN(),
      filter: search,
      // filter: {
      //   filterBy: "groupName",
      //   value: searchText ? searchText : "",
      // },
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      let Records = res?.data?.totalCount;
      if (res.data.groups) {
        let endpointGroupData = res?.data?.groups?.map((grp, i) => ({
          index: i,
          id: grp?.groupId,
          groupName: grp?.groupName,
          count: grp?.usersCount,
          // credential: grp.CredentialExpiry,
        }));
      } else {
      }
    } catch (err) {
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
      }
    }
  };
  const handleSaveUser = async () => {
    let updatedAssignedUsers = selectedRowKeys;

    // console.log({ selectedRowIds, selectedInstance });
    let usersToRemove = [];
    let usersToAdd = [];
    let found = false;
    console.log({ updatedAssignedUsers, selectedInstance });
    for (let i = 0; i < selectedInstance.length; i++) {
      found = false;
      for (let j = 0; j < updatedAssignedUsers.length; j++) {
        if (updatedAssignedUsers.includes(selectedInstance[i])) {
          found = true;
          break;
        }
      }
      if (!found) {
        usersToRemove.push(selectedInstance[i]);
      }
    }

    console.log("Users to remove", usersToRemove);

    for (let i = 0; i < updatedAssignedUsers.length; i++) {
      found = false;
      for (let j = 0; j < selectedInstance.length; j++) {
        if (selectedInstance[j] === updatedAssignedUsers[i]) {
          found = true;
          break;
        }
      }
      if (!found) usersToAdd.push(updatedAssignedUsers[i]);
    }

    console.log("Users to add:", usersToAdd);

    // Remove the users
    let usersRemovePayload = {
      domainId: DOMAIN_ID(),
      groupId: Number(selectedRowKeys),
      action: "remove",
      userIds: usersToRemove,
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    let usersAddPayload = {
      domainId: DOMAIN_ID(),
      groupId: Number(selectedRowKeys),
      action: "add",
      userIds: usersToAdd,
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    try {
      if (usersToRemove.length > 0)
        await axios.post(
          `${REACT_APP_PAM_API}/instanceGroup/assignUsersToEndpointGroup`,
          usersRemovePayload,
          {
            headers: {
              "X-Authorization": AUTH_TOKEN(),
              withCredentials: true,
            },
          }
        );
      if (usersToAdd.length > 0)
        await axios.post(
          `${REACT_APP_PAM_API}/instanceGroup/assignUsersToEndpointGroup`,
          usersAddPayload,
          {
            headers: {
              "X-Authorization": AUTH_TOKEN(),
              withCredentials: true,
            },
          }
        );
    } catch (err) {
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
      } else if (err.response.status == 401) {
        logOutUser();
        notification.open({
          type: "error",
          content: "Error",
        });
      } else {
        notification.open({
          type: "error",
          content: "Unable to Add User to Group",
        });
      }
    }
  };

  const columns = [
    { field: "user", headerName: "User Name", key: "user", flex: 1 },
    { field: "usertype", headerName: "User Type", key: "usertype", flex: 1 },
    { field: "type", headerName: "Credential Type", key: "type", flex: 1 },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selectedRowKeys:", selectedRowKeys, "selectedRows: ", selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (e, ef) => {
      console.log(e, ef);
    },
  };

  const handleOk = () => {
    handleSaveUser();
    fetchEndpointGroup();
    setTimeout(() => {
      setIsaddUsertogroup(false);
    }, 3000);
  };
  const handleCancel = () => {
    setIsaddUsertogroup(false);
  };

  return (
    <Modal
      open={IsaddUsertogroup}
      title={`Add Users - ${selectedRecords.groupName}`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Save
        </Button>,
      ]}
      width={1000}
    >
      <Row>
        <Col span={24}>
          <Row style={{ margin: "1rem 0" }} className="search-box-container">
            <Col span={6}>
              <div>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* Ant design Table */}
              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={epmUserData}
                rowSelection={rowSelection}
                pagination={{
                  pageSize: PAGESIZE,
                  total: totalCount,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
              /> */}

              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={epmUserData}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                page={paginationParams.current}
                pageSize={paginationParams.pageSize}
                onPaginationModelChange={(newPage) => {
                  setPaginationParams({
                    ...paginationParams,
                    current: newPage.page,
                  });
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default Addusertogroup;
