import { Card, Checkbox, Col, Progress, Row } from "antd";
import React, { useState } from "react";
import "../../../styles/onboard.scss";
import { Link } from "react-router-dom";

const PamServiceAccount = () => {
  const [percent, setPercent] = useState(33.2);

  const [checkStatus, setCheckStatus] = useState({
    mfa: true,
    window: true,
    linux: false,
    addUser: false,
    setupPolicy: false,
    viewServiceLog: false,
  });

  localStorage.setItem("OnboardStatus", checkStatus);
  const [selectedCheck, setSelectedCheck] = useState(false);

  const handleChangeStatus = (e, val) => {
    setCheckStatus((prevStatus) => ({
      ...prevStatus,
      [val]: e,
    }));
    if (e) {
      setSelectedCheck(e);

      setPercent((prevPercent) => {
        const newPercent = prevPercent + 16.6;
        if (newPercent > 100) {
          return 100;
        }
        return newPercent;
      });
    } else {
      setSelectedCheck(e);

      setPercent((prevPercent) => {
        const newPercent = prevPercent - 16.6;
        if (newPercent < 0) {
          return 0;
        }
        return newPercent;
      });
    }
  };
  localStorage.setItem("onboardServiceAccount", percent);

  return (
    <>
      <div className="onboarding-container">
        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <h2 className="title"> Let’s setup privileged access for service accounts</h2>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: "2rem" }}>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[<Progress percent={percent} showInfo={false} />]}
                >
                  <h3 className="card-header-h3">
                    Privileged access for service accounts
                    <br /> {percent}% complete{" "}
                  </h3>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Configure Tenant</h3>{" "}
                <Row style={{ margin: "1rem 0" }}>
                  <Col>
                    {" "}
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.mfa}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "mfa");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={`/onboard/setup/tenantConfig?type=pamLocal`}>
                      Configure MFA{" "}
                    </Link>{" "}
                  </Col>
                </Row>
                <Row style={style.row}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.addUser}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "addUser");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={`/directory/users?mfa=true`}>
                      Add user and setup wallets{" "}
                    </Link>{" "}
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Endpoints</h3>
                <Row style={style.row}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.window}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "window");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={"/endpoint/addEndpoint?type=windows"}>
                      Configure windows endpoints
                    </Link>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.linux}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "linux");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={"/endpoint/addEndpoint?type=linux"}>
                      Configure linux endpoint
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Service accounts</h3>
                <Row style={style.row}>
                  <Col>
                    {" "}
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.setupPolicy}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "setupPolicy");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={"/serviceAccount/addServiceAccount?type=onboard"}>
                      Setup service account policies{" "}
                    </Link>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.viewServiceLog}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "viewServiceLog");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={"/serviceAccount?type=onboard"}>
                      View service account authentication logs
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
const style = {
  a: {
    fontSize: "18px",
  },
  row: {
    marginBottom: "1rem",
  },
};
export default PamServiceAccount;
