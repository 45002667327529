import {
  Col,
  Row,
  Select,
  Table,
  Dropdown,
  Button,
  Input,
  message,
  Space,
  Alert,
  Tag,
  Spin,
  Tooltip,
  Cascader,
  DatePicker,
  Badge,
  Modal,
} from "antd";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_PAM_V1_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_PAM_API,
} from "../../constants";
import { logOutUser } from "../../common";

import { MoreOutlined } from "@ant-design/icons";
import { Link, Navigate, Outlet } from "react-router-dom";
import moment from "moment-timezone";
import { useState, useMemo, useEffect } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Logs = () => {
  const endpoints = [];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [modal, setModal] = useState(false);
  const [log, setLog] = useState();
  const [userNameFilter, setUserNameFilter] = useState("");
  const [componentFilter, setComponentFilter] = useState("AuthService");
  const [actionFilter, setActionFilter] = useState("Authentication");
  const [notification, setNotification] = message.useMessage();
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [user, setUser] = useState([]);
  const [endpointData, setEndpointData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filteredEndpoints, setFilteredEndpoints] = useState(endpoints);
  const handleApplyFilter = (filterParameter, filterValue) => {
    // Check if a filter with the same parameter already exists
    const existingFilterIndex = appliedFilters.findIndex(
      (filter) => filter.filterParameter === filterParameter
    );
  
    if (existingFilterIndex !== -1) {
      // Update the existing filter's value
      setAppliedFilters((prevFilters) => {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingFilterIndex] = {
          ...updatedFilters[existingFilterIndex],
          filterValue: filterValue,
        };
        return updatedFilters;
      });
    } else {
      // Add a new filter
      const newFilter = { filterParameter, filterValue };
      setAppliedFilters((prevFilters) => [...prevFilters, newFilter]);
    }
  };

  const handleRemoveFilter = (filterParameter, filterValue) => {
    setAppliedFilters((prevFilters) =>
      prevFilters.filter(
        (filter) =>
          filter.filterParameter !== filterParameter ||
          filter.filterValue !== filterValue
      )
    );
  };
  useEffect(() => {
    // Filter the endpoints based on applied filters
    const filteredData = endpoints.filter((endpoint) =>
      appliedFilters.every(({ filterParameter, filterValue }) => {
        // Implement your custom filtering logic based on filterParameter and filterValue
        // For simplicity, I'm using includes() for string matching
        return String(endpoint[filterParameter]).includes(filterValue);
      })
    );

    // Update the state with the filtered data
    setFilteredEndpoints(filteredData);
  }, [appliedFilters, endpoints]);

  const getCurrentOption = (filterParameter) => {
    const filter = appliedFilters.find(
      (filter) => filter.filterParameter === filterParameter
    );
    return filter ? filter.filterValue : undefined;
  };

  const fetchAdUser = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setUser(res?.data?.users);
        console.log(res.data.users);
      })
      .catch((err) => {
        if (err.response.data.message === "Invalid credentials") {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch users",
          });
        }
      });
  };
  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");
    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      const originalGroups = res?.data?.groups;

      // Adding an 'id' property to each object
      const groupsWithId = originalGroups.map((group, index) => ({
        ...group,
        id: index + 1, // You can use a different logic to generate IDs if needed
      }));
      setEndpointGroups(groupsWithId);
    } catch (err) {
      if (
        err?.response?.data?.message === "Credentials are invalid" ||
        err?.response?.status === 401
      ) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Error fetching Endpoint Groups",
        });
      }
    }
  };
  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpointData(res?.data?.instances);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload)
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        const outputArray = inputArray.map((item) => {
          const dcParts = item.domain
            .split(",")
            .map((part) => part.split("=")[1]);
          const domain = dcParts.join(".");
          return { id: item.id, value: domain };
        });

        console.log(
          res.data.data.adDomains.map((dom) => dom?.domain),
          outputArray
        );

        setDomainData(outputArray);
      });
  };

  useEffect(() => {
    fetchEndpointGroup();
    fetchAdUser();
    fetchInstance();
    listDomain();
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const downloadJsonAsFile = (data) => {
    // Convert JSON data to string
    const jsonString = JSON.stringify(data, null, 2);

    // Create a Blob with the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    // Set the filename for the download
    link.download = "userData.json";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };
  const columns = [
    {
      headerName: "User Name",
      dataIndex: "policyName",
      field: "policyName",
      width: 210,
      sortable: true,
    },
    {
        headerName: "Protocol",
        field: "protocol",
        width: 100,
        sortable: true,
        renderCell: (val) => (
          <>
            <Tag style={{ cursor: "pointer" }} key={val.row.protocol}  onClick={() => handleApplyFilter("protocol", val.row.protocol)}>
              {val.row.protocol}
            </Tag>
          </>
        ),
      },
    {
      dataIndex: "lastChange",
      headerName: "Created At",
      field: "lastChange",
      width: 210,
      sortable: true,
    },

    {
      dataIndex: "component",
      headerName: "Component",
      field: "component",
      width: 210,
      sortable: true,
    },
    {
      dataIndex: "action",
      headerName: "Action",
      field: "action",
      width: 210,
      sortable: true,
    },

    {
      headerName: "Endpoint User",
      field: "endpointUser",
      width: 120,
      sortable: true,
      renderCell: (val) => {
        const tagsArray =
          val.row.endpointUser && val.row.endpointUser.split(",");

        return (
          <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
            {tagsArray &&
              tagsArray.map((tag, index) => (
                <Space size={[0, "small"]} wrap>
                  <Tag key={index} onClick={() => handleApplyFilter("endpointUser", tag)}>{tag.trim()}</Tag>
                </Space>
              ))}
          </div>
        );
      },
    },
    {
      headerName: "Destination",
      field: "destinationEndpoint",
      width: 350,
      sortable: true,
      renderCell: (val) => {
        const tagsArray =
          val.row.destinationEndpoint && val.row.destinationEndpoint.split(",");

        return (
          <div>
            {tagsArray &&
              tagsArray.map((tag, index) => (
                <Tag style={{ cursor: "pointer" }} key={index} onClick={() => handleApplyFilter("destinationEndpoint", tag)}>
                  {tag.trim()}
                </Tag>
              ))}
          </div>
        );
      },
    },
    // {
    //   headerName: "Source Endpoint",
    //   field: "sourceEndpoint",
    //   width: 140,
    //   sortable: true,
    //   renderCell: (val) => {
    //     const tagsArray = val.row.sourceEndpoint.split(",");

    //     return (
    //       <div>
    //         {tagsArray.map((tag, index) => (
    //           <Tag
    //             style={{ cursor: "pointer" }}
    //             key={index}
    //             onClick={() => handleApplyFilter("sourceEndpoint", tag)}
    //           >
    //             {tag.trim()}
    //           </Tag>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Ou",
      field: "adOu",
      width: 130,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag style={{ cursor: "pointer" }} key={val.row.adOu}  onClick={() => handleApplyFilter("adOu", val.row.adOu)}>
            {val.row.adOu}
          </Tag>
        </>
      ),
    },
    {
      headerName: "Domain",
      field: "adDomain",
      width: 140,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag style={{ cursor: "pointer" }} key={val.row.adDomain}  onClick={() => handleApplyFilter("adDomain", val.row.adDomain)}>
            {val.row.adDomain}
          </Tag>
        </>
      ),
    },
    // {
    //   headerName: "Auth",
    //   field: "auth",
    //   width: 100,
    //   sortable: true,
    //   renderCell: (val) => {
    //     return (
    //       <div>
    //         <Tag
    //           style={{ cursor: "pointer" }}
    //           key={val.row.auth}
    //           onClick={() => handleApplyFilter("auth", val.row.auth)}
    //         >
    //           {val.row.auth}{" "}
    //         </Tag>
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Risk",
      field: "risk",
      width: 120,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>
            {val.row.id % 5
              ? "Low risk"
              : val.row.id % 2
              ? "Medium risk"
              : "High risk"}
          </span>
        </>
      ),
    },
    {
      headerName: "Access Type",
      field: "policyType",
      width: 130,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>{val.row.policyType}</span>
        </>
      ),
    },

   
    {
      headerName: "View / Download",
      field: "logs",

      width: 210,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>
            <a
              onClick={() => {
                setModal(true);
                setLog(val.row.platform);
              }}
            >
              View
            </a>{" "}
            /{" "}
            <a
              onClick={() => {
                setLog(val.row.platform);
                downloadJsonAsFile(val.row.platform);
              }}
            >
              Download
            </a>
          </span>
        </>
      ),
    },
  ];
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Authentication Logs</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row
                style={{ marginBottom: "2rem", gap: "2rem" }}
                className="search-box-container"
              >
                <Col span={4}>
                  <p className="search-label">Username</p>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search username "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleApplyFilter("adUser", e)}
                    value={getCurrentOption("adUser")}

                    // onDeselect={(e) => handleRemoveFilter("user", e)}
                    // mode="multiple"
                  >
                    {user &&
                      user.length > 0 &&
                      user.map((endpoint) => (
                        <Option value={endpoint.emailAddress}>
                          {endpoint.emailAddress}
                        </Option>
                      ))}
                  </Select>
                </Col>
                <Col span={4}>
                  <p className="search-label">Access Type</p>
                  <Select
                    style={{ width: 200 }}
                    placeholder="Search Access Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleApplyFilter("policyType", e)}
                    // onDeselect={(e) => handleRemoveFilter("policyType", e)}
                    value={getCurrentOption("policyType")}
                    // mode="multiple"
                    options={[
                      { value: "local", label: "Local User" },
                      { value: "Active Directory", label: "Active Directory" },
                      { value: "Service Account", label: "Service Account" },
                    ]}
                  />
                </Col>

                <Col span={4}>
                  <p className="search-label">Ou</p>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search OU"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleApplyFilter("adOu", e)}
                    value={getCurrentOption("adOu")}
                  >
                    {/* {domainData &&
                          domainData.length > 0 &&
                          domainData.map((endpoint) => (
                            <Option value={endpoint.domain}>{endpoint.domain}</Option>
                          ))} */}
                    <Option value="ou=rider-park">ou=rider-park</Option>
                    <Option value="ou=rider-park 2">ou=rider-park 2</Option>
                    <Option value="ou=rider-park 3">ou=rider-park 3</Option>
                  </Select>
                </Col>
                <Col span={4}>
                  <p className="search-label">Domain</p>
                  <Select
                    showSearch
                    // mode="multiple"
                    allowClear
                    style={{ width: 200 }}
                    placeholder="Search Domain"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleApplyFilter("adDomain", e)}
                    value={getCurrentOption("adDomain")}
                  >
                    {domainData &&
                      domainData.length > 0 &&
                      domainData.map((endpoint) => (
                        <Option value={endpoint.value}>{endpoint.value}</Option>
                      ))}
                  </Select>
                </Col>
                <Col span={4}>
                  <p className="search-label">Endpoint</p>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search Endpoint "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      handleApplyFilter("destinationEndpoint", e)
                    }
                    value={getCurrentOption("destinationEndpoint")}
                    allowClear
                  >
                    {endpointData &&
                      endpointData.length > 0 &&
                      endpointData.map((endpoint) => (
                        <Option
                          value={endpoint.hostName || endpoint.instanceId}
                        >
                          {endpoint.hostName}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              {appliedFilters.length > 0 && (
                <div className="filter-tags">
                  <h4
                    style={{
                      marginTop: "0rem",
                      marginBottom: "0.5rem",
                      fontWeight: "400",
                    }}
                  >
                    Applied Filters:
                  </h4>
                  {appliedFilters.map(({ filterParameter, filterValue }) => (
                    <>
                      <Tag
                        key={`${filterParameter}:${filterValue}`}
                        closable
                        onClose={() =>
                          handleRemoveFilter(filterParameter, filterValue)
                        }
                      >
                        {`${filterParameter}: ${filterValue}`}
                      </Tag>
                    </>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
              
                rows={filteredEndpoints}
                columns={columns}
                pageSize={10}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none", width: "100%", overflow: "auto" }}
                sx={{
                  "& .MuiDataGrid-detailPanel": {
                    overflow: "visible",
                  },
                }}
           
                  components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Modal
          open={modal}
          title={`Authentication Log`}
          onCancel={() => setModal(false)}
          footer={null}
          width={900}
        >
          <Row>
            <Col span={24}>
              <pre>{JSON.stringify(log, null, 2)}</pre>
            </Col>
          </Row>
        </Modal>
      </Row>
    </>
  );
};

export default Logs;
