import { Button, Modal, Row, Col, Input, Table, message, Select, Form } from "antd";
import React, { useState, useEffect } from "react";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  REACT_APP_API_DID_URL,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import { fetchEPMUsers } from "../../../common/functions";
import axios from "axios";
import { logOutUser } from "../../../common";
import FormItem from "antd/es/form/FormItem";
import { useDebounce } from "../../../common/debounce";

const AssignEndpointUser = ({ Isassignwallet, setIsassignwallet, selectedRecords }) => {
  const [epmUserData, setEPMUserData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 5,
  });
  const [issuer, setIssuer] = useState([]);
  const [walletData, setwalletData] = useState([]);
  const [selectedissuer, setselectedIssuer] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [form] = Form.useForm();
  const debouncedValue = useDebounce(search, 500);

  const handleEndpointChange = (value, data) => {
    console.log(value);
    setselectedIssuer(value);
    form.setFieldsValue({
      IssuerId: value,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selectedRowKeys:", selectedRowKeys, "selectedRows: ", selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      form.setFieldValue({
        userSelect: selectedRowKeys,
      });
    },
  };

  useEffect(() => {
    fetchWallets();
  }, [debouncedValue, paginationParams]);
  useEffect(() => {
    fetchIssuerList();
  }, []);

  const fetchIssuerList = () => {
    let requestData = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIssuer(
          res?.data?.map((data) => ({
            id: data.IssuerId,
            text: data.Name,
          }))
        );
      });
  };

  const fetchWallets = async (e) => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageNumber: paginationParams.current,
      pageSize: paginationParams.pageSize,
      filter: search,
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_API_DID_URL}/walletService/walletUserList`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );

      setTotalCount(res?.data?.count);
      setwalletData(
        res?.data?.users?.map((user) => ({
          id: user.userId,
          email: user.emailAddress ? user.emailAddress : "-",
          name: user.username ? user.username : "-",
          walletUrl: user.walletUrl,
          noOfcredential: user.noOfcredential,
          userId: user.walletId,
        }))
      );
      setIsLoading(false);
      console.log(walletData);
    } catch (err) {
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
      }
    }
  };

  const handleWalletassign = () => {
    console.log(selectedRecords);
    let walletData = {
      domainId: DOMAIN_ID(),
      issuerId: selectedissuer,
      epmUsers: [
        {
          epmUserId: selectedRecords.userId,
          instanceId: Number(selectedRecords.instances.map((instance) => instance.instanceId)),
        },
      ],
      users: selectedRowKeys,
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_PAM_API}/checkouts/assignEpmUserToWallet`, walletData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: "User Assigned to wallet!",
        });
      })
      .catch((err) => {
        if (err.response.data.message == "Credentials are invalid") {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid!",
          });
        } else if (err.response.status == 401) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Error Assigning user to wallet!",
          });
        }
        notification.open({
          type: "error",
          content: "Failed to assign User to wallet!",
        });
      });
  };

  const columns = [
    { dataIndex: "name", title: "Name", key: "name" },
    { dataIndex: "email", title: "Email Address", key: "email" },
    { dataIndex: "walletUrl", title: "URL", key: "walletUrl" },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setwalletData([]);
    }
  };

  const handleOk = () => {
    setIsLoading(true);
    handleWalletassign();
    setTimeout(() => {
      setIsassignwallet(false);
    }, 3000);
    setIsLoading(false);
  };
  const handleCancel = () => {
    setIsassignwallet(false);
  };

  return (
    <Modal
      open={Isassignwallet}
      title={`Assign To Wallet- ${selectedRecords.userName}`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={1000}
    >
      {setNotification}
      <Row>
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={handleOk}>
            <Row>
              <Col span={12}>
                <p className="search-label">Search</p>
                <Input
                  placeholder="Search"
                  allowClear
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (paginationParams.current !== 1) {
                      setPaginationParams({ current: 1, pageSize: 10 });
                    }
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "1rem 0" }} className="search-box-container">
              <Col span={24}>
                <Form.Item
                  name="IssuerId"
                  label="Issuer"
                  rules={[
                    {
                      required: true,
                      message: "Select Issuer!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Issuer"
                    showSearch
                    optionFilterProp="label"
                    options={issuer.map((issuer) => ({
                      value: issuer.id,
                      label: issuer.text,
                    }))}
                    onChange={handleEndpointChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <FormItem
              name="userSelect"
              rules={[
                {
                  required: true,
                  message: "Select User to Assign!",
                },
              ]}
            >
              <Row>
                <Col span={24}>
                  <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={walletData}
                    rowSelection={rowSelection}
                    onChange={handleTableChange}
                    pagination={{
                      showTotal: (total) => `Total ${total} items`,
                      ...paginationParams,
                      total: totalCount,
                    }}
                    rowKey="id"
                  />
                </Col>
              </Row>
            </FormItem>
            <FormItem>
              <Row justify={"end"}>
                <Col span={2} style={{ marginTop: "1rem" }}>
                  <Button key="cancel" type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Col>
                <Col span={2} style={{ marginTop: "1rem" }}>
                  <Button htmlType="submit" type="primary" loading={isLoading}>
                    Assign
                  </Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AssignEndpointUser;
