import { Button, Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ListPasswordPolicy from "./ListPasswordPolicy";
import ListSshKeys from "./ListSshKeys";

function CredebtialRotation() {
  const [currentTab, setCurrentTab] = useState("1");
  const navigate = useNavigate();

  const items = [
    { key: "1", label: "Passwords", children: <ListPasswordPolicy /> },
    {
      key: "2",
      label: "SSH Keys",
      children: <ListSshKeys />,
    },
  ];
  const handleAddPolicy = () => {
    if (currentTab === "1") {
      navigate("/credentialRotation/addPassword");
    } else {
      navigate("/credentialRotation/addSshKeys");
    }
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Credential Rotation Policies</h2>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleAddPolicy()}>
                Add {currentTab === "1" ? "Password" : "SSH Key"} Policy
              </Button>
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CredebtialRotation;
