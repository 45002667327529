import React, { useEffect, useState } from "react";
import { Col, Row, Select, Button, Input, Form, Checkbox, Spin, Space, message } from "antd";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_DID_URL,
} from "../../../constants";
import { logOutUser } from "../../../common";

const CreateConnection = () => {
  const [endpoint, setEndpoint] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jumpServer, setJumpServer] = useState([]);
  const [protocol, setProtocol] = useState("SSH");
  const [epmUser, setEpmUser] = useState([]);
  const [ipAddress, setIPAddress] = useState();
  const [isNotification, setNotification] = message.useMessage();
  const [walletData, setWalletData] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    fetchInstance();
    fetchJumpserver();
    fetchEPMUsers();
    fetchWallet();
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serverId = queryParams.get("serverName");
  const JumpserverId = queryParams.get("id");
  const serverIp = queryParams.get("ip");

  const handleSave = (e) => {
    console.log(e?.jumpServerId);

    let payload = {
      ...e,
      jumpServerId: serverId ? Number(JumpserverId) : e?.jumpServerId,
      domains: [],
      // ipAddress: serverId ? serverIp : ipAddress,
      ipAddress: 0,
      password: e.password ? e.password : "",
      url: "http://ad",
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/connections/createConnections`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        setIsLoading(false);
        navigate(-1);
        isNotification.open({
          type: "success",
          content: "Connection created successfully",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        isNotification.open({
          type: "error",
          content: "Unablr to create connection",
        });
      });
  };

  const fetchInstance = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoint(
          res.data.instances.map((ins) => ({
            value: ins.instanceId,
            label: ins.hostName,
            ipAddress: ins.publicIp,
          }))
        );
      });
  };
  const fetchJumpserver = () => {
    let pageDetails = {
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/jumpserver/listJumpServer`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setJumpServer(
          res?.data?.jumpServers.map((data) => ({
            value: data.id,
            label: data.serverName,
            ipAddress: data.publicIpAddress,
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          console.log("Error");
        }
      });
  };
  const portocolData = [
    {
      value: "ssh",
      label: "SSH",
    },
    {
      value: "rdp",
      label: "RDP",
    },
    {
      value: "vnc",
      label: "VNC",
    },
    {
      value: "telnet",
      label: "Telnet",
    },
  ];

  const fetchEPMUsers = (id) => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 500,
      token: AUTH_TOKEN(),
      instanceIds: id ? id : [],
      filter: {
        filterBy: "",
        value: "",
      },
      search: "",
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEpmUser(
          res?.data?.epmUsers?.map((user) => ({
            value: user?.userId,
            label: user?.userName,
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          console.log(err);
        }
      });
  };
  const fetchWallet = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageNumber: 1,
      pageSize: 100,
      filter: "",
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        console.log(res);
        setWalletData(
          res?.data?.users?.map((user) => ({
            value: user.walletId,
            label: user.emailAddress ? user.emailAddress : "-",
          }))
        );
      });
  };
  const handlejumpSererChange = (value, data) => {
    const IPADDRESS = data?.ipAddress;
    setIPAddress(IPADDRESS);
    form.setFieldsValue({
      ipAddress: IPADDRESS,
    });
  };

  const handleChange = (value, data) => {
    setProtocol(data?.label);
    form.setFieldsValue({
      port: value === "ssh" ? 22 : value === "rdp" ? 3389 : value === "vnc" ? 5900 : 22,
      password: value === "rdp" ? "test@123" : value === "vnc" ? "test@123" : "",
    });
  };
  const handleEndpointChange = (value, data) => {
    form.setFieldsValue({
      endpoints: value,
      hostname: data[0]?.label,
    });
    fetchEPMUsers([value[0]]);
  };

  return (
    <Row className="content-conatiner">
      {setNotification}
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className="title">{serverId ? "Assign Connection" : "Create Connection"}</h2>
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
          initialValues={
            serverId && {
              jumpServerId: { value: JumpserverId, label: serverId },
            }
          }
        >
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item
                    name="jumpServerId"
                    label="Jump Server"
                    rules={[
                      {
                        required: true,
                        message: "Select Jump Server!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      tokenSeparators={[","]}
                      placeholder="Search Jump Server"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? "").includes(input)}
                      filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase()}
                      options={jumpServer}
                      onChange={handlejumpSererChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="endpoints"
                    label="Select Endpoint to connect to"
                    rules={[
                      {
                        required: true,
                        message: "Select Endpoints!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Search Endpoints"
                      mode="multiple"
                      tokenSeparators={[","]}
                      showSearch
                      optionFilterProp="label"
                      options={endpoint}
                      onChange={handleEndpointChange}
                    />{" "}
                  </Form.Item>
                </Col>
                {/* <Col span={8}>
                  <Form.Item
                    name="ipAddress"
                    label="IP Address"
                    rules={[
                      {
                        required: false,
                        message: "IP Address is required!",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>{" "}
                </Col> */}
                {/* <Col span={8}>
                  <Form.Item
                    name="domains"
                    label="AD Domains"
                    rules={[
                      {
                        required: true,
                        message: "Select Domains!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      tokenSeparators={[","]}
                      placeholder="Search Domains"
                      options={[
                        {
                          value: 1,
                          label: "Domain 1",
                        },
                        {
                          value: 2,
                          label: "Domain 2",
                        },
                        {
                          value: 3,
                          label: "Domain 3",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col> */}
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item
                    name="protocol"
                    label="Protocol"
                    rules={[
                      {
                        required: true,
                        message: "Select Protocol!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Protocol"
                      tokenSeparators={[","]}
                      options={portocolData}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="port"
                    label="Port"
                    rules={[
                      {
                        required: false,
                        message: "Port is required!",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item
                    name="epmUserId"
                    label={`${protocol}` + ` ` + `Username`}
                    rules={[
                      {
                        required: true,
                        message: "Select Username!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Search to Select Username"
                      tokenSeparators={[","]}
                      options={epmUser}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="userId"
                    label="Wallet User"
                    rules={[
                      {
                        required: true,
                        message: "Select wallet user!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Search to Select wallet user"
                      tokenSeparators={[","]}
                      options={walletData}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
              <Row>
                {protocol === "RDP" || protocol === "VNC" ? (
                  <Col span={8}>
                    <Form.Item
                      label={`${protocol}` + " " + `Password`}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item style={{ marginTop: "1rem" }}>
                    <Checkbox disabled>
                      automatically enable each ldap user to connect to server (embed their
                      credentials)
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: "1rem" }}>
            <Col span={8}>
              <Space>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default CreateConnection;
