import {
  Col,
  Row,
  Select,
  Table,
  Dropdown,
  Button,
  Input,
  message,
  Space,
  Alert,
  Tag,
  Spin,
  Tooltip,
  Cascader,
  DatePicker,
} from "antd";

import { MoreOutlined } from "@ant-design/icons";
import { Link, Navigate, Outlet } from "react-router-dom";
import moment from "moment-timezone";
import { useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const JitPolicy = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const endpoints = Array.from({ length: 1000 }, (_, i) => ({
    id: i + 1,
    policyName: `policy-${i + 1}`,
    lastChange: moment().add(i, "days").format("MM/DD/YYYY hh:mma ZZ"),
    platform: i % 3 === 0 ? "AWS" : i % 3 === 1 ? "Azure" : "GCP",
    count: (i % 15) + 1, // Vary the count for diversity
    description: `Security policy ${i + 1}`,
    status: i % 2 === 0 ? "active" : "inactive",
  }));

  const columns = [
    {
      headerName: "Policy Name",
      dataIndex: "policyName",
      field: "policyName",
      flex: 1,
      sortable: true,
    },
    {
      dataIndex: "lastChange",
      headerName: "Last Change",
      field: "lastChange",
      flex: 1.5,
      sortable: true,
    },

    {
      headerName: "Platform",
      dataIndex: "platform",
      field: "platform",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Rule Count",
      dataIndex: "count",
      field: "count",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Description",
      dataIndex: "description",
      field: "description",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Status",
      dataIndex: "status",
      field: "status",
      flex: 1,
      sortable: true,
    },

    {
      headerName: "Logs",
      field: "logs",
      renderCell: (_value, record) => <a>View Logs</a>,
      flex: 1,
      sortable: true,
    },
  ];
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">JIT Access Policies</h2>
            </Col>
            <Col>
              <Link to={"add"}>
                <Button type="primary">Add Policies</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Tenant</p>
                      <Input placeholder="Tenant Name" value={"Authnull"} />
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Range</p>
                      <RangePicker />
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Policy Name</p>
                      <Input
                        placeholder="Search..."
                        onSearch={(value) => console.log(value)}
                        addonAfter={null}
                      />
                    </Col>

                    <Col span={4}>
                      <p className="search-label">Description</p>
                      <Input
                        placeholder="Search..."
                        onSearch={(value) => console.log(value)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={endpoints}
                paginationMode='server'
                columns={columns}
                pageSize={10}
                checkboxSelection
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default JitPolicy;
