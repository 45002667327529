import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  Tag,
  Button,
  Select,
  Switch,
  DatePicker,
  Space,
  Modal,
  Tabs,
  Empty,
  message,
  Badge,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { DateRange, Expand } from "@mui/icons-material";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_PAM_V1_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_PAM_API,
} from "../../constants";
import { logOutUser } from "../../common";
import moment from "moment-timezone";
import {
  DEFAULT_GRID_AUTOSIZE_OPTIONS,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const AuthenticationPolicy = () => {
  const [sortModel, setSortModel] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 10,
    page: 0,
  });
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [type2, setType2] = useState("option1");
  const [type3, setType3] = useState("option1");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [user, setUser] = useState([]);
  const [endpointData, setEndpointData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [states, setStates] = useState("Rejected");
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    brokerId: false,
    status: false,
  });
  // const [expand, setExpand] = React.useState(DEFAULT_GRID_AUTOSIZE_OPTIONS.expand);

  // const autosizeOptions = {
  //   includeHeaders,
  //   includeOutliers,
  //   outliersFactor: Number.isNaN(parseFloat(outliersFactor))
  //     ? 1
  //     : parseFloat(outliersFactor),
  //   expand,
  // };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStates("Rejected");
  };
  useEffect(() => {
    fetchRequest();
  }, [appliedFilters, sortModel]);
  useEffect(() => {
    fetchEndpointGroup();
    fetchAdUser();
    fetchInstance();
    listDomain();
  }, []);

  console.log(sortModel);
  const fetchAdUser = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setUser(res?.data?.users);
        console.log(res.data.users);
      })
      .catch((err) => {
        if (err.response.data.message === "Invalid credentials") {
          // logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch users",
          });
        }
      });
  };
  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");
    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      const originalGroups = res?.data?.groups;

      // Adding an 'id' property to each object
      const groupsWithId = originalGroups.map((group, index) => ({
        ...group,
        id: index + 1, // You can use a different logic to generate IDs if needed
      }));
      setEndpointGroups(groupsWithId);
    } catch (err) {
      if (
        err?.response?.data?.message === "Credentials are invalid" ||
        err?.response?.status === 401
      ) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        // logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Error fetching Endpoint Groups",
        });
      }
    }
  };
  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpointData(res?.data?.instances);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          // logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchRequest = (page) => {
    let REQUEST_HEADER;
    let requestPayload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      policyType: "local",
      filters: appliedFilters ? appliedFilters : [],
      requestId: "",
      page: page,
      limit: currentPageModal.pageSize,
      sort: {
        field: sortModel ? sortModel[0]?.field : "",
        order: sortModel ? sortModel[0]?.sort : "",
      },
    };
    if (appliedFilters.length !== 0) {
      REQUEST_HEADER = `${REACT_APP_API_DID_URL}/policyService/FilterAccessRequest`;
    } else {
      REQUEST_HEADER = `${REACT_APP_API_DID_URL}/policyService/AccessRequest`;
    }
    axios
      .post(REQUEST_HEADER, requestPayload)

      .then((res) => {
        console.log(res.data.data.length);
        setRequestData(res?.data?.data);
        // setTotalCount(res?.data?.data?.length);
        setTotalCount(res.data.total_pages * 10);
        setCurrentPageModal({
          pageSize: 10,
          page: res.data.page - 1,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSortModelChange = (val) => {
    setSortModel(val);
  };

  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios.post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload).then((res) => {
      const inputArray = res.data.data.adDomains;

      const outputArray = inputArray.map((item) => {
        const dcParts = item.domain.split(",").map((part) => part.split("=")[1]);
        const domain = dcParts.join(".");
        return { id: item.id, value: domain };
      });

      console.log(
        res.data.data.adDomains.map((dom) => dom?.domain),
        outputArray
      );

      setDomainData(outputArray);
    });
  };
  const columns = [
    {
      headerName: "", // Empty header for the expand/collapse column
      field: "expand",
      width: 10,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleRowExpand(params);
            showModal();
          }}
        >
          {isModalVisible ? "[-]" : "[+]"}
        </Link>
      ),
    },
    {
      headerName: "Username",
      field: "adUser",
      width: 210,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val.row.adUser.split(",");

        return (
          <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <Tag key={index} onClick={() => handleApplyFilter("adUser", tag)}>
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Endpoint User",
      field: "endpointUser",
      width: 120,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val.row.endpointUser.split(",");

        return (
          <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <Space size={[0, "small"]} wrap>
                <Tag key={index} onClick={() => handleApplyFilter("endpointUser", tag)}>
                  {tag.trim()}
                </Tag>
              </Space>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Destination",
      field: "destinationEndpoint",
      width: 350,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val.row.destinationEndpoint.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <Tag
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => handleApplyFilter("destinationEndpoint", tag)}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    // {
    //   headerName: "Source Endpoint",
    //   field: "sourceEndpoint",
    //   width: 140,
    //   sortable: true,
    //   renderCell: (val) => {
    //     const tagsArray = val.row.sourceEndpoint.split(",");

    //     return (
    //       <div>
    //         {tagsArray.map((tag, index) => (
    //           <Tag
    //             style={{ cursor: "pointer" }}
    //             key={index}
    //             onClick={() => handleApplyFilter("sourceEndpoint", tag)}
    //           >
    //             {tag.trim()}
    //           </Tag>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Ou",
      field: "adOu",
      width: 130,
      sortable: true,
      renderCell: (val) => (
        <>
          <div style={{ whiteSpace: "normal" }}>
            <Tag
              style={{ cursor: "pointer" }}
              key={val.row.adOu}
              onClick={() => handleApplyFilter("adOu", val.row.adOu)}
            >
              {val.row.adOu}
            </Tag>
          </div>
        </>
      ),
    },
    {
      headerName: "Domain",
      field: "adDomain",
      width: 140,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag
            style={{ cursor: "pointer" }}
            key={val.row.adDomain}
            onClick={() => handleApplyFilter("adDomain", val.row.adDomain)}
          >
            {val.row.adDomain}
          </Tag>
        </>
      ),
    },
    // {
    //   headerName: "Auth",
    //   field: "auth",
    //   width: 100,
    //   sortable: true,
    //   renderCell: (val) => {
    //     return (
    //       <div>
    //         <Tag
    //           style={{ cursor: "pointer" }}
    //           key={val.row.auth}
    //           onClick={() => handleApplyFilter("auth", val.row.auth)}
    //         >
    //           {val.row.auth}{" "}
    //         </Tag>
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Risk",
      field: "risk",
      width: 120,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>{val.row.id % 5 ? "Low risk" : val.row.id % 2 ? "Medium risk" : "High risk"}</span>
        </>
      ),
    },
    {
      headerName: "Access Type",
      field: "policyType",
      width: 130,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>{val.row.policyType}</span>
        </>
      ),
    },
    {
      headerName: "Auth Count",
      field: "authCount",
      width: 130,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>{val.row.authCount}</span>
        </>
      ),
    },

    {
      headerName: "Protocol",
      field: "protocol",
      width: 100,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag
            style={{ cursor: "pointer" }}
            key={val.row.protocol}
            onClick={() => handleApplyFilter("protocol", val.row.protocol)}
          >
            {val.row.protocol}
          </Tag>
        </>
      ),
    },
    {
      headerName: "Created At",
      field: "timestamp",
      width: 160,
      sortable: true,
      renderCell: (val) => {
        const formatString = "MM-DD-YYYY HH:mm [PST]";
        const formattedDate = moment.unix(val.row.timestamp).format(formatString);
        return <span>{formattedDate}</span>;
      },
    },
    {
      headerName: "Status",
      field: "policyStatus",
      renderCell: (value) => (
        <>
          {value.row.policyStatus === "Approved" ? (
            <Badge
              onClick={() => {
                handleRowExpand(value);
                showModal();
              }}
              style={{
                cursor: "pointer",
              }}
              status="success"
              text="Approved"
            />
          ) : value.row.policyStatus === "Denied " ? (
            <Badge
              onClick={() => {
                handleRowExpand(value);
                showModal();
              }}
              style={{
                cursor: "pointer",
              }}
              status="error"
              text="Denied"
            />
          ) : (
            <Badge
              onClick={() => {
                handleRowExpand(value);
                showModal();
              }}
              style={{
                cursor: "pointer",
              }}
              status="warning"
              text={value.row.policyStatus}
            />
          )}
        </>
      ),
    },
  ];

  const handleApplyFilter = (filter, value) => {
    // if (value) {
    //   const newFilter = { filter, value };
    // setAppliedFilters([...appliedFilters, newFilter]);
    // setSelectedFilters([...selectedFilters, { filterParameter: filter, filterValue: value }]);

    // Update searchText immediately
    // setSearchText(value);
    // }
    let filterParameter = filter;
    let filterValue = value;
    let obj = [
      {
        filterParameter: filterParameter,
        filterValue: filterValue,
      },
    ];
    let tempAppliedFilter = appliedFilters;
    let tempfilter = tempAppliedFilter.concat(obj);
    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
      const existingFilter = acc.find(
        (filter) => filter.filterParameter === current.filterParameter
      );

      if (!existingFilter) {
        acc.push(current);
      }

      return acc;
    }, []);
    setAppliedFilters(uniqueFilters);
  };

  const handleRemoveFilter = (filter, value) => {
    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) => filterItem.filterParameter !== filter || filterItem.filterValue !== value
      )
    );
  };

  const getCurrentOption = (type) => {
    console.log({ type, appliedFilters });
    const currentOption = appliedFilters.filter((opt) => opt.filterParameter === type);
    return currentOption[0]?.filterValue ?? null;
  };

  const handleRowExpand = (params) => {
    console.log(params);
    setSelectedRow(params.row);
    setExpandedRows((prevRows) =>
      prevRows.includes(params.id.toString())
        ? prevRows.filter((row) => row !== params.id.toString())
        : [...prevRows, params.id.toString()]
    );
  };

  const isRowExpandable = (params) => {
    // Example condition for expandable rows
    return true;
  };

  const handleExpandSave = () => {
    const requestId = selectedRow.id;
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      requestId: requestId.toString(),
      status: states,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/UpdateUserAccessRequest`, payload)
      .then((res) => {
        setIsModalVisible(false);
        fetchRequest();
        setTimeout(() => {
          notification.open({
            type: "success",
            content: `${selectedRow?.adUser ?? selectedRow?.endpointUser} Request ${
              states === "Rejected" ? "rejected" : "approved"
            } successfully`,
          });
        });
      }, 3000)
      .catch((err) => {
        setTimeout(() => {
          notification.open({
            type: "error",
            content: "Unable to update the status",
          });
        }, 3000);
      });
  };
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Requests</h2>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Username</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search username "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleApplyFilter("adUser", e)}
                        value={getCurrentOption("adUser")}

                        // onDeselect={(e) => handleRemoveFilter("user", e)}
                        // mode="multiple"
                      >
                        {user &&
                          user.length > 0 &&
                          user.map((endpoint) => (
                            <Option value={endpoint.emailAddress}>{endpoint.emailAddress}</Option>
                          ))}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Access Type</p>
                      <Select
                        style={{ width: 200 }}
                        placeholder="Search Access Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleApplyFilter("policyType", e)}
                        // onDeselect={(e) => handleRemoveFilter("policyType", e)}
                        value={getCurrentOption("policyType")}
                        // mode="multiple"
                        options={[
                          { value: "local", label: "Local User" },
                          {
                            value: "Active Directory",
                            label: "Active Directory",
                          },
                          {
                            value: "Service Account",
                            label: "Service Account",
                          },
                        ]}
                      />
                    </Col>

                    <Col span={4}>
                      <p className="search-label">Ou</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search OU"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleApplyFilter("adOu", e)}
                        value={getCurrentOption("adOu")}
                      >
                        {/* {domainData &&
                          domainData.length > 0 &&
                          domainData.map((endpoint) => (
                            <Option value={endpoint.domain}>{endpoint.domain}</Option>
                          ))} */}
                        <Option value="Endpoint 1">ou=rider-park</Option>
                        <Option value="Endpoint 2">ou=rider-park 2</Option>
                        <Option value="Endpoint 3">ou=rider-park 3</Option>
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Domain</p>
                      <Select
                        showSearch
                        // mode="multiple"
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Search Domain"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleApplyFilter("adDomain", e)}
                        value={getCurrentOption("adDomain")}
                      >
                        {domainData &&
                          domainData.length > 0 &&
                          domainData.map((endpoint) => (
                            <Option value={endpoint.value}>{endpoint.value}</Option>
                          ))}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Endpoint</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search Endpoint "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleApplyFilter("destinationEndpoint", e)}
                        value={getCurrentOption("destinationEndpoint")}
                        allowClear
                      >
                        {endpointData &&
                          endpointData.length > 0 &&
                          endpointData.map((endpoint) => (
                            <Option value={endpoint.hostName || endpoint.instanceId}>
                              {endpoint.hostName}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: "2rem" }}>
                <Col>
                  {appliedFilters.length > 0 && (
                    <div className="filter-tags">
                      <h4
                        style={{
                          marginTop: "0rem",
                          marginBottom: "0.5rem",
                          fontWeight: "400",
                        }}
                      >
                        Applied Filters:
                      </h4>
                      {appliedFilters.map(({ filterParameter, filterValue }) => (
                        <>
                          <Tag
                            key={`${filterParameter}:${filterValue}`}
                            closable
                            onClose={() => handleRemoveFilter(filterParameter, filterValue)}
                          >
                            {`${filterParameter}: ${filterValue}`}
                          </Tag>
                        </>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DataGrid
                    rows={requestData}
                    columns={columns}
                    rowCount={totalCount}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    isRowExpandable={isRowExpandable}
                    getRowId={(row) => row.id.toString()}
                    expandedRows={expandedRows}
                    onRowExpand={handleRowExpand}
                    paginationMode="server"
                    page={currentPageModal.current}
                    pageSize={currentPageModal.pageSize}
                    pageSizeOptions={[10]}
                    // onPageChange={(newPage) => {
                    //   setPaginationParams({
                    //     ...paginationParams,
                    //     current: newPage.page,
                    //   });
                    // }}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                    }}
                    paginationModel={currentPageModal}
                    onPaginationModelChange={(params) => {
                      fetchRequest(params.page + 1);
                    }}
                    filterMode="server"
                    onFilterModelChange={(e) =>
                      handleApplyFilter(e.items[0].field, e.items[0].value)
                    }
                    disableRowSelectionOnClick
                    style={{ border: "none", width: "100%", overflow: "auto" }} // Set a fixed height for the grid
                    rowThreshold={0}
                    pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                    sx={{
                      "& .MuiDataGrid-detailPanel": {
                        overflow: "visible",
                      },
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      noResultsOverlay: CustomNoRowsOverlay,
                    }}
                    // autosizeOptions={expand}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {isModalVisible && (
          <Modal
            title={
              <span style={{ fontSize: 18 }}>
                Showing policy for{" "}
                <span style={{ color: "#1677FF" }}>
                  {` ${selectedRow?.adUser ? selectedRow?.adUser : selectedRow?.endpointUser}`}
                </span>
              </span>
            }
            footer={[
              <Popconfirm
                placement="topRight"
                // title={`${states === "Rejected" ? "Reject" : "Approve"} the Request`}
                title={
                  <span>
                    Are you sure to {`${states === "Rejected" ? "reject" : "approve "}`} this
                    request <br /> for {`${selectedRow?.adUser ?? selectedRow?.endpointUser} ?`}
                  </span>
                }
                onConfirm={handleExpandSave}
                onCancel={(e) => console.log(e)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  key="submit"
                  type="primary"
                  // onClick={handleExpandSave}
                  danger={states === "Rejected"}
                  style={
                    states !== "Rejected" ? { background: "#52C41A" } : { background: "#dc1a1d" }
                  }
                >
                  {states === "Rejected" ? "Reject" : "Approve"}
                </Button>
              </Popconfirm>,
            ]}
            width={900}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            className="authModal"
          >
            <Row>
              <Col span={24}>
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col span={12}>
                    <p className="search-label">Domain</p>
                    <Select
                      showSearch
                      placeholder="Select Domain"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      mode="multiple"
                      defaultValue={selectedRow?.adDomain}
                    >
                      {domainData &&
                        domainData.length > 0 &&
                        domainData.map((endpoint) => (
                          <Option value={endpoint.value}>{endpoint.value}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <p className="search-label">Ou</p>
                    <Select
                      showSearch
                      placeholder="OU"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      mode="multiple"
                      defaultValue={selectedRow?.adOu}
                    >
                      <Option value="ou=rider-park">ou=rider-park</Option>
                      <Option value="ou=rider-park 2">ou=rider-park 2</Option>
                      <Option value="ou=rider-park 3">ou=rider-park 3</Option>
                      {/* Add other options here */}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col span={12}>
                    <p className="search-label">Users</p>
                    <Select
                      placeholder="Select Users"
                      onChange={(e) => setType3(e)}
                      defaultValue={"option1"}
                    >
                      <Option value="option1">Users</Option>
                      <Option value="option2">Users Matching Wild Card</Option>
                      <Option value="option3">Apply To Whole Tenant</Option>
                    </Select>
                  </Col>
                  {type3 === "option1" && (
                    <Col span={12}>
                      <p className="search-label">Directory Users</p>
                      <Select mode="multiple" defaultValue={selectedRow?.adUser}>
                        {user &&
                          user.length > 0 &&
                          user.map((val) => (
                            <Option value={val.emailAddress || val.id}>{val.emailAddress}</Option>
                          ))}
                      </Select>
                    </Col>
                  )}
                  {type3 === "option2" && (
                    <Col span={12}>
                      <p className="search-label">Wild Card</p>
                      <Input
                        placeholder="Enter Wild Card"
                        onSearch={(value) => console.log(value)}
                      />
                    </Col>
                  )}
                </Row>
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col span={12}>
                    <p className="search-label">Endpoints and Endpoint Groups</p>
                    <Select
                      placeholder="Select Endpoint And Endpoint Groups"
                      defaultValue={"option1"}
                      onChange={(e) => setType2(e)}
                    >
                      <Option value="option1">Endpoints</Option>
                      <Option value="option2">Endpoint Groups</Option>
                      <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                      <Option value="option4">Endpoint Matching Wild Card</Option>
                      <Option value="option5">Apply To Whole Tenant</Option>
                    </Select>
                  </Col>
                  {type2 === "option1" && (
                    <Col span={12}>
                      <p className="search-label">Endpoints List</p>
                      <Select mode="multiple" defaultValue={selectedRow?.destinationEndpoint}>
                        {endpointData &&
                          endpointData.length > 0 &&
                          endpointData.map((endpoint) => (
                            <Option value={endpoint.hostName || endpoint.instanceId}>
                              {endpoint.hostName}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                  )}{" "}
                  {type2 === "option2" && (
                    <Col span={12}>
                      <p className="search-label">Endpoints Groups List</p>
                      <Select mode="multiple">
                        {endpointGroups &&
                          endpointGroups?.length > 0 &&
                          endpointGroups.map((grp) => (
                            <Option value={grp?.groupName || grp?.groupId}>{grp?.groupName}</Option>
                          ))}
                      </Select>
                    </Col>
                  )}
                  {(type2 == "option3" || type2 == "option4") && (
                    <Col span={12}>
                      <p className="search-label">Wild Card</p>
                      <Input
                        placeholder="Enter Wild Card"
                        onSearch={(value) => console.log(value)}
                      />
                    </Col>
                  )}
                </Row>
                <Row gutter={16} style={{ margin: "1rem 0 2rem 0" }}>
                  <Col span={12}>
                    {" "}
                    <p className="search-label">Protocol</p>
                    <Select defaultValue={selectedRow?.protocol} mode="multiple">
                      <Option value="LDAP">LDAP X</Option>
                      <Option value="NTLM">NTLM X</Option>
                      <Option value="Kerberos">Kerberos X</Option>
                      <Option value="Radius">Radius X</Option>
                      <Option value="RDP">RDP X</Option>
                      <Option value="SSH">SSH X</Option>
                    </Select>
                  </Col>
                  <Col span={12}>
                    <p className="search-label">Status</p>
                    <Select value={states} onChange={(e) => setStates(e)}>
                      <Option value="Approved">Approve</Option>
                      <Option value="Rejected">Reject</Option>
                      {/* <Option value="Requested">In Review</Option> */}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal>
        )}
      </Row>
    </>
  );
};

export default AuthenticationPolicy;
