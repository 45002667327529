import { Button, Col, Form, Input, Row, Select, Space, Spin, Steps, theme, message } from "antd";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import {
  AUTH_TOKEN,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  DOMAIN_ID,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";

const AddEndpointUser = () => {
  const [current, setCurrent] = useState(0);
  const [walletData, setWalletData] = useState([]);
  const [endpointData, setEndpointData] = useState([]);
  const [issuerData, setIssuerData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [userInfoSave, setUserInfoSave] = useState(null);
  const [createdUserId, setCreatedUserId] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const navigate = useNavigate();
  const { token } = theme.useToken();

  useEffect(() => {
    fetchWallet();
    fetchEndpoint();
    fetchGrp();
    fetchIssue();
  }, []);

  const steps = [
    {
      title: "User Information",
    },
    {
      title: "Assignment",
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const prev = () => {
    setCurrent(current - 1);
  };

  const fetchWallet = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageNumber: 1,
      pageSize: 100,
      filter: "",
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        console.log(res);
        setWalletData(
          res?.data?.users?.map((user) => ({
            value: user.walletId,
            label: user.emailAddress ? user.emailAddress : "-",
          }))
        );
      });
  };

  const fetchEndpoint = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpointData(
          res.data.instances.map((ins) => ({
            value: ins.instanceId,
            label: ins.hostName,
            ipAddress: ins.publicIp,
          }))
        );
      });
  };

  const fetchGrp = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: "",
      },
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setGroupData(
          res.data.groups.map((grp, i) => ({
            value: grp.id,
            label: grp.groupName,
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch Groups",
          });
        }
      });
  };

  const fetchIssue = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIssuerData(
          res?.data?.map((data) => ({
            value: data.IssuerId,
            label: data.Name,
          }))
        );
      });
  };

  const contentStyle = {
    lineHeight: "260px",
    // textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    margin: `16px 0 `,
    padding: `1rem`,
  };
  const [form] = Form.useForm();
  const [fields, setFieldsData] = useState([{ name: ["userType"], value: "local" }]);

  const handleCredType = (value) => {
    form.setFieldsValue({
      credType: value,
    });
  };
  const handlePrivilegedUser = (value, data) => {
    console.log(data, value);
    form.setFieldsValue({
      privilegedUser: value,
    });
  };
  const handleInfoSubmit = (e) => {
    setUserInfoSave(e);
    setCurrent(current + 1);
    message.success(`${e.credType} is generated and store in wallet`);
  };

  const handleSubmit = (e) => {
    let payloadUserInfo = {
      ...userInfoSave,
      domainId: DOMAIN_ID(),
      password: "",
      sshKey: "",
      motp: "",
      did: "",
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    console.log(payloadUserInfo);
    setBtnLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/addEpmUserInfo`, payloadUserInfo, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setCreatedUserId(res.data.userId);
        handleAssignment(res.data.userId, e);
        setBtnLoading(false);
      })
      .catch((err) => {
        setBtnLoading(false);
        if (
          err.response.data.message === "Credentials are invalid" ||
          err.response.status === 401
        ) {
          logOutUser();
        }
      });
  };

  const handleAssignment = (userId, e) => {
    let id = [];
    id.push(Number(userId));
    const data = {
      ...e,
      ...userInfoSave,
      epmUserId: id,
      domainId: DOMAIN_ID(),
      assignMultiple: "false",
      credentialId: [],
      orgId: ORG_ID(),
    };

    setBtnLoading(true);
    axios
      .put(`${REACT_APP_API_PAM_URL}/endpointUsers/updateUserWallet`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then(() => {
        setBtnLoading(false);
        navigate(-1);
        notification.open({
          type: "success",
          content: "User Created Successful",
        });
      })
      .catch((err) => {
        setBtnLoading(false);
        if (
          err.response.data.message === "Credentials are invalid" ||
          err.response.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to add user",
          });
        }
      });
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={20}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">Add Endpoint User</h2>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <Steps current={current} items={items} />
            </Col>
          </Row>
          <Row>
            {/* <Col span={24}>{steps[current].content}</Col> */}
            {current === 0 ? (
              <>
                <Col span={24}>
                  <Form
                    form={form}
                    layout="vertical"
                    fields={fields}
                    onFieldsChange={(_, allFields) => {
                      setFieldsData(allFields);
                    }}
                    initialValues={{
                      credType: "Password",
                      privilegedUser: "false",
                    }}
                    onFinish={handleInfoSubmit}
                  >
                    <div style={contentStyle}>
                      <Row justify="space-between" align="middle">
                        <Col span={11}>
                          <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Username is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            name="userType"
                            label="User Type"
                            rules={[
                              {
                                required: true,
                                message: "User Type is required",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="space-between" align="middle">
                        <Col span={11}>
                          <Form.Item
                            name="credType"
                            label="Credential Type"
                            rules={[
                              {
                                required: true,
                                message: "Select a Credential Type",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleCredType}
                              tokenSeparators={[","]}
                              options={[
                                { value: "Password", label: "Password" },
                                { value: "SSH Key", label: "SSH Key" },
                                { value: "Decentralized ID", label: "Decentralized ID" },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            name="privilegedUser"
                            label="Privileged User"
                            rules={[
                              {
                                required: true,
                                message: "Select a Privileged User",
                              },
                            ]}
                          >
                            <Select
                              onChange={handlePrivilegedUser}
                              tokenSeparators={[","]}
                              options={[
                                { value: "false", label: "False" },
                                { value: "true", label: "True" },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <Row justify="end">
                      <Col>
                        <Space>
                          {current < 1 && <Button onClick={() => navigate(-1)}>Cancel</Button>}
                          {current < steps.length - 1 && (
                            <Button type="primary" htmlType="submit">
                              Next
                            </Button>
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <div style={contentStyle}>
                      <Row justify="space-between" align="middle">
                        <Col span={11}>
                          <Form.Item
                            name="instanceId"
                            label="Select Endpoints"
                            rules={[
                              {
                                required: true,
                                message: "Select a endpoints",
                              },
                            ]}
                          >
                            <Select
                              placeholder="select endpoints"
                              showSearch
                              optionFilterProp="label"
                              mode="multiple"
                              onChange={handleCredType}
                              tokenSeparators={[","]}
                              options={endpointData}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            name="groupId"
                            label="Create or Select User Groups(POSIX)"
                            rules={[
                              {
                                required: true,
                                message: "select a user groups",
                              },
                            ]}
                          >
                            <Select
                              placeholder="select user groups"
                              showSearch
                              optionFilterProp="label"
                              mode="tags"
                              onChange={handlePrivilegedUser}
                              tokenSeparators={[","]}
                              options={groupData}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="space-between" align="middle">
                        <Col span={11}>
                          <Form.Item
                            name="authnullUserNames"
                            label="Select User Wallets"
                            rules={[
                              {
                                required: true,
                                message: "Select a User Wallets",
                              },
                            ]}
                          >
                            <Select
                              placeholder="select user wallets"
                              mode="multiple"
                              onChange={handleCredType}
                              tokenSeparators={[","]}
                              showSearch
                              optionFilterProp="label"
                              options={walletData}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            name="issuerId"
                            label="Select Issuer"
                            rules={[
                              {
                                required: true,
                                message: "Select a Issuer",
                              },
                            ]}
                          >
                            <Select
                              placeholder="select issuer"
                              onChange={handlePrivilegedUser}
                              showSearch
                              optionFilterProp="label"
                              tokenSeparators={[","]}
                              options={issuerData}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <Row justify="end">
                      <Col>
                        <Space>
                          {current > 0 && <Button onClick={() => prev()}>Previous</Button>}
                          {current === steps.length - 1 && (
                            <Button type="primary" htmlType="submit">
                              Done
                            </Button>
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default AddEndpointUser;
