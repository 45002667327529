import React, { useEffect, useState } from "react";
import { Popover, Input, Button, List, Avatar } from "antd";
import { MessageFilled, SendOutlined, UserOutlined } from "@ant-design/icons";
// import './ChatWidget.css'; // Import your custom CSS for styling

const ChatWidget = () => {
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  useEffect(()=>{
    let tempMessages = [
        {
            type:"user",
            text:"How i can assign rules?"
        },
        {
            type:"Mike",
            text:"You can use template rules or define your own."
        },
        {
            type:"user",
            text:"Can I go ahead and create the rule?"
        },
        {
            type:"Mike",
            text:"Yes"
        },
        {
            type:"user",
            text:"Can i view history of group?"
        },
        {
            type:"mike",
            text:"Yes"
        },
    ];
    setMessages(tempMessages)
  },[])
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === "") return;
    let tempMessages = messages;
    tempMessages.push({ type: "user", text: inputValue });
    setMessages(tempMessages);
    setInputValue("");
    // Simulate bot response after a short delay
    setTimeout(() => {
        setMessages(messages => [
          ...messages,
          {
            type: "Mike",
            text: "Created Rules",
          },
        ]);
      }, 1000);
  };

  const content = (
    <div className="chat-widget-container">
      <div className="chat-messages">
        <List
          itemLayout="horizontal"
          dataSource={messages}
          renderItem={(item) => (
            <List.Item style={{backgroundColor:"#f0f0f0" , borderRadius:"10px"}} className={`message ${item.type}`}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    icon={<UserOutlined/>}
                    style={{marginLeft:"10px" , backgroundColor:"#c9dfff"}}
                    src={
                      item.type === "user"
                        ? "user-avatar.png"
                        : "bot-avatar.png"
                    }
                  />
                }
                title={item.type === "user" ? "You" : "Mike"}
                description={item.text}
              />
            </List.Item>
          )}
          locale={{ emptyText: "No messages yet" }}
        />
      </div>
      <label style={{marginLeft:"0.5rem", fontWeight:"600"}}>Your IT admin assistant Mike is ready to help guide you</label>
      <div className="chat-input">
        
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type your message..."
        />
        <Button type="primary" onClick={handleSendMessage}>
          <SendOutlined />
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      title="Chat"
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={(visible) => setVisible(visible)}
    >
      <div className="chat-icon">
        <MessageFilled />
      </div>
    </Popover>
  );
};

export default ChatWidget;
