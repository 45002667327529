import { Col, Row, Select, Table, Button, Input, message, Card } from "antd";
import { AUTH_TOKEN, REACT_APP_API_DID_URL, ORG_ID, DOMAIN_ID } from "../../constants";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";

import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;
const PasswordCredential = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [notification, setNotification] = message.useMessage();
  const [sortModel, setSortModel] = useState();

  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchCredential();
  }, [filter, debouncedValue, paginationParams]);
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const fetchCredential = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: paginationParams?.current,
      pageSize: paginationParams?.pageSize,
      filter: {
        filterType: "PASSWORD",
        searchType: "",
        searchValue: "",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/credential/credentialList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data = [];
        if (res.data.data) {
          Data = res.data.data.map((cred, i) => ({
            id: i,
            text: cred.credential_name,
            issuerName: cred.issuer_name,
            walletUser: cred.email_address,
            endpointUser: cred.epm_user_name,
            issuedAt:
              cred.created_at &&
              moment(cred.created_at).tz("America/Los_Angeles").format("MM-DD-YYYY HH:mm z"),
            // credentialId: cred.credentialId,
            expireDate:
              cred.expirationdate &&
              moment(cred.expirationdate).tz("America/Los_Angeles").format("MM-DD-YYYY HH:mm z"),
            status: cred.status,
          }));
        }
        console.log(Data);
        setCredentialData(Data);
        setTotalCount(res?.data?.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const columns = [
    { key: "text", headerName: "VNC Credential Name", field: "text", flex: 1 },
    { key: "walletUser", headerName: "Wallet User", field: "walletUser", flex: 1 },
    { key: "issuedAt", headerName: "Created Date", field: "issuedAt", flex: 1 },
    { key: "expireDate", headerName: "Expiry Date", field: "expireDate", flex: 1 },
    { key: "issuerName", headerName: "Issuer", field: "issuerName", flex: 1 },
    { key: "status", headerName: "Status", field: "status", flex: 1 },
    { key: "endpointUser", headerName: "Endpoint User", field: "endpointUser", flex: 1 },

    //{ key: "action1", value: "Action" }
  ];
  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                {/* <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Filter by status"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onFilterChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOption.map((option) => (
                        <Option
                          key={option.value}
                          value={option.value}
                          label={option.key}
                        >
                          {option.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* <Table
            loading={isLoading}
            columns={columns}
            dataSource={credentialData}
            pagination={{
              showTotal: (total) => `Total ${total} items`,
              ...paginationParams,
              total: totalCount,
              onChange: (page, pageSize) => {
                setPaginationParams({
                  current: page,
                  pageSize: pageSize,
                });
              },
            }}
          /> */}
          <DataGrid
                  components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
            rows={credentialData}
            columns={columns}
            paginationMode="server"
            rowCount={totalCount}
            page={paginationParams.current}
            pageSize={paginationParams.pageSize}
            onPaginationModelChange={(newPage) => {
              setPaginationParams({
                ...paginationParams,
                current: newPage.page,
              });
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            style={{ border: "none" }}
            loading={isLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default PasswordCredential;
