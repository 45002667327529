import { Button, Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ScreenRecording from "./ScreenRecording";
import TextRecording from "./TextRecording";

const SessionRecordings = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const navigate = useNavigate();

  const items = [
    {
      key: 1,
      label: "Screen Recording",
      children: <ScreenRecording />,
    },
    {
      key: 2,
      label: "Text Recording",
      children: <TextRecording />,
    },
  ];
  const handleAddPolicy = () => {
    if (currentTab === 1) {
      navigate("/credentialRotation/addPassword");
    } else {
      navigate("/credentialRotation/addSshKeys");
    }
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Session Recordings</h2>
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SessionRecordings;
