import { Col, Input, Row, message, Button, Form, Modal, Card, Divider, Space, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_TENANTS_URL } from "../../../constants";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import "../../../styles/Unauthorized/signup.scss";

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [notification, setNotification] = message.useMessage();
  const [adminInfo, setAdminInfo] = useState(null);
  const [isCheckAuthMethod, setIsCheckAuthMethod] = useState(false);
  const [urlOkta, setUrlOkta] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let session = params.get("token");

    const verifyToken = () => {
      let data = {
        token: session,
      };
      axios.post(`${REACT_APP_API_TENANTS_URL}/tenanttokenverify`, data).then((res) => {
        CheckAuthnMethod(res?.data?.tenanturl);
        setAdminInfo(res.data);
        setFormData([
          { name: ["role"], value: res?.data?.role.toUpperCase() === "ADMIN" && "Admin" },
          {
            name: ["orgname"],
            value: res?.data?.organizationname,
          },
          {
            name: ["email"],
            value: res?.data?.email,
          },
          {
            name: ["tenantname"],
            value: res?.data?.tenantname,
          },
          {
            name: ["siteurl"],
            value: res?.data?.tenanturl,
          },
        ]);
      });
    };
    verifyToken();
    const CheckAuthnMethod = (tenanturl) => {
      const data = {
        url: tenanturl,
      };
      axios
        .post(`${REACT_APP_API_TENANTS_URL}/getAuthMethod`, data)
        .then((res) => {
          let method = res.data.authenticationmethod;
          if (method.toUpperCase() === "NORMAL") {
            setIsCheckAuthMethod(false);
          } else if (method.toUpperCase() === "OKTA") {
            setUrlOkta(res.data.ssoUrl);
            setIsCheckAuthMethod(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }, []);

  const fetchSignup = (e) => {
    let pageDetails = {
      ...e,
      authentication_method: "pay",
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/tenantsignup`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        form.resetFields();
        notification.open({
          type: "success",
          content: "Tenant created successfully",
        });

        setTimeout(() => {
          if (!isCheckAuthMethod) {
            navigate("/tenant/login");
          } else {
            window.location.replace(urlOkta);
          }
        }, 4000);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error during signup:", err);
      });
  };

  return (
    <>
      {setNotification}

      <div className="signup-container">
        <div className="form-container">
          <h2 className="title">Create a Tenant Account</h2>

          <Form
            form={form}
            layout="vertical"
            onFinish={fetchSignup}
            fields={formData}
            onFieldsChange={(_, allFields) => {
              setFormData(allFields);
            }}
          >
            <Row align="middle" justify="space-between">
              <Col span={11}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "First name required",
                    },
                  ]}
                >
                  <Input placeholder="Enter first name" />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Last name required",
                    },
                  ]}
                >
                  <Input placeholder="Enter Last name" />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" justify="space-between">
              <Col span={11}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Email required",
                    },
                    {
                      type: "email",
                      message: "Please enter valid email",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email Address" disabled />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[
                    {
                      required: true,
                      message: "Role required",
                    },
                  ]}
                >
                  <Input placeholder="Enter Role" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" justify="space-between">
              <Col span={11}>
                <Form.Item
                  name="orgname"
                  label="Organization Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Enter your Orgnization name" disabled />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="tenantname"
                  label="Tenant Name"
                  rules={[
                    {
                      required: true,
                      message: "Tenant Name required",
                    },
                  ]}
                >
                  <Input placeholder="Enter your Tenant name" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="space-between">
              <Col span={11}>
                <Form.Item
                  name="siteurl"
                  label="Tenant URL"
                  rules={[
                    {
                      required: true,
                      message: "Tenant Url required",
                    },
                  ]}
                >
                  <Input placeholder="Enter your Tenant URL" disabled />
                </Form.Item>
              </Col>
              {!isCheckAuthMethod && (
                <Col span={11}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Password required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {!isCheckAuthMethod && (
              <Row align="middle" justify="space-between">
                <Col span={11}>
                  <Form.Item
                    name="confirmpassword"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Confirm Password required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("The new password that you entered do not match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row justify="center" style={{ margin: "1rem 0" }}>
              <Col>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Sign Up
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Signup;
