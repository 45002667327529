import logo from "./logo.svg";
import "./App.css";
import PrimaryLayout from "./components/primaryLayout";
import { useState, useEffect } from "react";
import { getOktaToken, getRequestId } from "./common/token";
import { useNavigate, Route, Routes } from "react-router-dom";
import axios from "axios";
import {
  AUTH_TOKEN,
  REACT_APP_API,
  REACT_APP_API_OKTA,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_URL,
} from "./constants";
import { logOutUser } from "./common";
import { Button, Result, Spin } from "antd";
import { authorize } from "./common/SecureRoute";
import UnAuthorizeRoutes from "./components/unauthorized/UnAuthorizeRoutes";
import PrimaryLayoutLocal from "./components/PrimaryLayoutLocal";

function App() {
  const navigate = useNavigate();
  const [appLoading, setAppLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const authToken = localStorage.getItem("token");

  let path = window.location.pathname;

  useEffect(() => {
    setAppLoading(true);
    localStorage.setItem("URL", window.location.host);
    const fetchToken = async (token) => {
      localStorage.setItem("token", token);
      requiredAccess(token);
    };

    if (!authToken) {
      const params = new URLSearchParams(window.location.search);
      let session = params.get("session");
      getRequestId();
      if (session) {
        fetchToken(session);
      } else {
        localStorage.setItem("URL", window.location.host);
        if (path === "/org/signup") {
          return;
        } else if (path === "/org/login") {
          return;
        } else if (path === "/tenant/login") {
          return;
        } else if (path === "tenant/signup") {
          return;
        } else if (path === "/") {
          // window.location.replace(`${REACT_APP_API_OKTA}/okta/RedirectLink`);
          if (localStorage.getItem("URL") === "test.authnull.com") {
            navigate("/org/login");
          } else {
            CheckAuthnMethod();
          }
        }
      }
    } else {
      requiredAccess(authToken);
    }
  }, [authToken]);
  let reqId = localStorage.getItem("requestId");

  const CheckAuthnMethod = () => {
    const data = {
      url: localStorage.getItem("URL"),
      requestId: reqId,
    };
    axios.post(`${REACT_APP_API_TENANTS_URL}/getAuthMethod`, data).then((res) => {
      let method = res.data.authenticationmethod;
      localStorage.setItem("logMethod", method.toUpperCase());
      if (method.toUpperCase() === "NORMAL") {
        // if (!reqId) {
        navigate("/tenant/login");
        // }
        // TODO: Need in future
        // else {
        //   navigate("/endUser/endpoints");
        // }
      } else if (method.toUpperCase() === "OKTA") {
        window.location.replace(res.data.ssoUrl);
      } else {
        navigate("/tenant/login");
      }
    });
  };
  const requiredAccess = (token) => {
    setAppLoading(true);
    let domainUrl = localStorage.getItem("URL");

    axios
      .post(
        `${REACT_APP_API}/authnz/getUserDetails`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": `${token}&DOMAIN&${domainUrl}`,
            "X-RequestUrl": localStorage.getItem("URL"),
          },
        }
      )
      .then(({ data }) => {
        setAppLoading(false);
        if (data?.Validation === true && data?.Status === "Success") {
          localStorage.setItem("UserRole", data?.UserRole);
          localStorage.setItem("UserName", data?.Username);
          localStorage.setItem("userId", data?.UserID);
          localStorage.setItem("DomainId", data?.DomainID);
          localStorage.setItem("OrgId", data?.OrgID);
          if (!authorize(data?.UserRole, path)) {
            if (data?.UserRole === "ADMIN") {
              navigate("/");
            } else if (data?.UserRole === "ENDUSER") {
              navigate("/endUser/endpoints");
            } else if (data?.UserRole === "SUPERADMIN") {
              fetchTenent();
              // navigate("/org/dashboard");
            }
          } else {
            navigate(path);
          }
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        } else if (
          err?.response?.status === 401 &&
          err.response.data?.Validation === false &&
          err.response.data?.Message === "User Does not exist contact your administrator"
        ) {
          setAppLoading(false);
          setIsUnauthorized(true);
        } else {
          logOutUser();
        }
      });
  };

  const fetchTenent = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/tenantlist`, data, {
        headers: {
          withCredentials: true,
        },
      })
      .then((res) => {
        let tenant = res?.data?.data;
        if (tenant.length < 1) {
          navigate("/tenant/add");
        } else {
          navigate("/org/dashboard");
        }
      });
  };

  return (
    <div className="app-conatiner">
      {!authToken ? (
        <UnAuthorizeRoutes />
      ) : !appLoading && authToken ? (
        REACT_APP_URL === "https://localhost:5173/" ? (
          <PrimaryLayoutLocal />
        ) : (
          <PrimaryLayout />
        )
      ) : isUnauthorized && !appLoading ? (
        <Result
          status="403"
          title={`You do not have permission to access this application.`}
          subTitle="Please contact your Site Administrator(s) to request access."
          extra={
            <Button
              type="primary"
              onClick={() => {
                localStorage.clear();
                navigate(`${REACT_APP_API_OKTA}/okta/RedirectLink`);
              }}
            >
              Return to the login
            </Button>
          }
        />
      ) : (
        <div className="app-loading">
          <Spin />
        </div>
      )}
    </div>
  );
}

export default App;
