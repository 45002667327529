import {
  Col,
  Row,
  Select,
  Table,
  Dropdown,
  Button,
  Input,
  message,
  Space,
  Alert,
  Tag,
  Spin,
  Tooltip,
  Cascader,
  DatePicker,
  Collapse,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Link, Navigate, Outlet } from "react-router-dom";
import moment from "moment-timezone";
import { useState } from "react";

import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const RequestLogs = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const generateDataset = (count) => {
    const dataset = [];

    for (let i = 0; i < count; i++) {
      dataset.push({
        id: i + 1,
        location: `Location ${i + 1}`,
        endpoint: `Endpoint ${i + 1}`,
        group: `Group ${i + 1}`,
        ip: `192.168.${i % 256}.${i % 256}`,
        labels: [`Label${i % 10}`, ` Label${(i % 10) + 1}`],
        user: `User ${Math.floor(Math.random() * 100)}`,
        previledgeUser: `User ${Math.floor(Math.random() * 10)}`,
        status: i % 2 ? "Approved" : "Denied",
        approvedBy: `User ${i + 1}`,
        request: `Request ${i + 1}`,
        applicationName: `App ${i + 1}`,
      });
    }

    return dataset;
  };

  const endpoints = generateDataset(20);
  const [filteredEndpoints, setFilteredEndpoints] = useState(
    endpoints.filter((endpoint) => {
      return (
        endpoint.endpoint.toLowerCase().includes(searchText.toLowerCase()) &&
        appliedFilters.every(({ filter, value }) =>
          endpoint[filter].toLowerCase().includes(value.toLowerCase())
        )
      );
    })
  );
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const columns = [
    { headerName: "Location", field: "location", key: "location" },
    {
      field: "endpoint",
      headerName: "Endpoint",
      flex: 1,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag key={val.row.endpoint} color="blue">
            {val.row.endpoint}
          </Tag>
        </>
      ),
    },
    {
      field: "group",
      headerName: "Endpoint Group",
      flex: 1,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag key={val.row.group} color="blue">
            {val.row.group}
          </Tag>
        </>
      ),
    },

    {
      headerName: "IP",
      field: "ip",
      flex: 1.5,
      sortable: true,
    },
    {
      headerName: "Labels",
      field: "labels",
      flex: 1.5,
      sortable: true,
      renderCell: (val) => (
        <>
          {val.row.labels.map((label) => (
            <Tag key={label} color="blue">
              {label}
            </Tag>
          ))}
        </>
      ),
    },

    {
      headerName: "Users (LDAP / Local)",
      field: "user",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Priviledge Users",
      field: "previledgeUser",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      sortable: true,
      renderCell: (val) => (
        <span
          style={{ color: val.row.status === "Approved" ? "green" : "red" }}
        >
          {val.row.status}
        </span>
      ),
    },
    {
      headerName: "Approved By",
      field: "approvedBy",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Request",
      field: "request",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Application Name",
      field: "applicationName",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Action",
      field: "createRule",
      flex: 1,
      sortable: true,
      renderCell: () => <Link>Create Rule</Link>,
    },
  ];

  const handleApplyFilter = (filter, value) => {
    if (value) {
      setAppliedFilters([...appliedFilters, { filter, value }]);
    }
  };

  const handleRemoveFilter = (filter, value) => {
    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) =>
          filterItem.filter !== filter || filterItem.value !== value
      )
    );
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Audit Log</h2>
            </Col>
            {/* <Col>
                <Link to={"/windows/createWindowPolicy"}>
                  <Button type="primary">Add Rules</Button>
                </Link>
              </Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Tenant</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select Tenant"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={"Authnull"} // Set the default value
                      >
                        <Option value="Authnull">Authnull</Option>
                        {/* Add other options here */}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Range</p>
                      <RangePicker />
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Type</p>
                      <Select>
                        <Option value="type1">Not Responded</Option>
                        <Option value="type2">Rejected</Option>
                        <Option value="type3">Approved Manually</Option>
                        <Option value="type4">Approved By Policy</Option>
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Endpoint Groups</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search Endpoint Group"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleApplyFilter("groups", e)}
                        // Set the default value
                      >
                        <Option value="Group 1">Group 1</Option>
                        <Option value="Group 2">Group 2</Option>
                        <Option value="Group 3">Group 3</Option>
                        {/* Add other options here */}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Endpoint</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search Endpoint "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleApplyFilter("groups", e)}
                        // Set the default value
                      >
                        <Option value="Endpoint 1">Endpoint 1</Option>
                        <Option value="Endpoint 2">Endpoint 2</Option>
                        <Option value="Endpoint 3">Endpoint 3</Option>
                        {/* Add other options here */}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              {appliedFilters.length > 0 && (
                <div className="filter-tags">
                  <h4
                    style={{
                      marginTop: "0rem",
                      marginBottom: "0.5rem",
                      fontWeight: "400",
                    }}
                  >
                    Applied Filters:
                  </h4>
                  {appliedFilters.map(({ filter, value }) => (
                    <Tag
                      key={`${filter}:${value}`}
                      closable
                      onClose={() => handleRemoveFilter(filter, value)}
                    >
                      {`${filter}: ${value}`}
                    </Tag>
                  ))}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="ant-style-data-grid">
              <DataGrid
                  components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={filteredEndpoints}
                paginationMode='server'
                columns={columns}
                pageSize={10}
                checkboxSelection
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RequestLogs;
