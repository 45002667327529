import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
  Input,
  Button,
  Select,
  Row,
  Col,
  Typography,
  Dropdown,
  Menu,
} from "antd";
import axios from "axios";
import "../../styles/ServiceAccount.scss"
import { useParams, useLocation } from "react-router-dom";
const { Title, Text } = Typography;
const { Option } = Select;

const ServiceDynamicAuthz = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
 
  return (
    <div className="rule-container">
      <Row style={{paddingRight:"3rem"}}>
        <Col span={12}>
          <Row>
            <Col span={24}>
            <p >Dynamic Authz rules for</p>
                <Select
                      defaultValue={"Endpoint Group #2"}
                      placeholder="Select Endpoint Group"
                      options={[
                        { label: "mysql-admin", value: "admin" },
                        { label: "endpoint Group #1", value: "group #1" },
                        { label: "mysql-backup", value: "backup" },
                        { label: "endpoint Group #3", value: "group #3" },
                      ]}
                      mode="multiple"
                      allowClear                    
                    />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
            <p >Show templates</p>
                <Select
                      defaultValue={"Zero Standing Privilege Rules"}
                      placeholder="Select template"
                      options={[
                        { label: "Zero Standing Privilege Rules", value: "system assigned" },
                        { label: " Zero Trust Rules", value: "user assigned" },                       
                      ]}
                    />
            </Col>         
          </Row>
          <Row></Row>
          <Row
            style={{
              padding: "2rem 2rem",
              background: "#dbeeff",
              paddingRight: "1rem",
              borderRadius: "8px",
              marginTop:"2rem"
            }}
          >   
              <Col span={24}>
                <Row className="add-dropdown-row" gutter={12}>
                  <Col span={2}>IF</Col>
                  <Col span={9}>
                    <Input
                      value={"Country"}                      
                      className="rules-dropdown"
                    >                     
                    </Input>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Input
                      value={"="}
                      className="rules-dropdown"
                    >
                    </Input>
                  </Col>
                  <Col span={9}>                   
                      <Input
                       value={"!usa"}
                        className="rules-dropdown"
                      >
                      </Input>                  
                  </Col>
                </Row>
                <Row className="add-dropdown-row" style={{marginTop:"1rem"}} gutter={12}>
                  <Col span={2}></Col>
                  <Col span={9}>
                    <Input
                      value={"Vpn"}                      
                      className="rules-dropdown"
                    >                     
                    </Input>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Input
                      value={"="}
                      className="rules-dropdown"
                    >
                    </Input>
                  </Col>
                  <Col span={9}>                  
                      <Input
                       value={"True"}
                        className="rules-dropdown"
                      >
                      </Input>                 
                  </Col>
                </Row>
                <Row className="add-dropdown-row" style={{marginTop:"1rem"}} gutter={12}>
                  <Col span={2}></Col>
                  <Col span={9}>
                    <Input
                      value={"Risk_score"}                      
                      className="rules-dropdown"
                    >                    
                    </Input>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Input
                      value={"="}
                      className="rules-dropdown"
                    >
                    </Input>
                  </Col>
                  <Col span={9}>                   
                      <Input
                       value={"<=1"}
                        className="rules-dropdown"
                      >
                      </Input> 
                  </Col>
                </Row>
              </Col>          
          </Row>
          <h4 style={{ textAlign: "center" }}>Then</h4>
          <Row
            style={{
              padding: "2rem 2rem",
              background: "#dbeeff",
              paddingRight: "1rem",
              borderRadius: "8px",
            }}
          >        
              <Col span={24}>
                <Row className="add-dropdown-row" gutter={12}>
                <Col span={24}>     
                    <Select
                    defaultValue={"Exclusion rules: Dissallow access if rules are true"}
                     options={[
                      { label: "Exclusion rules: Dissallow access if rules are true", value: "rule 1" },
                      { label: "Inclusion rules: Allow access only if above rules are true", value: "rule 2" },
                      { label: "Setup changes to entitlements", value: "rule 3" },                     
                    ]}
                    >
                    </Select>
                  </Col>
                </Row>              
              </Col>   
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col
              span={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              <Button
                type="primary"
                className="rules-dropdown"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col
          span={12}
          style={{ paddingLeft: "2rem" ,height:"848px", top: "64px" }}
          className=" add-servicerule-version"
        >
          <Row className="commit-container">
            <Col className="version-history" span={24}>
                <>
                  <Row>
                    <Col>
                      <h2 className="version-history-title">Assignment History</h2>
                    </Col>
                  </Row>
                  <Col span={24}>    
                        <div className="github-commit-card">
                          <div className="commit-header">
                            <p className="commit-info">
                              Last updated by Asif Ali @ 02-02-2023
                            </p>
                          </div>
                          <div className="commit-details">
                            {/* <p className="commit-message">abcc</p> */}
                            <div className="commit-code">
                              <pre className="code-snippet">
                                <div 
                                >
                                  <div
                                    style={{ marginTop: "0.5rem",textWrap:'balance' }}
                                    className="code-line"
                                  >
                                    Rules :{" IF Country = usa "}
                                           {"and vpn = true"}

                                  </div>
                                  <div
                                    style={{ marginLeft:"50px", textWrap:'balance' }}
                                    className="code-line"
                                  >
                                    {" and risk_score = <=1 "}
                                  </div>
                                  <h5
                                    style={{ marginTop: "0.5rem", textWrap:'balance' }}
                                    className="code-line"
                                  >
                                    Then : {"Inclusion rules: Allow login only if above rules are true"}
                                  </h5>
                                </div>
                              </pre>
                            </div>
                          </div>
                        </div>
                  </Col>
                  <Col span={24}>
                   <div className="github-commit-card">
                     <div className="commit-header">
                       <p className="commit-info">
                         Last updated by Asif Ali @ 02-02-2023
                       </p>
                     </div>
                     <div className="commit-details">
                       {/* <p className="commit-message">abcc</p> */}
                       <div className="commit-code">
                         <pre className="code-snippet">    
                           <div 
                           >
                             <div
                               style={{ marginTop: "0.5rem",textWrap:'balance' }}
                               className="code-line"
                             >
                               Rules :{" IF Country = usa "}
                                      {"and vpn = true"}

                             </div>
                             <div
                               style={{ marginLeft:"50px", textWrap:'balance' }}
                               className="code-line"
                             >
                               {" and risk_score = <=1 "}
                             </div>
                             <h5
                               style={{ marginTop: "0.5rem", textWrap:'balance' }}
                               className="code-line"
                             >
                               Then : {"Inclusion rules: Allow login only if above rules are true"} 
                             </h5>
                           </div>
                         </pre>
                       </div>
                     </div>
                   </div>
             </Col>
             <Col span={24}>
                   <div className="github-commit-card">
                     <div className="commit-header">
                       <p className="commit-info">
                         Last updated by Asif Ali @ 02-02-2023
                       </p>
                     </div>
                     <div className="commit-details">
                       {/* <p className="commit-message">abcc</p> */}
                       <div className="commit-code">
                         <pre className="code-snippet">   
                           <div
                           >
                             <div
                               style={{ marginTop: "0.5rem",textWrap:'balance' }}
                               className="code-line"
                             >
                               Rules :{" IF Country = usa "}
                                      {"and vpn = true"}

                             </div>
                             <div
                               style={{ marginLeft:"50px", textWrap:'balance' }}
                               className="code-line"
                             >
                               {" and risk_score = <=1 "}
                             </div>
                             <h5
                               style={{ marginTop: "0.5rem", textWrap:'balance' }}
                               className="code-line"
                             >
                               Then : {"Exclusion rules: Disallow login if above rules are true"}
                             </h5>
                           </div>
                         </pre>
                       </div>
                     </div>
                   </div>
             </Col>
             <Col span={24}>
                   <div className="github-commit-card">
                     <div className="commit-header">
                       <p className="commit-info">
                         Last updated by Asif Ali @ 02-02-2023
                       </p>
                     </div>
                     <div className="commit-details">
                       {/* <p className="commit-message">abcc</p> */}
                       <div className="commit-code">
                         <pre className="code-snippet">   
                           <div
                           >
                             <div
                               style={{ marginTop: "0.5rem",textWrap:'balance' }}
                               className="code-line"
                             >
                               Rules :{" IF Country = usa "}
                                      {"and vpn = true"}

                             </div>
                             <div
                               style={{ marginLeft:"50px", textWrap:'balance' }}
                               className="code-line"
                             >
                               {" and risk_score = <=1 "}
                             </div>
                             <h5
                               style={{ marginTop: "0.5rem", textWrap:'balance' }}
                               className="code-line"
                             >
                               Then : {"Exclusion rules: Disallow login if above rules are true"}
                             </h5>
                           </div>
                         </pre>
                       </div>
                     </div>
                   </div>
             </Col>
             <Col span={24}>
                   <div className="github-commit-card">
                     <div className="commit-header">
                       <p className="commit-info">
                         Last updated by Asif Ali @ 02-02-2023
                       </p>
                     </div>
                     <div className="commit-details">
                       {/* <p className="commit-message">abcc</p> */}
                       <div className="commit-code">
                         <pre className="code-snippet">   
                           <div
                           >
                             <div
                               style={{ marginTop: "0.5rem",textWrap:'balance' }}
                               className="code-line"
                             >
                               Rules :{" IF Country = usa "}
                                      {"and vpn = true"}

                             </div>
                             <div
                               style={{ marginLeft:"50px", textWrap:'balance' }}
                               className="code-line"
                             >
                               {" and risk_score = <=1 "}
                             </div>
                             <h5
                               style={{ marginTop: "0.5rem", textWrap:'balance' }}
                               className="code-line"
                             >
                               Then : {"Exclusion rules: Disallow login if above rules are true"}
                             </h5>
                           </div>
                         </pre>
                       </div>
                     </div>
                   </div>
             </Col>
             
                </>
            </Col>
          </Row>
        </Col>
    </div>
    
  );
};

export default ServiceDynamicAuthz;