import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Steps,
  Button,
  Input,
  Alert,
  Select,
  Form,
  DatePicker,
  Typography,
  InputNumber,
  message,
} from "antd";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_DID_URL,
  REACT_APP_API_SAM_URL,
  DOMAIN_ID,
  ORG_ID,
} from "../../constants";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const { Step } = Steps;
const { Option } = Select;
const { Title, Text } = Typography;

const ServiceWalletAssignment = ({ assignData }) => {
  const [current, setCurrent] = useState(0);
  const [stepOneSecondVisible, setStepOneSecondVisible] = useState(false);
  const [stepOneOneVisible, setStepOneOneVisible] = useState(true);
  const [idName, setidName] = useState("Authnull Identity");
  const [id, setid] = useState("389fd-43ds1-9c4ts");
  const [AssignedGroup, setAssignedGroup] = useState("Group #2");
  const [type, setType] = useState("User Assigned");
  const [selectedTtl, setSelectedTtl] = useState("1 Day");
  const [selectedRotation, setSelectedRotation] = useState("1 Day");
  const [walletEmail, setWalletemail] = useState("account@authnull.com");
  const [walletData, setWalletData] = useState([]);
  const [customDay, setCustomDay] = useState();
  const [customTtl, setCustomTtl] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [issuerData, setissuerData] = useState();
  const [issuer, setIssuer] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();

  useEffect(() => {
    fetchWallets();
    fetchIssuerList();
  }, []);
  const fetchIssuerList = () => {
    let requestData = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setissuerData(res.data);
      });
  };
  const AddService = () => {
    let pageDetails = {
      ...assignData,
      walletId: walletEmail,
      credentialExpiry: selectedDuration === "custom" ? customDay : selectedDuration,
      issuerId: issuer,
      createdBy: localStorage.getItem("UserName"),
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_SAM_URL}/assignEndpoints`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        notification.open({
          type: "success",
          content: "Service credential added successful!",
        });
        setIsLoading(false);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
        // setIsCreate(false);
        // fetchServiceaccounts();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const fetchWallets = async (searchText, pageNo) => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 1000,
    };
    try {
      let res = await axios.post(
        `${REACT_APP_API_DID_URL}/walletService/walletUserList`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );

      setWalletData(res.data.users);
    } catch (err) {
      if (err.response.status == 401) {
      }
    }
  };
  return (
    <Row>
      {setNotification}
      <Col span={24}>
        <Row className="content-conatiner">
          <Col span={24}>
            <h3>Who is this assigned to?</h3>
            <Form form={form} layout="vertical">
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={8}>
                      <Form.Item name="holdingpermisson" label="Who will hold these permissions?">
                        <Select
                          placeholder="Select Wallet"
                          style={{ width: "100%" }}
                          labelInValue={true}
                          onChange={(option) => setWalletemail(option.value)}
                        >
                          {walletData &&
                            walletData.length > 0 &&
                            walletData.map((data) => (
                              <Option key={data.walletId} value={data.walletId}>
                                {data.emailAddress}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="issuer" label="Issuer">
                        <Select
                          placeholder="Select Wallet"
                          style={{ width: "100%" }}
                          labelInValue={true}
                          onChange={(option) => setIssuer(option.value)}
                        >
                          {issuerData &&
                            issuerData.length > 0 &&
                            issuerData.map((data) => (
                              <Option key={data.IssuerId} value={data.IssuerId}>
                                {data.Name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={6}></Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={8}>
                      <Form.Item name="selectRotation" label="Assignment Duration">
                        <Select
                          placeholder="Select Duration"
                          style={{ width: "100%" }}
                          options={[
                            { value: 0, label: "Rotate per use" },
                            { value: 1, label: "1 Day" },
                            { value: 7, label: "1 Week" },
                            { value: 30, label: "30 Days" },
                            {
                              value: "custom",
                              label: "Enter Custom Value in days",
                            },
                          ]}
                          labelInValue={true}
                          onChange={(option) => setSelectedDuration(option.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="selectTTL" label="Rotation Of Credentials">
                        <Select
                          placeholder="Select Duration"
                          style={{ width: "100%" }}
                          options={[
                            { value: 0, label: "1 Use" },
                            { value: 1, label: "1 Day" },
                            { value: 7, label: "1 Week" },
                            { value: 30, label: "30 Days" },
                            { value: "custom", label: "Enter Custom Value" },
                          ]}
                          labelInValue={true}
                          onChange={(option) => setSelectedRotation(option.value)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}></Col>
                  </Row>
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  {selectedDuration === "custom" && (
                    <Form.Item name="select-custom-date" label="Days">
                      <InputNumber
                        style={{ width: "100% " }}
                        min={3}
                        max={30}
                        onChange={(e) => setCustomDay(e)}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={8}>
                  {selectedRotation === "custom" && (
                    <Form.Item name="select Custom Date" label="Days">
                      <InputNumber
                        style={{ width: "100% " }}
                        min={3}
                        max={30}
                        onChange={(e) => setCustomTtl(e)}
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col span={6}></Col>
              </Row>
              <Row style={{ marginTop: "1rem", marginLeft: "6.5rem" }} justify="end">
                <Col></Col>
                <Col span={9}>
                  <Button type="primary" onClick={() => AddService()} loading={isLoading}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ServiceWalletAssignment;
