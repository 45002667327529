import { Button, Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
 import UserDID from "./userDid";
import IssuerDID from "./IssuerDid";
import AcceptedCredential from "./acceptedCredential";
import AssignedCredential from "./assignedCredential";
import IgnoredCredential from "./ignoredCredentials";

const Credential = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const navigate = useNavigate();

  const items = [
    {
      key: 1,
      label: "Accepted",
      children: <AcceptedCredential />,
    },
    {
      key: 2,
      label: "Assigned",
      children: <AssignedCredential />,
    },
    {
        key: 3,
        label: "Ignored",
        children: <IgnoredCredential />,
      },
  ];


  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Credentials</h2>
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Credential;
