import { MoreOutlined } from "@ant-design/icons";
import {
  Col,
  Row,
  Select,
  Table,
  Dropdown,
  Button,
  Input,
  message,
  Space,
  Alert,
  Tag,
  Spin,
  Tooltip,
  Cascader,
} from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { REACT_APP_API_TENANTS_URL } from "../../constants";
import moment from "moment-timezone";

function Tenant() {
  const [isLoading, setIsLoading] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [notification, setNotification] = message.useMessage();

  const navigate = useNavigate();

  useEffect(() => {
    fetchTenent();
  }, []);

  const columns = [
    { title: "Name", dataIndex: "tenant_name", key: "tenant_name" },
    {
      title: "Admin Email",
      dataIndex: "admin_email",
      key: "admin_email",
      render: (tags, record) => (
        <span>
          <Tag color={record.status.toLowerCase() === "active" ? "green" : "red"} key={tags}>
            {tags}
          </Tag>
        </span>
      ),
    },
    {
      title: "Tenant URL",
      dataIndex: "site_url",
      key: "site_url",
      render: (tags, record) => (
        <span>
          <a href={`https://${tags}`} target="_blank">
            {tags}
          </a>
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (val) => (val.toLowerCase() === "active" ? "Active" : "Inactive"),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <span>
          {record && moment(record).tz("America/Los_Angeles").format("MM-DD-YYYY HH:mm z")}
        </span>
      ),
    },
    {
      title: "Modified At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, record) => (
        <span>
          {record && moment(record).tz("America/Los_Angeles").format("MM-DD-YYYY HH:mm z")}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: <Link>Edit</Link>,
              },

              {
                key: "delete",
                label: <Link onClick={() => handleDelete(record)}>Delete</Link>,
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const fetchTenent = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/tenantlist`, data, {
        headers: {
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTenantList(res?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const handleDelete = (val) => {
    console.log(val);
    const data = {
      email: localStorage.getItem("UserName"),
      tenantid: val.id,
    };
    axios.post(`${REACT_APP_API_TENANTS_URL}/deletetenant`, data).then((res) => {
      console.log(res);
      fetchTenent();
      notification.open({
        type: "success",
        content: "User registered successfully.Please check you registered email to verify",
      });
    });
  };
  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Tenant</h2>
            </Col>
            <Col>
              <Link to={"/tenant/add"}>
                <Button type="primary">Create Tenant</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input placeholder="Search" allowClear />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table loading={isLoading} columns={columns} dataSource={tenantList} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Tenant;
