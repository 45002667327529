import { Col, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { fetchEndpointGroups, fetchEndpoints } from "../../../common/functions";
import { useNavigate, useParams } from "react-router-dom";

export default function CredentialRotationPolicy() {
  const { epmUserId, instanceId } = useParams();
  const [selectedPort, setSelectedPort] = useState(22);
  const [selectedTemplate, setSelectedTemplate] = useState(22);
  const [loading, setLoading] = useState(false);

  const [selectedTtl, setSelectedTtl] = useState("");

  const [endpoints, setEndpoints] = useState([]);
  const [endpointGroups, setEndpointGroups] = useState([]);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const protocolPortMap = {
    SSH: 22,
    RDP: 3389,
    VNC: 5900,
    Telnet: 22,
  };

  useEffect(() => {
    fetchEndpoints({}).then((instances) => {
      setEndpoints(
        instances.map((instance) => ({
          id: instance.instanceId,
          text: instance.hostName,
          publicIp: instance.publicIp,
        })),
      );
    });

    fetchEndpointGroups({}).then(({ groups }) => {
      setEndpointGroups(groups);
    });
  }, []);

  return (
    <Spin spinning={loading}>
      <Row className="content-conatiner">
        <Col>
          <Row>
            <Col>
              <h2 className="title">Credential Rotation Policy</h2>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
}
