import React, { useState } from "react";
import "../../styles/Unauthorized/signup.scss";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, message } from "antd";
import axios from "axios";
import { AUTH_TOKEN, REACT_APP_API_TENANTS_URL } from "../../constants";
import { useNavigate } from "react-router";

function Login() {
  const [IsLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    console.log("Received values of form: ", values);
    const loginData = {
      ...values,
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/orglogin`, loginData)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        setIsLoading(false);
        // fetchTenent(values);
        navigate("/org/dashboard");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log({ err });
        notification.open({
          type: "error",
          content: "Unable to login, Please try again later ",
        });
      });
  };

  const fetchOrgDetail = (value) => {
    let data = {
      email: value.email,
    };

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/orgdetail`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res.data.check_authentication);
        if (res.data.check_authentication === false) {
          navigate("/tenant/add");
        } else {
          navigate("/org/dashboard");
        }
      });
  };

  const fetchTenent = (values) => {
    let data = {
      email: values.email,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/tenantlist`, data, {
        headers: {
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let tenant = res?.data?.data;
        if (tenant.length < 1) {
          navigate("/tenant/add");
        } else {
          navigate("/org/dashboard");
        }
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  return (
    <>
      {setNotification}
      <div className="login-container">
        <div className="login-box">
          <h2 className="title">Sign in to Organization</h2>

          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email",
                },
                {
                  type: "email",
                  message: "Please enter valid email",
                },
              ]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password",
                },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <div className="login-form-button">
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={IsLoading}
                >
                  Sign in
                </Button>
                <br />
              </Form.Item>
              <span>Or</span> <br />
              <br />
              <a href="">Forgot password ?</a>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
