import { Alert, Col, Input, Row, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../constants";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";

const Endpoints = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [endpointData, setEndpointData] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchEndpoints();
  }, [debouncedValue, paginationParams]);

  const columns = [
    {
      title: "Host Name",
      dataIndex: "hostName",
      key: "hostName",
    },
    {
      title: "OS",
      dataIndex: "os",
      key: "os",
    },
    {
      title: "Private IP",
      dataIndex: "privateIp",
      key: "privateIp",
    },
    {
      title: "Public IP",
      dataIndex: "publicIp",
      key: "publicIp",
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
    },
  ];

  const fetchEndpoints = () => {
    const userId = localStorage.getItem("userId");

    let data = {
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      filter: {
        filterBy: "",
        value: "",
      },
      search,
      userId: parseInt(userId),
      token: AUTH_TOKEN(),
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAssignedInstances`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEndpointData(res?.data?.assignedInstance);
        setTotalCount(res?.data?.totalCount);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={16}>
          <Alert
            message="You can login directly using SSH or other protocols as long as you have the credentials with it. Otherwise, try connecting with a jump server"
            type="info"
            showIcon
            closable
          />
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Endpoint Credentials</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={endpointData}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      current: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default Endpoints;
