import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Steps,
  Button,
  Input,
  Alert,
  Select,
  Form,
  DatePicker,
  Typography,
  InputNumber,
} from "antd";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, DOMAIN_ID } from "../../constants";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { Link } from "react-router-dom";
const { Step } = Steps;
const { Option } = Select;
const { Title, Text } = Typography;

const WorkloadwalletAssign = () => {
  const [current, setCurrent] = useState(0);
  const [stepOneSecondVisible, setStepOneSecondVisible] = useState(false);
  const [stepOneOneVisible, setStepOneOneVisible] = useState(true);
  const [idName, setidName] = useState("Authnull Identity");
  const [id, setid] = useState("Asif@authnull.com");
  const [AssignedGroup, setAssignedGroup] = useState("Group #2");
  const [type, setType] = useState("User Assigned");
  const [selectedTtl, setSelectedTtl] = useState("1 Day");
  const [selectedRotation, setSelectedRotation] = useState("1 Day");
  const [walletEmail, setWalletemail] = useState("Identity@authnull.com");
  const [form] = Form.useForm();
  const [isTabChange, setisTabChange] = useState(false);

  return (
    <Row>
      {/* Your content for the second step */}
      <Col span={24}>
        <Row style={{ marginTop: "2rem" }}>
          <h3>Assign to Wallet</h3>
        </Row>
        <Row className="content-conatiner">
          <Col span={24}>
            <Form form={form} layout="vertical">
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={8}>
                      <Form.Item
                        name="holdingpermisson"
                        label="Who will hold these permissions?"
                        initialValue={id}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="walletemail" label="Wallet Email" initialValue={walletEmail}>
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Wallet"
                          mode="multiple"
                          options={[
                            { value: 0, label: "Wajah@kloudone.com" },
                            { value: 1, label: "Asif@kloudone.com" },
                            { value: 7, label: "identity@authnull.com" },
                            { value: 30, label: "Inam@kloudone.com" },
                          ]}
                          labelInValue={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}></Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={8}>
                      <Form.Item name="selectRotation" label="Rotation of Credentials">
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Duration"
                          options={[
                            { value: 0, label: "Rotate per use" },
                            { value: 1, label: "1 Day" },
                            { value: 7, label: "1 Week" },
                            { value: 30, label: "30 Days" },
                            { value: "custom", label: "Enter Custom Value" },
                          ]}
                          labelInValue={true}
                          onChange={(option) => setSelectedRotation(option.label)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="selectTTL" label="Assignment Duration">
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Duration"
                          options={[
                            { value: 0, label: "1 Use" },
                            { value: 1, label: "1 Day" },
                            { value: 7, label: "1 Week" },
                            { value: 30, label: "30 Days" },
                            { value: "custom", label: "Enter Custom Value" },
                          ]}
                          labelInValue={true}
                          onChange={(option) => setSelectedTtl(option.label)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}></Col>
                  </Row>
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  {selectedRotation === "Enter Custom Value" && (
                    <Form.Item name="select-custom" label="Days">
                      <InputNumber style={{ width: "100% " }} min={0} max={30} />
                    </Form.Item>
                  )}
                </Col>
                <Col span={8}>
                  {selectedTtl === "Enter Custom Value" && (
                    <Form.Item name="select-custom-date" label="Days">
                      <InputNumber style={{ width: "100% " }} min={0} max={30} />
                    </Form.Item>
                  )}
                </Col>
                <Col span={6}></Col>
              </Row>

              <Row style={{ marginTop: "1rem", marginLeft: "29.8rem" }} justify="end">
                <Col></Col>
                <Col span={15}>
                  <Button type="primary">Save</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default WorkloadwalletAssign;
