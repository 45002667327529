import { Col, Row, Select, Table, Button, Input, message, Cascader, Spin, Empty } from "antd";
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_PAM_API } from "../../../constants";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const Jumpserver = () => {
  const [jumpServerData, setJumpServerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignConnection, setIsAssignConnection] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const PAGESIZE = 10;
  const [sortModel, setSortModel] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    const fetchJumpserver = () => {
      let pageDetails = {
        pageId: currentPage,
        pageSize: PAGESIZE,
        search,
        filter: {
          filterBy: filterBy,
          value: filteredValue,
        },
        token: AUTH_TOKEN(),
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
      };
      setIsLoading(true);
      axios
        .post(`${REACT_APP_PAM_API}/jumpserver/listJumpServer`, pageDetails, {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setJumpServerData(
            res?.data?.jumpServers?.map((data, i) => ({
              id: i + 1,
              ...data,
            }))
          );

          setTotalCount(res?.data?.total);
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err?.response?.data?.message === "Credentials are invalid" ||
            err?.response?.status === 401
          ) {
            logOutUser();
          } else {
            notification.open({
              type: "error",
              content: "Unable to fetch jump server",
            });
          }
        });
    };
    fetchJumpserver();
  }, [currentPage, debouncedValue, filteredValue]);
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const columns = [
    {
      headerName: "Server Name",
      field: "serverName",
      key: "serverName",
      flex: 1,
    },
    {
      headerName: "Jump Host Address",
      field: "publicIpAddress",
      key: "publicIpAddress",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      key: "status",
      flex: 1,
      renderCell: (val) =>
        val.row.status.toLowerCase() === "active"
          ? "Active"
          : val.row.status.toLowerCase() === "in progress"
          ? "In progress"
          : "Inactive",
    },
    {
      headerName: "View Connections",
      field: "viewConnections",
      key: "viewConnection",
      flex: 1,
      renderCell: (val) => <Link to={`/connections/${val.row.publicIpAddress}`}>Show</Link>,
    },
    {
      headerName: "Action",
      field: "action",
      key: "action",
      flex: 1,
      renderCell: (val) => (
        <Link
          to={`/connections/createConnection?serverName=${val.row.serverName}&id=${val.row.id}&ip=${val.row.publicIpAddress}`}
        >
          Assign Connection
        </Link>
      ),
    },
  ];

  const option = [
    {
      value: "status",
      label: "Status",
      children: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
        {
          label: "In progress",
          value: "In progress",
        },
      ],
    },
  ];
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
    setCurrentPage(1);
  };
  const filterOption = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
    {
      label: "In progress",
      value: "In progress",
    },
  ];

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Jump Server</h2>
            </Col>
            <Col>
              <Link to={"/linuxMachines/addJumpServer"}>
                <Button type="primary">Add Jump Server</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      <Cascader
                        options={option}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (currentPage !== 1) {
                              setCurrentPage(1);
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* {isLoading ? (
                <Spin
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "200px",
                  }}
                  indicator={<LoadingOutlined style={{ fontSize: 34 }} spin />}
                  size="large"
                />
              ) : ( */}
              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={jumpServerData}
                paginationMode="server"
                columns={columns}
                rowCount={totalCount}
                page={currentPage}
                onPaginationModelChange={(newPage) => {
                  setCurrentPage(newPage.page);
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
              {/* ) } */}

              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={jumpServerData}
                pagination={{
                  pageSize: PAGESIZE,
                  total: totalCount,
                  showTotal: (total) => `Total ${total} items`,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
              /> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Jumpserver;
