import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  theme,
  message,
  List,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { REACT_APP_API_TENANTS_URL } from "../../../constants";
import axios from "axios";
import { CopyTwoTone } from "@ant-design/icons";

const OktaSetup = ({ tenantName, orgName }) => {
  const [current, setCurrent] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [copyUrl, setCopyUrl] = useState(false);

  const steps = [
    {
      title: "Instruction",
    },
    {
      title: "Assignment",
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const prev = () => {
    setCurrent(current - 1);
  };

  const contentStyle = {
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    margin: `16px 0 `,
    padding: `1rem`,
  };

  const handleSave = (e) => {
    console.log("Received values of form: ", e);
    const loginData = {
      ...e,
      authenticationMethod: "Okta",
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/authenticatemethod`, loginData)
      .then((res) => {
        console.log(res.data);
        notification.open({
          type: "success",
          content: "Authenticate Method updated successfully",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      });
  };

  return (
    <>
      {setNotification}
      <Row
        className="content-conatiner"
        style={{
          height: "599px",
          overflow: "scroll",
        }}
      >
        <Col span={20}>
          {/* <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">SAML 2.0</h2>
            </Col>
          </Row> */}
          {/* <Row>
            <Col span={20}><Steps current={current} items={items} /></Col>
          </Row> */}
          <Row>
            {/* {current === 0 ? ( */}
            <>
              <Col span={24}>
                <div style={contentStyle}>
                  <Row align="middle">
                    <Col span={24}>
                      <h4>1. OKTA app creation</h4>
                      <p>
                        After Signing Up for the Admin Account, Next is to Create a SAML 2.0 App.
                      </p>

                      <h4>2. Create App Integration</h4>
                      <p>
                        Go to Applications in the Admin Console.
                        <br />
                        Click "Create App Integration."
                        <br />
                        Choose "SAML 2.0" as the Sign-in method and click "Next."
                      </p>
                      <h4>3. General Information</h4>
                      <p>
                        {" "}
                        {/* 1. Single sign on URL: The location where the SAML assertion is sent with a
                        HTTP POST. This is often referred to as the SAML Assertion Consumer Service
                        (ACS) URL for your application.
                        <br />
                        2. Recipient URL: The location where the application may present the SAML
                        assertion. This is usually the same location as the Single Sign-On URL.
                        <br />
                        3. Destination URL: Identifies the location where the SAML response is
                        intended to be sent inside of the SAML assertion. This is useful to prevent
                        malicious forwarding of responses to unintended recipients. This should be
                        the same location as the Single Sign-On URL unless your application
                        explicitly defines a specific value.
                        <br />
                        4. Audience URI (SP Entity ID): The application-defined unique identifier
                        that is the intended audience of the SAML assertion. This is most often the
                        SP Entity ID of your application. */}
                        Single sign on URL,Recipient URL,estination URL,Audience URI{" "}
                        <Input
                          disabled
                          value={`https://${tenantName}.${orgName}.authnull.com/okta/RedirectURl`}
                          placeholder="Enter your username"
                          suffix={
                            <Tooltip title={copyUrl ? "Copied" : "Copy URL"}>
                              <CopyTwoTone
                                onClick={() => {
                                  setCopyUrl(true);
                                  navigator.clipboard.writeText(
                                    `https://${tenantName}.${orgName}.authnull.com/okta/RedirectURl`
                                  );
                                }}
                              />
                            </Tooltip>
                          }
                        />
                      </p>
                      <h4>4. SAML Settings</h4>
                      <p>
                        Enter the necessary SAML settings.
                        <br />
                        Optionally, preview the SAML Assertion XML.
                        <br />
                        Click "Next."
                      </p>
                      <h4>5. Configuration Information</h4>
                      <p>
                        <u>
                          <b>If it's for internal use only:</b>
                        </u>{" "}
                        <br />
                        Select "I'm an Okta customer adding an internal app." <br />
                        Choose appropriate options and fill in required fields.
                        <br />
                        <u>
                          {" "}
                          <b>If you want to add it to the Okta Integration Network (OIN):</b>
                        </u>{" "}
                        <br /> Select "I'm a software vendor" and follow the prompts.
                        <br /> Click "Finish" to create the integration.
                      </p>
                      <p>
                        Your OKTA SAML APP creation and configuration is Successfully completed.
                      </p>
                    </Col>
                  </Row>
                </div>
                {/* <Row justify="end">
                    <Col>
                      <Space>
                        {current < 1 && <Button onClick={() => navigate(-1)}>Cancel</Button>}
                        {current < steps.length - 1 && (
                          <Button
                            type="primary"
                            onClick={() => {
                              setCurrent(current + 1);
                            }}
                          >
                            Next
                          </Button>
                        )}
                      </Space>
                    </Col>
                  </Row> */}
              </Col>
            </>
            {/* ) : ( */}
            {/* <>
                <Col span={24}>
                  <Form form={form} layout="vertical" onFinish={handleSave}>
                    <div style={contentStyle}>
                      <Row justify="space-between" align="middle">
                        <Col span={11}>
                          <Form.Item
                            name="ssoUrl"
                            label="SSO URL"
                            rules={[
                              {
                                required: true,
                                message: "Metadata URL required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <Row justify="end">
                      <Col>
                        <Space>
                          {current > 0 && <Button onClick={() => prev()}>Previous</Button>}
                          {current === steps.length - 1 && (
                            <Button type="primary" htmlType="submit">
                              Done
                            </Button>
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </> */}
            {/* )} */}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OktaSetup;
