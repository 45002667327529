import { Cascader, Col, Input, Row, Table, Tag, message, Button, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { MoreOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { useDebounce } from "../../../common/debounce";
import AssignEndpointUser from "../endpointsUsers/AssignEndpointUser";

import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const EndpointDetailsUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [epmUser, setEpmUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  const isTabChange = queryParams.get("tab");
  const isInstanceId = queryParams.get("instanceId");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [endpoint, setEndpoint] = useState([]);
  const [Isassignwallet, setIsassignwallet] = useState(false);
  const [sortModel, setSortModel] = useState();

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchEPMUsers();
  }, [debouncedValue, currentPage, paginationParams]);

  const fetchEPMUsers = (id) => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      token: AUTH_TOKEN(),
      instanceIds: [Number(isInstanceId)],
      filter: {
        filterBy: "",
        value: "",
      },
      search: search,
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res, totalCount) => {
        setIsLoading(false);
        setEpmUser(
          res?.data?.epmUsers?.map((data) => ({
            ...data,
            id: data.userId,
          }))
        );
        console.log(res);
        setTotalCount(res.data.totalCount);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Credentials are invalid") {
          logOutUser();
        } else {
        }
      });
  };
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const EndpointStatus = (lastUpdated) => {
    const lastActive = moment.unix(lastUpdated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return true;
    } else if (diff > 10) {
      return false;
    }
    return false;
  };

  const tab1columns = [
    {
      headerName: "User Name",
      field: "userName",
      key: "userName",
      flex: 1,
    },
    {
      field: "instances",
      headerName: "Endpoints",
      key: "instances",
      flex: 1,
      renderCell: (val) => (
        <span>
          {console.log(val)}
          {val.row.instances.map((tag) => {
            const lastActive = moment
              .unix(tag.lastActive)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD HH:mm:ss");
            const diff = moment().diff(lastActive, "minutes");
            return (
              // <Link
              //   to={`/endpointuserDetails?epmUsername=${record.userName}&epmUserid=${record.userId}`}
              // >
              <Tag color={diff >= 20 ? "red" : diff >= 10 ? "orange" : "green"} key={tag}>
                {tag.hostname}
              </Tag>
              // </Link>
            );
          })}
        </span>
      ),
    },
    {
      headerName: "User Type",
      field: "userType",
      key: "userType",
      flex: 1,
    },
    // {
    //   headerName: "Credentials",
    //   field: "countOfCredentials",
    //   key: "countOfCredentials",
    //   flex: 1,
    // },
    {
      headerName: "Action",
      key: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedRecords(val.row);
                      setIsassignwallet(true);
                    }}
                  >
                    Assign to Wallets
                  </Link>
                ),
              },
              {
                key: "view-session-recording",
                label: (
                  <Link to={`/sessionRecordings?epmUser=${val.row.userName}`}>
                    View Session Recordings
                  </Link>
                ),
              },
              {
                key: "checkout-user",
                label: (
                  <Link
                    to={`/checkoutUser/${val.row.userId}`}
                    state={{
                      instanceName: val.row.instances[0].hostname,
                      instanceId: JSON.stringify(val.row.instances[0].instanceId),
                      epmUsername: val.row.userName,
                      os: val.row.instances[0].os,
                    }}
                  >
                    Checkout User
                  </Link>
                ),
                disabled: EndpointStatus(val.row.instances.map((ins) => ins.lastActive)),
              },

              {
                key: "reset-credential",
                label: (
                  <Link
                    to={`/reset-credentials?epmuser=${val.row.userName}&epmuserId=${val.row.userId}`}
                  >
                    Force Reset Credentials
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);
    console.log(isTabChange);
    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpoint([]);
    }
    fetchEPMUsers();
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {/* <Table
                loading={isLoading}
                columns={tab1columns}
                dataSource={epmUser}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                }}
                onChange={handleTableChange}
              /> */}
              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={epmUser}
                columns={tab1columns}
                paginationMode="server"
                rowCount={totalCount}
                page={paginationParams.current}
                pageSize={paginationParams.pageSize}
                onPaginationModelChange={(newPage) => {
                  setPaginationParams({
                    ...paginationParams,
                    current: newPage.page,
                  });
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {Isassignwallet && (
        <AssignEndpointUser
          Isassignwallet={Isassignwallet}
          setIsassignwallet={setIsassignwallet}
          selectedRecords={selectedRecords}
        />
      )}
    </>
  );
};

export default EndpointDetailsUser;
