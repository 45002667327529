import {
  Button,
  Modal,
  Row,
  Col,
  Input,
  Table,
  Form,
  Select,
  Space,
  Checkbox,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import Paragraph from "antd/es/skeleton/Paragraph";
import Password from "antd/es/input/Password";
const EpmGroupAuthflow = ({ IsGroupauthflow, setIsGroupauthflow, selectedRecords }) => {
  const [epmUserData, setEPMUserData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const PAGESIZE = 5;
  const [form] = Form.useForm();
  const { Paragraph, Text } = Typography;
  const [selectedfirst, setSelectedfirst] = useState("password");
  const [selectedsecond, setSelectedsecond] = useState("ssh");
  const [selectedthird, setSelectedthird] = useState("DID");
  const [notificationGrp, setnotificationGrp] = useState(false);
  const [isOpenPAM, setisOpenPAM] = useState(false);
  const [code, setCode] = useState("");

  const firstFactordata = [
    {
      value: "password",
      label: "Password",
    },
    {
      value: "ssh",
      label: "SSH Key",
    },
    {
      value: "did",
      label: "DID",
    },
  ];

  const secondFactordata = [
    {
      value: "password",
      label: "Password",
    },
    {
      value: "ssh",
      label: "SSH Key",
    },
    {
      value: "DID",
      label: "DID",
    },
    {
      value: "",
      label: "None",
    },
  ];

  const thirdFactordata = [
    {
      value: "password",
      label: "Password",
    },
    {
      value: "ssh",
      label: "SSH Key",
    },
    {
      value: "DID",
      label: "DID",
    },
  ];

  const fetchPamCode = () => {
    let requestData = {
      instanceId: selectedRecords?.groupId,
      domainId: DOMAIN_ID(),
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/viewGeneratedPamCode`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let data = res.data.Code;
        code = data.split("\n").join("\n").replace(/\t/g, " ").replace(/ {2,}/g, " ");
        if (!res.data.Code) {
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Credentials are invalid") {
          logOutUser();
        } else if (err.response.status == 401) {
          logOutUser();
        }
      });
  };

  const handleSaveAuthType = (e) => {
    let groupAuthData = {
      groupId: selectedRecords?.groupId,
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      authFlow:
        selectedsecond === "None"
          ? `${selectedfirst},${selectedthird}`
          : selectedthird === "None"
          ? `${selectedfirst},${selectedsecond}`
          : `${selectedfirst},${selectedsecond},${selectedthird}`,
      token: AUTH_TOKEN(),
    };
    if (IsGroupauthflow) {
      axios
        .post(`${REACT_APP_PAM_API}/instanceGroup/addAuthFlowToInstanceGroup`, groupAuthData, {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        })
        .then((res) => {
          if (res.data.Code == "200") {
            setnotificationGrp(true);
            fetchPamCode();
            setisOpenPAM(true);
          } else {
            code = res.data.Code;
            setnotificationGrp(false);
            setisOpenPAM(true);
          }
        })
        .catch((err) => {
          if (err.response.data.message == "Credentials are invalid") {
            logOutUser();
          } else if (err.response.status == 401) {
            logOutUser();
          }
        });
    }
  };

  const downloadPamCode = (text) => {
    var fileName = "PAM.SO";
    var fileContent = text;
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(fileContent)
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleOk = () => {
    setTimeout(() => {
      setIsGroupauthflow(false);
    }, 1000);
  };
  const handleCancel = () => {
    setIsGroupauthflow(false);
  };

  return (
    <Modal
      open={IsGroupauthflow}
      title={`Authentication Flow - ${selectedRecords.groupName}`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" type="default" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Generate
        </Button>,
      ]}
      width={900}
    >
      <Row>
        <Col span={24}>
          <Row style={{ margin: "1rem 0" }} className="search-box-container">
            <Col span={18}>
              <div>
                <a href="https://help.authnull.com/docs/pam/pluggableauthmodule/" target="_blank">
                  This section requires changes to linux pluggable Authentication Modules. Click
                  here to learn more
                </a>
              </div>
            </Col>
          </Row>
          <Form form={form} layout="vertical">
            <Row>
              <Col span={24}>
                <Row justify={"space-between"}>
                  <Col span={8}>
                    <Form.Item
                      name="firstfactorid"
                      label="First Factor"
                      rules={[
                        {
                          message: "Select First Factor!",
                        },
                      ]}
                    >
                      <Select
                        tokenSeparators={[","]}
                        options={firstFactordata}
                        placeholder="Select First Factor"
                        defaultValue={selectedfirst}
                        onChange={(option) => setSelectedfirst(option.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="secondfactorid"
                      label="Second Factor"
                      rules={[
                        {
                          message: "Select Second Factor!",
                        },
                      ]}
                    >
                      <Select
                        tokenSeparators={[","]}
                        labelInValue={true}
                        options={secondFactordata}
                        placeholder="Select Second Factor"
                        defaultValue={selectedsecond}
                        onChange={(option) => setSelectedsecond(option.value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}></Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row align="middle" justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      name="thirdfactorid"
                      label="Third Factor"
                      rules={[
                        {
                          message: "Select Third Factor!",
                        },
                      ]}
                    >
                      <Select
                        tokenSeparators={[","]}
                        options={thirdFactordata}
                        labelInValue={true}
                        placeholder="Select Third Factor"
                        defaultValue={selectedthird}
                        onChange={(option) => setSelectedthird(option.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}></Col>

                  <Col span={4}></Col>
                </Row>
              </Col>
            </Row>

            {isOpenPAM && (
              <Row>
                <Col span={12}>
                  <Typography>
                    <Text copyable code>
                      {code}
                    </Text>
                  </Typography>
                  <br />
                  <a onClick={() => downloadPamCode(code)}> Download PAM.SO files. </a>
                </Col>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default EpmGroupAuthflow;
