import {
  Col,
  Row,
  Select,
  Table,
  Dropdown,
  Button,
  Input,
  message,
  Space,
  Alert,
  Tag,
  Spin,
  Tooltip,
  Cascader,
  DatePicker,
  Collapse,
  Typography,
} from "antd";
import { MoreOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import moment from "moment-timezone";

import { useEffect, useState } from "react";
import axios from "axios";
import ReactJoyride from "react-joyride";
import { DOMAIN_ID, ORG_ID, REACT_APP_API_TENANTS_URL } from "../../constants";

const { Search } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const TenantConfig = () => {
  const [tenantData, setTenantData] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [notification, setNotification] = message.useMessage();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mfa = queryParams.get("mfa");
  const type = queryParams.get("type");

  useEffect(() => {
    console.log(mfa);
    fetchTenantDetails();
  }, []);

  // useEffect(() => {
  //   handleActiveKey();
  // }, [activeKey]);

  // const handleActiveKey = () => {
  //   if (mfa === "true") {
  //     setActiveKey(["1"]);
  //   } else {
  //     setActiveKey([]);
  //   }
  // };
  //Tour
  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);

  useEffect(() => {
    //TODO : Onboarding api call done here
    setIsOnboarding(true);
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        target: "#onboarding_step1",
        content: (
          <span>
            <h4 style={{ marginBottom: "0" }}>Select any MFA Device </h4>
            <br />
            Multi-Factor Authentication (MFA) adds an extra layer of security by requiring users to
            provide two or more verification factors before gaining access.
          </span>
        ),
        disableBeacon: true,

        // title: "MFA Device",
      },
      {
        target: "#onboarding_step2",
        content: (
          <span>
            {" "}
            <h4 style={{ marginBottom: "0" }}>Select any Endpoint Authentication MFA method </h4>
            <br />
            Selecting an Endpoint Authentication Multi-Factor Authentication (MFA) method involves
            choosing a specific approach to verify the identity of users during the authentication
            process. This step is crucial for enhancing security.{" "}
          </span>
        ),
        disableBeacon: true,
        // title: "Endpoint Authentication MFA",
      },
      {
        target: "#onboarding_step3",
        content: (
          <span>
            <h4 style={{ marginBottom: "0" }}> Select any Authentication Policy</h4>
            <br />
            Selecting an Authentication Policy involves defining rules and guidelines that dictate
            how user authentication is managed within a system. This policy helps shape the security
            framework and user access controls.{" "}
          </span>
        ),
        disableBeacon: true,
        // title: "Authentication Policy",
      },
    ];
    const tourPamLocal = [
      {
        target: "#onboarding_step4",
        content: (
          <span>
            <h4 style={{ marginBottom: "0" }}> Select any SSO MFA </h4>
            <br />
            Viewing audited policies involves examining a record of the authentication policies that
            have been audited within a system. This process provides insights into the historical
            performance, effectiveness, and outcomes of implemented security measures.
          </span>
        ),
        disableBeacon: true,
        // title: "MFA Device",
      },
    ];
    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };
    return (
      <ReactJoyride
        className="tour-container"
        steps={mfa ? tourStep : type === "pamLocal" ? tourPamLocal : tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };

  const fetchTenantDetails = () => {
    let tenantPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios.post(`${REACT_APP_API_TENANTS_URL}/getTenantDetail`, tenantPayload).then((res) => {
      setTenantData(res.data.data);
    });
  };

  const updatePlatformMfa = (e) => {
    let platformmfaPayload = {
      email: localStorage.getItem("UserName"),
      // tenantId: localStorage.getItem("TenantId")
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      platformmfa: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setPlatformMfa`, platformmfaPayload)
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateMfaDevices = (e, val) => {
    console.log({ e, val });
    let mfaDevicePayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      mfadevices: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setMfaDevices`, mfaDevicePayload)
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `MFA Device updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateSsoMfa = (e, val) => {
    let SsoMfaPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      ssomfa: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setSsoMfa`, SsoMfaPayload)
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `SSO MFA updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateAuthenticationPolicy = (e, val) => {
    let AuthenticationPolicyPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      authenticationpolicy: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setAuthenticationPolicy`, AuthenticationPolicyPayload)
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Authentication Policy updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateEntityAuthentication = (e, val) => {
    let EntityAuthenticationPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityauthentication: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setEntityAuthentication`, EntityAuthenticationPayload)
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Endpoint Authentication MFA updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateConnectionMode = (e, val) => {
    let ConnectionModePayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      connectionmode: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setConnectionMode`, ConnectionModePayload)
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Connection Mode updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateCredentialStore = (e, val) => {
    let CredentialStorePayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      credentialstore: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setCredentialStore`, CredentialStorePayload)
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Credential Store updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateCredentialShareMode = (e, val) => {
    let CredentialSharePayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      credentialsharemode: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setCredentialShareMode`, CredentialSharePayload)
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Share Credentials updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {setNotification}
      <div className="onboarding-container">
        {isOnBoarding && startTour()}

        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <h2 className="title">Tenant Config</h2>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <p className="search-label">Tenant Name</p>
                <Input placeholder="Enter tenant name" value={tenantData?.tenantname} disabled />
              </Col>
            </Row>

            <Collapse
              // accordion
              style={{ marginTop: "2rem" }}
              defaultActiveKey={mfa ? ["1", "4"] : type === "pamLocal" ? ["1"] : []}
            >
              <Panel header="MFA device + MFA settings" key="1">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={24}>
                    <Row gutter={16}>
                      <Col span={6} id="onboarding_step1">
                        <p className="search-label">MFA Device</p>

                        <Select
                          defaultValue={
                            tenantData?.mfadevices
                              ? tenantData?.mfadevices === 0
                                ? ""
                                : tenantData?.mfadevices
                              : ""
                          }
                          onChange={updateMfaDevices}
                        >
                          <Option value={1}>Decentralized wallet</Option>
                          {/* Options will needed in future */}
                          {/* <Option value={2}>Directory Groups</Option>
                          <Option value={3}>Yubikey</Option>
                          <Option value={4}>Authenticator Apps</Option>
                          <Option value={5}>OkTA verify</Option> */}
                        </Select>
                      </Col>
                      <Col span={6} id="onboarding_step4">
                        <p className="search-label">SSO MFA(Admin Console)</p>

                        <Select
                          defaultValue={
                            tenantData?.ssomfa
                              ? tenantData?.ssomfa === 0
                                ? ""
                                : tenantData?.ssomfa
                              : ""
                          }
                          onChange={updateSsoMfa}
                        >
                          {" "}
                          <Option value={1}>No MFA </Option>
                          <Option value={2}>Password + Biometric</Option>
                          {/* Options will needed in future */}
                          {/* <Option value={2}>Biometric only App</Option>
                          <Option value={3}>OTP</Option>
                          <Option value={4}>Application Notification</Option> */}
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">Service Account MFA</p>

                        <Select defaultValue={1}>
                          <Option value={0}>No MFA - use service account policie</Option>
                          <Option value={1}>
                            Use MFA for services (defaults to application MFA)
                          </Option>
                        </Select>
                      </Col>
                    </Row>
                    {/* Below fields will needed in future */}

                    <Row gutter={16}>
                      {/* <Col span={6}>
                        <p className="search-label">Application MFA (multi-select)</p>

                        <Select
                          defaultValue={
                            tenantData?.platformmfa === 0 ? "" : tenantData?.platformmfa
                          }
                          onChange={(e) => {
                            updatePlatformMfa(e);
                          }}
                        >
                          <Option value={1}>No MFA (use sso caching) </Option>
                          <Option value={2}>Decentralized credentials </Option>
                          <Option value={3}>Biometric MFA on all auth</Option>
                          <Option value={4}>App based MFA</Option>
                        </Select>
                      </Col> */}
                      <Col span={6} id="onboarding_step2">
                        <p className="search-label">AD / Kerberos / Endpoint Authentication MFA</p>

                        <Select
                          defaultValue={
                            tenantData?.entityauthentication === 0
                              ? ""
                              : tenantData?.entityauthentication
                          }
                          onChange={updateEntityAuthentication}
                        >
                          <Option value={1}>No MFA (use sso caching) </Option>
                          <Option value={2}>Credential + Biometric MFA on all auth </Option>
                          <Option value={3}>Credential + OTP on all auth</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Application Control" key="2">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={12}>
                    <p className="search-label">Action</p>

                    <Select defaultValue={1}>
                      <Option value={1}>
                        Audit everything (audit and generate policies automatically)
                      </Option>
                      <Option value={2}>
                        Live mode (enforcement mode) also a zero trust mode when uac is on. New
                        policies are also created in blocked status{" "}
                      </Option>
                      <Option value={3}>
                        Policy mode (simulate enforcement and generate new policies). New policies
                        are created in blocked status
                      </Option>
                    </Select>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Credential store and sharing" key="3">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Credential Store</p>

                    <Select
                      defaultValue={
                        tenantData
                          ? tenantData?.credentialstore === 0
                            ? ""
                            : tenantData?.credentialstore
                          : ""
                      }
                      onChange={updateCredentialStore}
                    >
                      <Option value={1}>Decentralize</Option>
                      {/* Options will needed in future */}
                      {/* <Option value={2}>Use a vault</Option> */}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Share Credentials</p>

                    <Select
                      defaultValue={
                        !tenantData?.credentialsharemode
                          ? ""
                          : tenantData?.credentialsharemode === 0
                          ? ""
                          : tenantData?.credentialsharemode
                      }
                      onChange={updateCredentialShareMode}
                      // mode="multiple"
                    >
                      <Option value={1}>Share to email </Option>
                      <Option value={2}>Share to wallets </Option>
                      <Option value={3}>Share via SSO only</Option>
                    </Select>
                  </Col>
                  {/* <Col span={6}>
                    <p className="search-label">Service Account MFA</p>

                    <Select>
                      <Option value={1}>No MFA - use service account policie</Option>
                      <Option value={2}>Use MFA for services (defaults to application MFA)</Option>
                    </Select>
                  </Col> */}
                </Row>
              </Panel>
              <Panel header="Authentication Policy" key="4">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={12} id="onboarding_step3">
                    <p className="search-label">Authentication Policy</p>

                    <Select
                      defaultValue={
                        !tenantData?.authenticationpolicy
                          ? ""
                          : tenantData?.authenticationpolicy === 0
                          ? ""
                          : tenantData?.authenticationpolicy
                      }
                      onChange={updateAuthenticationPolicy}
                    >
                      <Option value={1}>
                        Audit - this will audit existing policies and create new policies (learn the
                        system)
                      </Option>
                      <Option value={2}>
                        Policy - Simulate enforcement policies and see the impact of blocked
                        policies
                      </Option>
                      <Option value={3}>
                        Live mode - Zero trust (block everything but trusted) - warning this
                        requires policies to be available
                      </Option>
                    </Select>
                  </Col>
                </Row>
              </Panel>
              {/* Below fields will needed in future */}

              {/* <Panel header="Service Account" key="5">
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Discovery of service accounts</p>

                    <Select>
                      <Option value={0}>Discover and manage with policies</Option>
                      <Option value={1}> Ignore service accounts</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Authentication</p>

                    <Select>
                      <Option value={1}>
                        Zero trust (block everything but trusted) - warning this requires policies
                        to be available{" "}
                      </Option>
                      <Option value={2}>
                        {" "}
                        Audit Mode - this will audit existing policies and create new policies
                      </Option>
                      <Option value={3}>
                        Policy - Simulate enforcement policies and see the impact of blocked
                        policies
                      </Option>
                    </Select>
                  </Col>
                </Row>
              </Panel> */}
              {/* Below fields will needed in future */}

              {/* <Panel header="Revoke local admin credentials" key="6">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Windows</p>

                    <Select>
                      <Option value={0}>Revoke admin credentials</Option>
                      <Option value={1}> Ignore</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Linux</p>

                    <Select>
                      <Option value={0}>Revoke admin credentials</Option>
                      <Option value={1}>Ignore</Option>
                    </Select>
                  </Col>
                </Row>
              </Panel> */}
              <Panel header="Connection" key="7">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Connection Mode</p>

                    <Select
                      defaultValue={
                        !tenantData.connectionmode
                          ? ""
                          : tenantData?.connectionmode === 0
                          ? ""
                          : tenantData?.connectionmode
                      }
                      onChange={updateConnectionMode}
                    >
                      <Option value={1}>Jump Server</Option>
                      <Option value={2}> Direct</Option>
                      <Option value={3}> Direct and Jump Server</Option>
                    </Select>
                  </Col>
                </Row>
              </Panel>
              {/* Below fields will needed in future */}

              {/* <Panel header="Risk based mfa and dynamic authz" key="8">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Enable risk based mfa</p>

                    <Select>
                      <Option value={0}>Yes</Option>
                      <Option value={1}> No</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Enable dynamic authz</p>

                    <Select>
                      <Option value={1}>Yes</Option>
                      <Option value={2}>No</Option>
                    </Select>
                  </Col>
                </Row>
              </Panel> */}
              <Panel header=" Enable Direct AD authentication without policies" key="9">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Enable Ad domain authentication</p>

                    <Select defaultValue={0}>
                      <Option value={1}>Yes</Option>
                      <Option value={0}> No</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Enable dynamic authz</p>

                    <Select defaultValue={0}>
                      <Option value={1}>Yes</Option>
                      <Option value={0}>No</Option>
                    </Select>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TenantConfig;
