import { Col, Row, Select, Card, Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { logOutUser } from "../../common";
const { Option } = Select;

const Module = () => {
  return (
    <div className="onboarding-container">
      <Row className="content-conatiner" style={{ margin: "1rem 4rem 0" }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Modules</h2>
            </Col>
          </Row>

          <Row gutter={[16, 24]}>
            <Col span={8}>
              <Card style={{ cursor: "pointer" }} className="card-container card-border-module">
                <h3 style={style.h3}>Privileged Access</h3>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  {/* <p style={{ width: "1.5rem", height: "1.5rem", background: "#52c41a" }}></p> */}
                  <Checkbox
                    className="checkbox-container"
                    checked={true}
                    onChange={(e) => {
                      console.log(e);
                    }}
                  ></Checkbox>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ cursor: "pointer" }} className="card-container card-border-module">
                <h3 style={style.h3}>Policies</h3>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  <Checkbox
                    className="checkbox-container"
                    checked={true}
                    onChange={(e) => {
                      console.log(e);
                    }}
                  ></Checkbox>{" "}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ cursor: "pointer" }} className="card-container card-border-module">
                <h3 style={style.h3}>Windows EPS</h3>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  <Checkbox
                    className="checkbox-container"
                    checked={true}
                    onChange={(e) => {
                      console.log(e);
                    }}
                  ></Checkbox>{" "}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ cursor: "pointer" }} className="card-container card-border-module">
                <h3 style={style.h3}>Dynamic Privileged Access</h3>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  <Checkbox
                    className="checkbox-container"
                    checked={true}
                    onChange={(e) => {
                      console.log(e);
                    }}
                  ></Checkbox>{" "}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ cursor: "pointer" }} className="card-container card-border-module">
                <h3 style={style.h3}>Dynamic AuthZ</h3>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  <Checkbox
                    className="checkbox-container"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  ></Checkbox>{" "}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ cursor: "pointer" }} className="card-container card-border-module">
                <h3 style={style.h3}>Integrations </h3>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  <Checkbox
                    className="checkbox-container"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  ></Checkbox>{" "}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card style={{ cursor: "pointer" }} className="card-container card-border-module">
                <h3 style={style.h3}>AI Co-pilot </h3>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  <Checkbox
                    className="checkbox-container"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  ></Checkbox>{" "}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const style = {
  h3: {
    textAlign: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: "600",
    color: "#000",
  },
};

export default Module;
