import React from "react";
import { Route, Routes } from "react-router-dom";
import Signup from "./Signup";
import Login from "./Login";
import TenantLogin from "./tenant/Login";
import TenantSignup from "./tenant/Signup";

const UnAuthorizeRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="org/*">
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="tenant/*">
          <Route path="login" element={<TenantLogin />} />
          <Route path="signup" element={<TenantSignup />} />
        </Route>
      </Routes>
    </>
  );
};

export default UnAuthorizeRoutes;
