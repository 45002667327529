import React, { useState } from "react";
import { Button, Cascader, Checkbox, Col, Form, Input, InputNumber, Row, message } from "antd";
import { REACT_APP_API, REACT_APP_API_TENANTS_URL } from "../../../constants";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

const ResetPassword = ({ loginDetails }) => {
  const [notification, setNotification] = message.useMessage();
  const [IsLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const handleFinish = (values) => {
    const loginData = {
      ...values,
      email: loginDetails.username,
      oldpassword: loginDetails.password,
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/resetPassword`, loginData, {
        headers: {
          withCredentials: true,
        },
      })
      .then((res) => {
        console.log(res);
        navigate("/");
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to login, Please try again later",
        });
      });
  };

  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <h2 className="title">Reset Password</h2>

          <Form
            form={form}
            name="register"
            onFinish={handleFinish}
            scrollToFirstError
            className="login-form"
          >
            <Form.Item
              name="newpassword"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="New Password" size="large" />
            </Form.Item>

            <Form.Item
              name="confirmpassword"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newpassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The new password that you entered do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" size="large" />
            </Form.Item>
            <div className="login-form-button">
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={IsLoading}
                >
                  Reset
                </Button>
                <br />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
