import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Table,
  Tag,
  Button,
  Input,
  notification,
  Cascader,
  Tabs,
  TabsProps,
  Dropdown,
  Breadcrumb,
  Modal,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../constants";
import { logOutUser } from "../../../common";
import axios from "axios";
import { MoreOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { fetchEndpoints, fetchEPMUsers, fetchEndpointGroups } from "../../../common/functions";
import { useDebounce } from "../../../common/debounce";
import AssignEndpointUser from "../endpointsUsers/AssignEndpointUser";
import moment from "moment-timezone";

import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const Context = React.createContext({
  name: "Default",
});
const { TabPane } = Tabs;

const EndpointGroupDetails = (isTabChange, setIsTabChange) => {
  const [instaceDataById, setinstaceDataById] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [endpoints, setEndpoints] = useState([]);
  const [instance, setInstance] = useState([]);
  const [epmUser, setEpmUser] = useState([]);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("0");
  const [endpointData, setEndpointData] = useState([]);
  const [endpointUsers, setEndpointUsers] = useState([]);
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [Isassignwallet, setIsassignwallet] = useState(false);
  const [totalUsers, setTotalUsers] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [isOpenPAM, setisOpenPAM] = useState(false);
  const navigate = useNavigate();
  const [selectedRecords, setSelectedRecords] = useState([]);

  const PAGESIZE = 10;
  let userEmail = localStorage.getItem("UserName");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupName = queryParams.get("groupName");
  const groupId = queryParams.get("groupId");

  const [notify, contextHolder] = notification.useNotification();
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpoints([]);
    }
  };

  const openNotification = (placement) => {
    notify.info({
      message: `Notification ${placement}`,
      description: <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>,
      placement,
    });
  };
  let endpointId = [];
  // let found;

  useEffect(() => {
    handleTab();
    fetchEPMUsers();
    fetchInstance();
    fetchEndpoints({ search }).then((instances) => {
      setInstance(
        instances.map((instance) => ({
          id: instance.instanceId,
          text: instance.hostName,
          publicIp: instance.publicIp,
        }))
      );
    });
    fetchInstanceGroupDetail();
  }, [debouncedValue, isTabChange, currentPage, paginationParams]);

  const fetchInstanceGroupDetail = async () => {
    let payload = {
      pageId: 1,
      pageSize: 10,
      search: search,
      filter: {
        filterBy: "",
        value: "",
      },
      domainId: DOMAIN_ID(),
      groupId: Number(groupId),
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    let config = {
      headers: {
        "X-Authorization": AUTH_TOKEN(),
        withCredentials: true,
      },
    };
    let res;
    try {
      res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/fetchInstanceGroupDetail`,
        payload,
        config
      );

      let insData = res.data.InstanceGroup.map((ins, i) => ({
        id: ins.instanceId,
        hostName: ins.hostName,
        instanceName: ins.instanceName,
        os: ins.os,
        privateIp: ins.privateIp,
        publicIp: ins.publicIp,
        state: ins.state,
      }));
      console.log(insData);
      setinstaceDataById(insData);
      setTotalUsers(res.data.totalCount);
    } catch (err) {
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
      }
    } finally {
      // selectedInstancesRow = new Array(...selectedInstancesRow); // to trigger the change
    }
  };
  const fetchInstance = (record) => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setEndpoints(
          res?.data?.instances.map((ins) => ({
            ...ins,
            id: ins.instanceId,
            instance: ins.instanceId,
            label: ins.hostName,
            ipAddress: ins.publicIpAddress,
          }))
        );
      });
  };

  const fetchEPMUsers = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      token: AUTH_TOKEN(),
      instanceIds: [],
      filter: {
        filterBy: "",
        value: "",
      },
      search: search,
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res, totalCount) => {
        setIsLoading(false);
        setEpmUser(
          res?.data?.epmUsers.map((data, index) => ({
            ...data,
            id: index + 1,
          }))
        );
        setTotalRecords(res.data.totalCount);
        fetchInstance();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Credentials are invalid") {
          logOutUser();
        } else {
        }
      });
  };

  const handleStatus = async (record) => {
    let data = {
      domainId: DOMAIN_ID(),
      status: record.state === "Active" ? "Inactive" : "Active",
      instanceIds: [Number(record.id)],
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    console.log(record);
    let url = `${REACT_APP_PAM_API}/instances/updateInstanceStatus`;
    try {
      await axios.put(url, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      await fetchInstance();
      notification.open({
        type: "success",
        content: `${record.hostName} is ${
          record.state === "Active" ? "deactivated" : "activated"
        } successfull`,
      });
    } catch (err) {
      if (err.response.data.message === "Credentials are invalid") {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else if (err.response.status === 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      }
    }
  };

  const option = [
    {
      value: "endpoint",
      label: "Endpoints",
      children: endpoints,
    },
    {
      value: "endpointUser",
      label: "Endpoint Users",
      children: epmUser,
    },
  ];
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleTab = (key, isTabChange) => {
    if (isTabChange == true) {
      setSelectedTab("1");
    } else {
      setSelectedTab(key);
    }
  };

  const EndpointStatus = (lastUpdated) => {
    const lastActive = moment.unix(lastUpdated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return true;
    } else if (diff > 10) {
      return false;
    }
    return false;
  };

  const Authcolum = [
    {
      headerName: "First Factor",
      field: "firstfactor",
      key: "firstfactor",
      flex: 1,
    },
    {
      headerName: "Second Factor",
      field: "secondfactor",
      key: "secondfactor",
      flex: 1,
    },
    {
      headerName: "Third Factor",
      field: "thirdFactor",
      key: "thirdfactor",
      flex: 1,
    },
    {
      headerName: "PAM Code",
      field: "pamcode",
      key: "pamcode",
      flex: 1,
    },
  ];

  const tab1columns = [
    {
      headerName: "User Name",
      field: "userName",
      key: "userName",
      flex: 1,
    },
    {
      field: "instances",
      headerName: "Endpoints",
      key: "instances",
      flex: 1,
      renderCell: (tags) => (
        <span>
          {tags.row.instances.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      headerName: "User Type",
      field: "userType",
      key: "userType",
      flex: 1,
    },
    {
      headerName: "Credentials",
      field: "countOfCredentials",
      key: "countOfCredentials",
      flex: 1,
    },
    {
      headerName: "Action",
      key: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedRecords(val.row);
                      setIsassignwallet(true);
                    }}
                  >
                    Assign to Wallets
                  </Link>
                ),
              },
              {
                key: "view-session-recording",
                label: (
                  <Link to={`/sessionRecordings?epmUser=${val.row.userName}`}>
                    View Session Recordings
                  </Link>
                ),
              },
              {
                key: "checkout-user",
                label: (
                  <Link
                    to={`/checkoutUser/${val.row.userId}`}
                    state={{
                      instanceName: val.row.instances[0].hostname,
                      instanceId: JSON.stringify(val.row.instances[0].instanceId),
                      epmUsername: val.row.userName,
                      os: val.row.instances[0].os,
                    }}
                  >
                    Checkout User
                  </Link>
                ),
                disabled: EndpointStatus(val.row.instances.map((ins) => ins.lastActive)),
              },

              {
                key: "reset-credential",
                label: (
                  <Link
                    to={`/reset-credentials?epmuser=${val.row.userName}&epmuserId=${val.row.userId}`}
                  >
                    Force Reset Credentials
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];
  const tab2columns = [
    {
      headerName: "Host Name",
      field: "hostName",
      key: "hostName",
      flex: 1,
    },
    {
      headerName: "OS",
      field: "os",
      key: "os",
      flex: 1,
    },
    {
      headerName: "Private IP",
      field: "privateIp",
      key: "privateIp",
      flex: 1,
    },
    {
      headerName: "PublicIp",
      field: "publicIp",
      key: "publicIp",
      flex: 1,
    },
    {
      headerName: "State",
      field: "state",
      key: "state",
      flex: 1,
    },
    //   {
    //     headerName: 'PAM Code',
    //     dataIndex: 'pamcode',
    //     key: 'pamcode',
    //     renderCell: (data, record) => (
    //        "View"
    //       ),

    //   },
    {
      headerName: "Action",
      key: "Action",
      flex: 1,
      renderCell: (value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: "Auth Flow",
              },
              {
                key: "status",
                label: (
                  <Link onClick={() => handleStatus(value.row)}>
                    {value.row.state === "Active" ? "Deactivate" : "Activate"}
                  </Link>
                ),
                danger: value.row.state === "Active" ? true : false,
              },
              {
                key: "Password Policies",
                label: (
                  <Link to={`/endpoint/passwordPolicies?hostaddress=${value.row.hostName}`}>
                    Password Policies
                  </Link>
                ),
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];
  const fetchInstanceData = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage,
      pageSize: PAGESIZE,
      search: search,
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEndpointData(res?.data?.instances);
        setTotalCount(res?.data?.total);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Credentials are invalid") {
          logOutUser();
        } else {
          console.log("Error");
        }
      });
  };

  const handleOk = () => {
    setTimeout(() => {
      setisOpenPAM(false);
    }, 3000);
  };
  const handleCancel = () => {
    setisOpenPAM(false);
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  href: "",
                  title: <HomeOutlined />,
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Endpoints Group</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Endpoint Group Details",
                },
              ]}
            />
          </Row>
          <Row justify="space-between">
            <Col>
              <h2 className="title">{`Endpoint Group Details - ${groupName}`} </h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (paginationParams.current !== 1) {
                              setPaginationParams({ current: 1, pageSize: 10 });
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tab-container">
                <Tabs activeKey={selectedTab} onChange={() => handleTab()}>
                  <TabPane style={{ width: "65rem" }} tab="Endpoints" key="0">
                    {/* <Table
                      rowKey="id"
                      dataSource={instaceDataById}
                      columns={tab2columns}
                      pagination={{
                        showTotal: (total) => `Total ${total} items`,
                        ...paginationParams,
                        total: totalUsers,
                      }}
                      onChange={handleTableChange}
                      loading={isLoading}
                    /> */}
                    <DataGrid
                          components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            noResultsOverlay: CustomNoRowsOverlay,
                          }}
                      rows={instaceDataById}
                      columns={tab2columns}
                      paginationMode="server"
                      rowCount={totalCount}
                      page={paginationParams.current}
                      pageSize={paginationParams.pageSize}
                      onPaginationModelChange={(newPage) => {
                        setPaginationParams({
                          ...paginationParams,
                          current: newPage.page,
                        });
                      }}
                      sortModel={sortModel}
                      onSortModelChange={handleSortModelChange}
                      style={{ border: "none" }}
                      loading={isLoading}
                    />
                  </TabPane>
                  <TabPane style={{ width: "65rem" }} tab="Endpoints Users" key="1">
                    {/* <Table
                      dataSource={epmUser}
                      columns={tab1columns}
                      loading={isLoading}
                      pagination={{
                        showTotal: (total) => `Total ${total} items`,
                        ...paginationParams,
                        total: totalRecords,
                      }}
                      onChange={handleTableChange}
                    /> */}
                    <DataGrid
                           components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            noResultsOverlay: CustomNoRowsOverlay,
                          }}
                      rows={epmUser}
                      columns={tab1columns}
                      paginationMode="server"
                      rowCount={totalCount}
                      page={paginationParams.current}
                      pageSize={paginationParams.pageSize}
                      onPaginationModelChange={(newPage) => {
                        setPaginationParams({
                          ...paginationParams,
                          current: newPage.page,
                        });
                      }}
                      sortModel={sortModel}
                      onSortModelChange={handleSortModelChange}
                      style={{ border: "none" }}
                      loading={isLoading}
                    />
                  </TabPane>
                  <TabPane style={{ width: "65rem" }} tab="Auth Flow" key="2">
                    {/* <Table columns={Authcolum} /> */}
                    <DataGrid
                           components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            noResultsOverlay: CustomNoRowsOverlay,
                          }}
                      rows={[]}
                      columns={Authcolum}
                      paginationMode="server"
                      rowCount={totalCount}
                      page={paginationParams.current}
                      pageSize={paginationParams.pageSize}
                      onPaginationModelChange={(newPage) => {
                        setPaginationParams({
                          ...paginationParams,
                          current: newPage.page,
                        });
                      }}
                      sortModel={sortModel}
                      onSortModelChange={handleSortModelChange}
                      style={{ border: "none" }}
                      loading={isLoading}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title={`PAM Code`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        width={900}
      >
        <Row>
          <Col span={24}></Col>
        </Row>
      </Modal>

      {Isassignwallet && (
        <AssignEndpointUser
          Isassignwallet={Isassignwallet}
          setIsassignwallet={setIsassignwallet}
          selectedRecords={selectedRecords}
        />
      )}
    </>
  );
};

export default EndpointGroupDetails;
