import {
  Col,
  Row,
  Select,
  Table,
  Dropdown,
  Button,
  Input,
  message,
  Space,
  Alert,
  Tag,
  Spin,
  Tooltip,
  Cascader,
  Empty,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../constants";

import axios from "axios";
import { logOutUser } from "../../../common";
import { MoreOutlined, LoadingOutlined } from "@ant-design/icons";
import AssignEndpointUser from "./AssignEndpointUser";
import { useDebounce } from "../../../common/debounce";
import moment from "moment-timezone";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const Endpoints = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [epmUsers, setEpmUser] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [endpointData, setEndpointData] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState();
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [Isassignwallet, setIsassignwallet] = useState(false);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");

  const debouncedValue = useDebounce(search, 1000);

  useEffect(() => {
    fetchInstance();
  }, []);

  useEffect(() => {
    fetchEPMUsers();
  }, [paginationParams, filteredValue]);

  useEffect(() => {
    fetchEPMUsers();
  }, [debouncedValue]);

  const EndpointStatus = (lastUpdated) => {
    const lastActive = moment.unix(lastUpdated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return true;
    } else if (diff > 10) {
      return false;
    }
    return false;
  };

  const columns = [
    { headerName: "User Name", field: "userName", key: "userName", flex: 1 },
    {
      field: "instances",
      headerName: "Endpoints",
      key: "instances",
      flex: 1,
      renderCell: (val) => (
        <span>
          {console.log(val)}
          {val.row.instances.map((tag) => {
            const lastActive = moment
              .unix(tag.lastActive)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD HH:mm:ss");
            const diff = moment().diff(lastActive, "minutes");
            return (
              // <Link
              //   to={`/endpointuserDetails?epmUsername=${record.userName}&epmUserid=${record.userId}`}
              // >
              <Tag color={diff >= 20 ? "red" : diff >= 10 ? "orange" : "green"} key={tag}>
                {tag.hostname}
              </Tag>
              // </Link>
            );
          })}
        </span>
      ),
    },

    {
      headerName: "Credentials",
      field: "countOfCredentials",
      key: "countOfCredentials",
      flex: 1,
      renderCell: (val) => (
        <Link
          disabled={val.row.countOfCredentials == 0 ? true : false}
          to={`/endpointuserDetails?epmUsername=${val.row.userName}&epmUserid=${val.row.userId}`}
        >
          {val.row.countOfCredentials}
        </Link>
      ),
    },

    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedRecords(val.row);
                      setIsassignwallet(true);
                    }}
                  >
                    Assign to Wallets
                  </Link>
                ),
              },
              {
                key: "view-session-recording",
                label: (
                  <Link to={`/sessionRecordings?epmUser=${val.row.userName}`}>
                    View Session Recordings
                  </Link>
                ),
              },
              {
                key: "checkout-user",
                label: (
                  <Link
                    to={`/checkoutUser/${val.row.userId}`}
                    state={{
                      instanceName: val.row.instances[0].hostname,
                      instanceId: JSON.stringify(val.row.instances[0].instanceId),
                      epmUsername: val.row.userName,
                      os: val.row.instances[0].os,
                    }}
                  >
                    Checkout User
                  </Link>
                ),
                disabled: EndpointStatus(val.row.instances.map((ins) => ins.lastActive)),
              },

              {
                key: "reset-credential",
                label: (
                  <Link
                    to={`/reset-credentials?epmuser=${val.row.userName}&epmuserId=${val.row.userId}`}
                  >
                    Force Reset Credentials
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];
  const fetchEPMUsers = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      token: AUTH_TOKEN(),
      instanceIds: filteredValue ? [filteredValue] : [],
      filter: {
        filterBy: filterBy,
        Value: "",
      },
      search,
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        // Assuming res?.data?.epmUsers is an array of objects
        const originalEpmUsers = res?.data?.epmUsers;

        // Adding an 'id' property to each object
        const epmUsersWithId = originalEpmUsers.map((user, index) => ({
          ...user,
          id: index + 1, // You can use a different logic to generate IDs if needed
        }));

        // Now, you can store the updated array with 'id' in the state
        setEpmUser(epmUsersWithId);
        console.log(epmUsers, "Inam");
        setTotalCount(res.data.totalCount);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoint users",
          });
        }
      });
  };

  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search,
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res, instances) => {
        setEndpointData(
          res.data.instances.map((instance) => ({
            value: instance.instanceId,
            label: instance.hostName,
            publicIp: instance.publicIp,
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.value : "");
    setPaginationParams({ current: 1, pageSize: 10 });
  };
  const handleSortModelChange = (model) => {
    console.log(model);
    setSortModel(model);
  };
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Endpoint Users</h2>
            </Col>
            <Col>
              <Link to={"add"}>
                <Button type="primary">Add Users</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      <Cascader
                        options={[
                          {
                            value: "endpoints",
                            label: "Endpoints",
                            children: endpointData,
                          },
                        ]}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (paginationParams.current !== 1) {
                              setPaginationParams({ current: 1, pageSize: 10 });
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* {isLoading ? (
                <Spin
                  style={{ position: "absolute", left: "50%", top: "200px" }}
                  indicator={<LoadingOutlined style={{ fontSize: 34 }} spin />}
                  size="large"
                />
              ) : ( */}
              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={epmUsers}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                loading={isLoading}
                page={paginationParams.current}
                pageSize={paginationParams.pageSize}
                onPaginationModelChange={(newPage) => {
                  setPaginationParams({
                    ...paginationParams,
                    current: newPage.page,
                  });
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
              />
              {/* )} */}
            </Col>
          </Row>
        </Col>
      </Row>

      <Outlet />
      {Isassignwallet && (
        <AssignEndpointUser
          Isassignwallet={Isassignwallet}
          setIsassignwallet={setIsassignwallet}
          selectedRecords={selectedRecords}
        />
      )}
    </>
  );
};

export default React.memo(Endpoints);
