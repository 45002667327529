import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Input,
  Tag,
  Button,
  Select,
  Switch,
  DatePicker,
  Space,
  Modal,
  Tabs,
  Empty,
  Badge,
  message,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  DEFAULT_GRID_AUTOSIZE_OPTIONS,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

import { CircleOutlined, DateRange } from "@mui/icons-material";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_PAM_API,
} from "../../constants";
import { logOutUser } from "../../common";
import ReactJoyride from "react-joyride";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const PolicyList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [endpoints, setEndpoints] = useState([]);
  const [totalPages, setTotalPage] = useState();
  const [tenantData, setTenantData] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [status, setStatus] = useState("option3");
  const [type1, setType1] = useState("option1");
  const [type2, setType2] = useState("option1");
  const [type3, setType3] = useState("option1");

  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 10,
    page: 0,
  });
  const [notification, setNotification] = message.useMessage();
  const [user, setUser] = useState([]);
  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [statusLoading, setStatusLoading] = useState(false);
  const [credentialData, setCredentialData] = useState([]);
  const [userType, setUserType] = useState();
  useEffect(() => {
    //TODO : Onboarding api call done here
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        target: "#onboarding_step1",
        content: <span>View audited policies </span>,
        disableBeacon: true,
      },
      {
        target: "#onboarding_step2",
        content: <span> Approved or Rejected the policies </span>,
        disableBeacon: true,
      },
    ];

    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };
    return (
      <ReactJoyride
        steps={tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };

  const [sort, setSort] = useState({ field: "", sort: "" });
  const [epm, setEpm] = useState([]);
  const [dom, setDom] = useState([]);
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [epmUsers, setEpmUsers] = useState([]);
  const [policyType, setPolicyType] = useState("local");
  const callback = (key) => {
    console.log(key);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const fetchTenantDetails = () => {
    let tenantPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios.post(`${REACT_APP_API_TENANTS_URL}/getTenantDetail`, tenantPayload).then((res) => {
      setTenantData(res.data.data);
      message.info({
        content: `Tenant has policies in ${
          res.data.data.authenticationpolicy === 1
            ? "audit"
            : res.data.data.authenticationpolicy === 2
            ? "policy"
            : "live"
        } mode`,
        key: "permanentMessage",
        duration: 0, // Set duration to 0 for a permanent message
        style: {},
      });
    });
  };
  useEffect(() => {
    // Display a permanent message on mount

    fetchTenantDetails();
    fetchEndpoints();
    fetchPolicy();
    fetchDomains();
    fetchAdUser();
    fetchEndpointGroup();
    fetchGrp();
    fetchEndpointUser();
    // Clean up the message when the component unmounts
    return () => {
      message.destroy("permanentMessage");
    };
  }, [policyType, appliedFilters, sort, userType]);

  const fetchEndpointUser = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-users",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpmUsers(data.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          //logOutUser();
        }
      });
  };
  const fetchGrp = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: "",
      },
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            name: grp.groupName,

            ...grp,
          }));
        }
        setCredentialData(Data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          //logOutUser();
        }
      });
  };
  const fetchAdUser = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setUser(res?.data?.users);
        console.log(res.data.users);
      })
      .catch((err) => {
        if (err.response.data.message === "Invalid credentials") {
          //logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch users",
          });
        }
      });
  };
  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");
    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      const originalGroups = res?.data?.groups;

      // Adding an 'id' property to each object
      const groupsWithId = originalGroups.map((group, index) => ({
        ...group,
        id: index + 1, // You can use a different logic to generate IDs if needed
      }));
      setEndpointGroups(groupsWithId);
    } catch (err) {
      if (
        err?.response?.data?.message === "Credentials are invalid" ||
        err?.response?.status === 401
      ) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        //logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Error fetching Endpoint Groups",
        });
      }
    }
  };
  const changePolicyStatus = (e, val) => {
    let id = val.row.id;
    console.log(e);
    // const status = val.row.policyStatus.toLowerCase() === "approved" ?  "Approved" :"Denied" ;

    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      requestId: id.toString(),
      status: e,
    };
    setStatusLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/UpdateUserAccessRequest`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setStatusLoading(false);
        notification.open({
          type: "success",
          content: `${val.row.policyName} policies ${e} successfully`,
        });
        fetchPolicy(currentPageModal.page + 1);
      })
      .catch((err) => {
        setStatusLoading(false);
      });
  };

  const fetchEndpoints = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoints",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpm(data.data.endpoints);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          //logOutUser();
        }
      });
  };
  const fetchDomains = (page) => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload)
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        const outputArray = inputArray.map((item) => {
          const dcParts = item.domain.split(",").map((part) => part.split("=")[1]);
          const domain = dcParts.join(".");
          return { id: item.id, value: domain };
        });

        setDom(outputArray);
      })

      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          //logOutUser();
        }
      });
  };
  const fetchPolicy = (page, filters) => {
    let tempAppliedFilter = appliedFilters;
    const uniqueFilters = tempAppliedFilter.reduceRight((acc, current) => {
      const existingFilter = acc.find(
        (filter) => filter.filterParameter === current.filterParameter
      );

      if (!existingFilter) {
        acc.push(current);
      }

      return acc;
    }, []);
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      policyType: userType ? userType : "local",
      filters: userType ? [] : uniqueFilters,
      requestId: "",
      limit: 10,
      page: page,
      sort: {
        field: sort.field,
        order: sort.sort.toUpperCase(),
      },
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/FilterPolicy`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEndpoints(data.data);
        setTotalPage(data.total_pages * 10);
        setCurrentPageModal({
          pageSize: 10,
          page: data.page - 1,
        });
        setIsOnboarding(type ? true : false);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          //logOutUser();
        }
      });
  };

  const handleSortModelChange = (model) => {
    if (model && model.length > 0) {
      setSort(model[0]);
      console.log(sort);
    }
  };

  const columns = [
    {
      headerName: "", // Empty header for the expand/collapse column
      field: "expand",
      width: 10,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleRowExpand(params);
            showModal();
          }}
        >
          <EditOutlined />
        </Link>
      ),
    },
    {
      headerName: "Policy Name",
      field: "policyName",
      width: 150,
      sortable: true,
    },
    {
      headerName: "User",
      field: "adUser",
      width: 150,

      sortable: true,
      renderCell: (val) => {
        const tagsArray = val.row.adUser.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <>
                <Tag
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let filterParameter = "adUser";
                    let filterValue = tag;
                    let obj = [
                      {
                        filterParameter: filterParameter,
                        filterValue: filterValue,
                      },
                    ];
                    let tempAppliedFilter = appliedFilters;
                    let tempfilter = tempAppliedFilter.concat(obj);
                    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                      const existingFilter = acc.find(
                        (filter) => filter.filterParameter === current.filterParameter
                      );

                      if (!existingFilter) {
                        acc.push(current);
                      }

                      return acc;
                    }, []);
                    setAppliedFilters(uniqueFilters);
                  }}
                >
                  {tag.trim()}
                </Tag>
                {/* <br /> */}
              </>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Group",
      field: "group",
      width: 150,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val.row.os.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <>
                <Tag
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let filterParameter = "group";
                    let filterValue = tag;
                    let obj = [
                      {
                        filterParameter: filterParameter,
                        filterValue: filterValue,
                      },
                    ];
                    let tempAppliedFilter = appliedFilters;
                    let tempfilter = tempAppliedFilter.concat(obj);
                    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                      const existingFilter = acc.find(
                        (filter) => filter.filterParameter === current.filterParameter
                      );

                      if (!existingFilter) {
                        acc.push(current);
                      }

                      return acc;
                    }, []);
                    setAppliedFilters(uniqueFilters);
                  }}
                >
                  {tag.trim()}
                </Tag>

                <br />
              </>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Domain",
      field: "adDomain",
      width: 150,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val.row.adDomain.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <Tag
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let filterParameter = "adDomain";
                  let filterValue = tag;
                  let obj = [
                    {
                      filterParameter: filterParameter,
                      filterValue: filterValue,
                    },
                  ];
                  let tempAppliedFilter = appliedFilters;
                  let tempfilter = tempAppliedFilter.concat(obj);
                  const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                    const existingFilter = acc.find(
                      (filter) => filter.filterParameter === current.filterParameter
                    );

                    if (!existingFilter) {
                      acc.push(current);
                    }

                    return acc;
                  }, []);
                  setAppliedFilters(uniqueFilters);
                }}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Ou",
      field: "adOu",
      width: 120,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val.row.adOu.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <Tag
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let filterParameter = "adOu";
                  let filterValue = tag;
                  let obj = [
                    {
                      filterParameter: filterParameter,
                      filterValue: filterValue,
                    },
                  ];
                  let tempAppliedFilter = appliedFilters;
                  let tempfilter = tempAppliedFilter.concat(obj);
                  const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                    const existingFilter = acc.find(
                      (filter) => filter.filterParameter === current.filterParameter
                    );

                    if (!existingFilter) {
                      acc.push(current);
                    }

                    return acc;
                  }, []);
                  setAppliedFilters(uniqueFilters);
                }}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Endpoint Users",
      field: "endpointUser",
      sortable: true,
      width: 210,
      renderCell: (val) => {
        const tagsArray = val.row.endpointUser.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <Tag
                key={index}
                style={{
                  cursor: "pointer",
                  // whiteSpace: "break-spaces"
                }}
                onClick={() => {
                  let filterParameter = "endpointUser";
                  let filterValue = tag;
                  let obj = [
                    {
                      filterParameter: filterParameter,
                      filterValue: filterValue,
                    },
                  ];
                  let tempAppliedFilter = appliedFilters;
                  let tempfilter = tempAppliedFilter.concat(obj);
                  const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                    const existingFilter = acc.find(
                      (filter) => filter.filterParameter === current.filterParameter
                    );

                    if (!existingFilter) {
                      acc.push(current);
                    }

                    return acc;
                  }, []);
                  setAppliedFilters(uniqueFilters);
                }}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Destination",
      field: "destinationEndpoint",
      sortable: true,
      width: 340,
      renderCell: (val) => {
        const tagsArray = val.row.destinationEndpoint.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <Tag
                key={index}
                style={{
                  cursor: "pointer",
                  // whiteSpace: "break-spaces"
                }}
                onClick={() => {
                  let filterParameter = "destinationEndpoint";
                  let filterValue = tag;
                  let obj = [
                    {
                      filterParameter: filterParameter,
                      filterValue: filterValue,
                    },
                  ];
                  let tempAppliedFilter = appliedFilters;
                  let tempfilter = tempAppliedFilter.concat(obj);
                  const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                    const existingFilter = acc.find(
                      (filter) => filter.filterParameter === current.filterParameter
                    );

                    if (!existingFilter) {
                      acc.push(current);
                    }

                    return acc;
                  }, []);
                  setAppliedFilters(uniqueFilters);
                }}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Policy Type",
      field: "policyType",
      sortable: true,
      width: 140,
      renderCell: (val) => {
        const tagsArray = val.row.policyType.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.map((tag, index) => (
              <Tag
                key={index}
                style={{
                  cursor: "pointer",
                  // whiteSpace: "break-spaces"
                }}
                onClick={() => {
                  let filterParameter = "policyType";
                  let filterValue = tag;
                  let obj = [
                    {
                      filterParameter: filterParameter,
                      filterValue: filterValue,
                    },
                  ];
                  let tempAppliedFilter = appliedFilters;
                  let tempfilter = tempAppliedFilter.concat(obj);
                  const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                    const existingFilter = acc.find(
                      (filter) => filter.filterParameter === current.filterParameter
                    );

                    if (!existingFilter) {
                      acc.push(current);
                    }

                    return acc;
                  }, []);
                  setAppliedFilters(uniqueFilters);
                }}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Updated At",
      field: "timestamp",
      width: 170,
      sortable: true,
      renderCell: (val) => {
        const formatString = "MM-DD-YYYY HH:mm [PST]";

        const formattedDate = moment.unix(val.row.timestamp).format(formatString);

        console.log("Inam", formattedDate);
        return <span>{formattedDate}</span>;
      },
    },

    // {
    //   headerName: "Execution Count",
    //   field: "count",
    //   flex: 1.3,
    //   sortable: true,
    // },

    {
      headerName: "Status",
      field: "policyStatus",
      width: 180,
      renderCell: (value) => {
        return (
          <>
            <Select
              size="small"
              defaultValue={
                value.row.policyStatus.toLowerCase() === "approved"
                  ? "Approved"
                  : value.row.policyStatus.toLowerCase() === "denied"
                  ? "Denied"
                  : "In Review"
              }
              onChange={(e) => changePolicyStatus(e, value)}
              disabled={statusLoading}
            >
              <Option value="In Review">
                {" "}
                <Badge status="warning" text="In Review" />
              </Option>
              <Option value="Approved">
                <Badge status="success" text="Approved" />
              </Option>
              <Option value="Denied">
                <Badge status="error" text="Denied" />
              </Option>
            </Select>
          </>
        );
      },
    },
  ];

  //   const handleApplyFilter = (filter, value) => {
  //     if (value) {
  //       const newFilter = { filter, value };
  //       setAppliedFilters([...appliedFilters, newFilter]);

  //       // Update searchText immediately
  //       setSearchText(value);

  //       // Update filteredEndpoints based on searchText and appliedFilters
  //       setFilteredEndpoints((prevFilteredEndpoints) => {
  //         return prevFilteredEndpoints.filter((endpoint) => {
  //           const searchTextMatch = endpoint.endpoint
  //             .toLowerCase()
  //             .includes(value.toLowerCase()); // Use the current value

  //           const filtersMatch = appliedFilters.every(({ filter, value }) =>
  //             endpoint[filter].toLowerCase().includes(value.toLowerCase())
  //           );

  //           return searchTextMatch && filtersMatch;
  //         });
  //       });
  //     }
  //   }

  const getCurrentOption = (type) => {
    console.log({ type, appliedFilters });
    const currentOption = appliedFilters.filter((opt) => opt.filterParameter === type);
    return currentOption[0]?.filterValue ?? null;
  };

  const handleRemoveFilter = (filter, value) => {
    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) => filterItem.filterParameter !== filter || filterItem.filterValue !== value
      )
    );
  };

  const handleRowExpand = (params) => {
    setSelectedRow(params.row);
    setExpandedRows((prevRows) =>
      prevRows.includes(params.id.toString())
        ? prevRows.filter((row) => row !== params.id.toString())
        : [...prevRows, params.id.toString()]
    );
  };

  const isRowExpandable = (params) => {
    // Example condition for expandable rows
    return true;
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {isOnBoarding && startTour()}
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Policies</h2>
            </Col>
            <Col>
              <Link to={"/windows/createEndpointAccessPolicy"}>
                <Button type="primary">Add Endpoint Access Policy</Button>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Policy Type</p>
                      <Select
                        style={{ width: 200 }}
                        placeholder="Search Policy Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        // onChange={(e) => {
                        //   setPolicyType(e);
                        // }}
                        value={getCurrentOption("policyType")}
                        onChange={(value) => {
                          setUserType(value);
                          let filterParameter = "policyType";
                          let filterValue = value;
                          let obj = [
                            {
                              filterParameter: filterParameter,
                              filterValue: filterValue,
                            },
                          ];
                          let tempAppliedFilter = appliedFilters;
                          let tempfilter = tempAppliedFilter.concat(obj);
                          const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                            const existingFilter = acc.find(
                              (filter) => filter.filterParameter === current.filterParameter
                            );

                            if (!existingFilter) {
                              acc.push(current);
                            }

                            return acc;
                          }, []);
                          setAppliedFilters(uniqueFilters);
                        }}
                      >
                        <Option value="local">Local User</Option>
                        <Option value="ad">Active Directory</Option>
                      </Select>
                    </Col>

                    <Col span={4}>
                      <p className="search-label">Ou</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search OU "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          let filterParameter = "adOu";
                          let filterValue = value;
                          let obj = [
                            {
                              filterParameter: filterParameter,
                              filterValue: filterValue,
                            },
                          ];
                          let tempAppliedFilter = appliedFilters;
                          let tempfilter = tempAppliedFilter.concat(obj);
                          const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                            const existingFilter = acc.find(
                              (filter) => filter.filterParameter === current.filterParameter
                            );

                            if (!existingFilter) {
                              acc.push(current);
                            }

                            return acc;
                          }, []);
                          setAppliedFilters(uniqueFilters);
                        }}
                        // onChange={(e) => handleApplyFilter("groups", e)}
                        value={getCurrentOption("adOu")}
                      >
                        <Option value="Endpoint 1">ou=rider-park</Option>
                        <Option value="Endpoint 2">ou=rider-park 2</Option>
                        <Option value="Endpoint 3">ou=rider-park 3</Option>
                        {/* Add other options here */}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Domain</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search Domain"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={getCurrentOption("adDomain")}
                        onChange={(value) => {
                          let filterParameter = "adDomain";
                          let filterValue = value;
                          let obj = [
                            {
                              filterParameter: filterParameter,
                              filterValue: filterValue,
                            },
                          ];
                          let tempAppliedFilter = appliedFilters;
                          let tempfilter = tempAppliedFilter.concat(obj);
                          const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                            const existingFilter = acc.find(
                              (filter) => filter.filterParameter === current.filterParameter
                            );

                            if (!existingFilter) {
                              acc.push(current);
                            }

                            return acc;
                          }, []);
                          setAppliedFilters(uniqueFilters);
                        }}
                        // onChange={(e) => handleApplyFilter("groups", e)}
                      >
                        {dom &&
                          dom.length > 0 &&
                          dom.map((endpoint) => (
                            <Option value={endpoint.value}>{endpoint.value}</Option>
                          ))}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Endpoint</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search Endpoint "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={getCurrentOption("destinationEndpoint")}
                        onChange={(value) => {
                          let filterParameter = "destinationEndpoint";
                          let filterValue = value;
                          let obj = [
                            {
                              filterParameter: filterParameter,
                              filterValue: filterValue,
                            },
                          ];
                          let tempAppliedFilter = appliedFilters;
                          let tempfilter = tempAppliedFilter.concat(obj);
                          const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                            const existingFilter = acc.find(
                              (filter) => filter.filterParameter === current.filterParameter
                            );

                            if (!existingFilter) {
                              acc.push(current);
                            }

                            return acc;
                          }, []);
                          setAppliedFilters(uniqueFilters);
                        }}
                      >
                        {epm &&
                          epm.length > 0 &&
                          epm.map((endpoint) => (
                            <Option value={endpoint.name}>{endpoint.name}</Option>
                          ))}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Status</p>
                      <Select
                        value={getCurrentOption("policyStatus")}
                        onChange={(value) => {
                          let filterParameter = "policyStatus";
                          let filterValue = value;
                          let obj = [
                            {
                              filterParameter: filterParameter,
                              filterValue: filterValue,
                            },
                          ];
                          let tempAppliedFilter = appliedFilters;
                          let tempfilter = tempAppliedFilter.concat(obj);
                          const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                            const existingFilter = acc.find(
                              (filter) => filter.filterParameter === current.filterParameter
                            );

                            if (!existingFilter) {
                              acc.push(current);
                            }

                            return acc;
                          }, []);
                          setAppliedFilters(uniqueFilters);
                        }}
                        placeholder="Select Status"
                      >
                        <Option value="In Review">In Review</Option>
                        <Option value="Approved">Approved</Option>
                        <Option value="Denied">Denied</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: "2rem" }}>
                <Col>
                  {appliedFilters.length > 0 && (
                    <div className="filter-tags">
                      <h4
                        style={{
                          marginTop: "0rem",
                          marginBottom: "0.5rem",
                          fontWeight: "400",
                        }}
                      >
                        Applied Filters
                      </h4>

                      {appliedFilters.map(({ filterParameter, filterValue }) => (
                        <>
                          <Tag
                            key={`${filterParameter}:${filterValue}`}
                            closable
                            onClose={() => handleRemoveFilter(filterParameter, filterValue)}
                          >
                            {`${filterParameter}: ${filterValue}`}
                          </Tag>
                        </>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24} id="onboarding_step1">
                  <DataGrid
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      noResultsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={endpoints}
                    columns={columns}
                    pageSizeOptions={[10]}
                    rowCount={totalPages}
                    paginationMode="server"
                    // Add autoHeight property
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                    }}
                    paginationModel={currentPageModal}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    isRowExpandable={isRowExpandable}
                    getRowId={(row) => row.id.toString()}
                    expandedRows={expandedRows}
                    onRowExpand={handleRowExpand}
                    onPaginationModelChange={(params) => {
                      fetchPolicy(params.page + 1, appliedFilters);
                    }}
                    filterMode="server"
                    onFilterModelChange={(e) => {
                      let filterParameter = e.items[0].field;
                      let filterValue = e.items[0].value;
                      let obj = [
                        {
                          filterParameter: filterParameter,
                          filterValue: filterValue,
                        },
                      ];
                      let tempAppliedFilter = appliedFilters;
                      let tempfilter = tempAppliedFilter.concat(obj);
                      const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                        const existingFilter = acc.find(
                          (filter) => filter.filterParameter === current.filterParameter
                        );

                        if (!existingFilter) {
                          acc.push(current);
                        }

                        return acc;
                      }, []);
                      setAppliedFilters(uniqueFilters);
                    }}
                    style={{ border: "none", width: "100%", overflow: "auto" }} // Set a fixed height for the grid
                    rowThreshold={0}
                    pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                    sx={{
                      "& .MuiDataGrid-detailPanel": {
                        overflow: "visible",
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {isModalVisible && (
        <Modal
          title={
            <span style={{ fontSize: 18 }}>
              Edit policy for{" "}
              <span style={{ color: "#1677FF" }}>{` ${selectedRow?.policyName}`}</span>
            </span>
          }
          footer={[
            <Tooltip title="Feature Under Development">
              <Button
                key="submit"
                type="primary"
                disabled={true}
                onClick={() => setIsModalVisible(false)}
              >
                Save
              </Button>
            </Tooltip>,
          ]}
          width={800}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="authModal"
        >
          <Row>
            <Col span={24}>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={12}>
                  <p className="search-label">Policy Name</p>
                  <Input value={selectedRow?.policyName} />
                </Col>
                <Col span={12}>
                  <p className="search-label">Domain</p>
                  <Select
                    showSearch
                    placeholder="Select Domain"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    defaultValue={selectedRow?.adDomain}
                  >
                    {dom &&
                      dom.length > 0 &&
                      dom.map((endpoint) => (
                        <Option value={endpoint.value}>{endpoint.value}</Option>
                      ))}
                  </Select>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={12}>
                  <p className="search-label">Ou</p>
                  <Select
                    showSearch
                    placeholder="OU"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    defaultValue={selectedRow?.adOu}
                  >
                    <Option value="ou=rider-park">ou=rider-park</Option>
                    <Option value="ou=rider-park 2">ou=rider-park 2</Option>
                    <Option value="ou=rider-park 3">ou=rider-park 3</Option>
                  </Select>
                </Col>
              </Row>
              <h3 style={{ color: "blue", marginTop: "2rem" }}>What can they Access</h3>

              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={12}>
                  <p className="search-label">Directory Users and Groups</p>
                  <Select
                    placeholder="Select User And Groups"
                    onChange={(e) => setType1(e)}
                    defaultValue={"option1"}
                  >
                    <Option value="option1">Directory Users </Option>
                    <Option value="option2">Directory Groups</Option>
                    <Option value="option3">Groups Matching Wild Card</Option>
                    <Option value="option4">Users Matching Wild Card</Option>
                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type1 === "option1" ? (
                  <Col span={12}>
                    <p className="search-label">Directory User List</p>
                    <Select mode="multiple" value={selectedRow?.adUser}>
                      {user &&
                        user.length > 0 &&
                        user.map((user) => <Option value={user.Email}>{user.Email}</Option>)}
                    </Select>
                  </Col>
                ) : (
                  type1 === "option2" && (
                    <Col span={12}>
                      <p className="search-label"> Directory Groups List</p>
                      <Select mode="multiple">
                        {credentialData &&
                          credentialData.length > 0 &&
                          credentialData.map((user) => (
                            <Option value={user.name}>{user.name}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type1 == "option3" || type == "option4") && (
                  <Col span={12}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={12}>
                  <p className="search-label">Endpoints and Endpoint Groups</p>
                  <Select
                    placeholder="Select Endpoint And Endpoint Groups"
                    onChange={(e) => setType2(e)}
                    defaultValue={"option1"}
                  >
                    <Option value="option1">Endpoints</Option>
                    <Option value="option2">Endpoint Groups</Option>
                    <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                    <Option value="option4">Endpoint Matching Wild Card</Option>
                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type2 === "option1" ? (
                  <Col span={12}>
                    <p className="search-label">Endpoints List</p>
                    <Select
                      mode="multiple"
                      onChange={(e) => setEndpoints(e)}
                      defaultValue={selectedRow?.destinationEndpoint}
                    >
                      {epm &&
                        epm.length > 0 &&
                        epm.map((endpoint) => (
                          <Option value={endpoint.name}>{endpoint.name}</Option>
                        ))}
                    </Select>
                  </Col>
                ) : (
                  type2 === "option2" && (
                    <Col span={12}>
                      <p className="search-label">Endpoints Groups List</p>
                      <Select mode="multiple" onChange={(e) => setEndpointGroups(e)}>
                        {endpointGroups &&
                          endpointGroups.length > 0 &&
                          endpointGroups.map((group) => (
                            <Option value={group.groupName}>{group.groupName}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type2 == "option3" || type2 == "option4") && (
                  <Col span={12}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
              <Row gutter={16} style={{ margin: "1rem 0" }}>
                <Col span={12}>
                  <p className="search-label">Endpoints Users</p>
                  <Select
                    placeholder="Select Endpoint Users"
                    onChange={(e) => setType3(e)}
                    defaultValue={"option1"}
                  >
                    <Option value="option1">Endpoint Users</Option>
                    <Option value="option3">Endpoint Users Matching Wild Card</Option>
                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type3 === "option1" ? (
                  <Col span={12}>
                    <p className="search-label">Endpoint User List</p>
                    <Select
                      mode="multiple"
                      onChange={(e) => console.log(e)}
                      defaultValue={selectedRow?.endpointUser.split(",")}
                    >
                      {epmUsers &&
                        epmUsers.length > 0 &&
                        epmUsers.map((user) => <Option value={user.user}>{user.user}</Option>)}
                    </Select>
                  </Col>
                ) : (
                  type3 === "option2" && (
                    <Col span={12}>
                      <p className="search-label">Endpoints Groups List</p>
                      <Select mode="multiple">
                        <Option value="option1">Label 1</Option>
                        <Option value="option2">Label 2</Option>
                        <Option value="option3">Label 3</Option>
                      </Select>
                    </Col>
                  )
                )}
                {(type3 == "option3" || type3 == "option4") && (
                  <Col span={12}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default PolicyList;
