import { Button, Modal, Row, Col, Input, Table, message, Form, Select } from "antd";
import React, { useState, useEffect } from "react";
import {
  AUTH_TOKEN,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  DOMAIN_ID,
} from "../../../constants";
import { fetchEPMUsers, HEADERS } from "../../../common/functions";
import axios from "axios";
import { logOutUser } from "../../../common";

const EditUsers = ({ isEditUser, setIsEditUser, notification, fetchUsers, selectedUserData }) => {
  const [issuerData, setIssuerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    console.log(selectedUserData);
    fetchIssue();
    setFormData([
      { name: ["firstName"], value: selectedUserData?.firstName },
      { name: ["LastName"], value: selectedUserData?.lastName },
      {
        name: ["phone"],
        value: selectedUserData?.phone,
      },
      {
        name: ["email"],
        value: selectedUserData?.email,
      },
    ]);
  }, []);
  const handleOk = (e) => {
    console.log(e);
    let payload = {
      ...e,
      domainId: DOMAIN_ID(),
      metadata: selectedUserData.metaData,
      otpMethod: selectedUserData.otpMethod,
      status: selectedUserData.status,
      applications: [],
      userId: selectedUserData.id,
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/updateUser`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        notification?.open({
          type: "success",
          content: "Edit user Successful",
        });
        setIsEditUser(false);
        fetchUsers();
      })
      .catch((err) => {
        setIsLoading(false);
        let errMgs = err.response.data.message;
        notification.open({
          type: "error",
          content:
            errMgs === "User already exists"
              ? "User already exists"
              : errMgs === "Invalid credentials"
              ? "Credentials are invalid"
              : "Unable to unboard User",
        });
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };

  const handleCancel = () => {
    setIsEditUser(false);
  };

  const fetchIssue = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIssuerData(
          res?.data?.map((data) => ({
            value: data.IssuerId,
            label: data.Name,
          }))
        );
      });
  };

  const handleRole = (_, data) => {
    form.setFieldsValue({
      userRoleId: data.role,
    });
  };
  const handleIssuer = (value) => {
    form.setFieldsValue({
      issuerId: value,
    });
  };

  return (
    <Modal
      open={isEditUser}
      title={`Edit User - ${selectedUserData.email}`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={900}
      centered
    >
      <div style={{ marginTop: "2rem" }}>
        <Row>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleOk}
              fields={formData}
              onFieldsChange={(_, allFields) => {
                setFormData(allFields);
              }}
            >
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: "First name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter first name" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="LastName"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: "Last name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Last name" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="email"
                        label="Email ID"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Email ID is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:abc@gmail.com" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="phone"
                        label="User Phone"
                        rules={[
                          {
                            required: true,
                            message: "Phone number is required",
                          },
                          {
                            pattern: /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
                            message: "Please enter a valid phone number",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Phone number" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="userRoleId"
                        label="Role"
                        rules={[
                          {
                            required: true,
                            message: "Select role",
                          },
                        ]}
                      >
                        <Select
                          onChange={handleRole}
                          placeholder="Select Hostname"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={[
                            {
                              value: "0",
                              label: "Enduser",
                              role: 3,
                            },
                            { value: "1", label: "Admin", role: 1 },
                          ]}
                          value={formData?.userRoleId}
                        />{" "}
                      </Form.Item>
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        name="cn"
                        label="Common Name"
                        rules={[
                          {
                            required: true,
                            message: "Common Name is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:cn" />
                      </Form.Item>
                    </Col>

                    
                  </Row>
                </Col>
              </Row> */}
              {/* <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="dn"
                        label="Distinguish Name"
                        rules={[
                          {
                            required: true,
                            message: "Distinguish Name is required",
                          },
                          {
                            pattern: /^(dc=[a-zA-Z0-9]+,dc=[a-zA-Z0-9]+)$/,
                            message: "Distinguish Name must be dc=example,dc=com format",
                          },
                        ]}
                      >
                        <Input placeholder="eg:dc=example,dc=com" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="issuerId"
                        label="Issuer DID"
                        rules={[
                          {
                            required: true,
                            message: "Select issuer DID",
                          },
                        ]}
                      >
                        <Select
                          onChange={handleIssuer}
                          placeholder="Select issuer DID"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={issuerData}
                        />{" "}
                      </Form.Item>
                    </Col>
                    
                  </Row>
                </Col>
              </Row> */}
              <Form.Item style={{ textAlign: "end" }}>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EditUsers;
