export const userRoles = {
  ADMIN: {
    canAccess: [
      "/",
      "/onboard/config",
      "/endpointsUsers",
      "/endpointGroups",
      "/endpoint",
      "/linuxMachines/jumpServer",
      "/linuxMachines/addJumpServer",
      "/connections",
      "/connections/createConnection",
      "/sessionRecordings",
      "/credentialRotation",
      "/credentialRotation/addPassword",
      "/windows/authnPolicy",
      "/windows/policies",
      "/windows/createEndpointAccessPolicy",
      "/workloadIdentity",
      "/workloadIdentity/addWorkloadIdentity",
      "/serviceCredentials",
      "/serviceAccount/addServiceAccount",
      "/serviceAccount",

      "/directory/users",
      "/directory/groups",
      "/directory/device",
      "/directory/activeDirectory",

      "/did",
      "/did/dids",
      "/did/walletUser",
      "/did/addHolderDID",
      "/did/verifiableCredentials",
      "/did/transactionLogs",
      "/did/walletUser/view-credentials/:id",

      "/windows/endpoints",
      "/windows/endpointPolicies",
      "/windows/createWindowPolicy",
      "/windows/requestLogs",
      "/windows/auditLogs",
      "/windows/applicationRequest",

      "/onboard",
      "/onboard/module",
      "/onboard/setup/serviceAccount",
      "/onboard/setup/mfa",
      "/onboard/setup/localAccount",
      "/onboard/setup/tenantConfig",
      "/splunk",
    ],
  },

  ENDUSER: {
    canAccess: [
      "/endUser/endpoints",
      "/endUser/connections",
      "/endUser/assignedVcs",
    ],
  },
  SUPERADMIN: {
    canAccess: [
      "/org/dashboard",
      "/org/dashboard/details",
      "/setting",
      "/setting/okta",
      "/tenant",
      "/tenant/add",
      "/onboard/module",
      "/onboard",
      "/onboard/config",
      "/onboard/setup/azureAdUser",
      "/onboard/setup/mfa",
      "/onboard/setup/localAccount",
    ],
  },
};

export const authorize = (role, page, id) => {
  if (id) {
    const access = userRoles[role].canAccess.map((path) => {
      path.replace("dynamicId", id);
    });
    if (access.includes(page)) {
      return true;
    }
    return false;
  } else {
    if (userRoles[role].canAccess.includes(page)) {
      return true;
    } else {
      return false;
    }
  }
};
