import { InboxOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Select, Upload, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AUTH_TOKEN,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import { logOutUser } from "../../../common";

const ImportUsers = ({ isImportUser, setIsImportUser, notification, fetchUsers }) => {
  const [issuerData, setIssuerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [userFile, setUserFile] = useState(null);
  const { Dragger } = Upload;

  useEffect(() => {
    fetchIssue();
  }, []);

  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(info.file);
        setUserFile(info.file);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleOk = (e) => {
    console.log(e);
    const csvRequest = {
      file: userFile.originFileObj,
      fileName: userFile.name,
      domainId: DOMAIN_ID(),
      delimiter: ",",
      hasHeader: true,
      issuerId: e.issuerId,
      orgId: ORG_ID(),
    };
    const ldifRequest = {
      file: userFile.originFileObj,
      fileName: userFile.name,
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);

    const URL_TYPE = userFile?.type === "text/csv" ? "importUsers" : "activeDirectory/importLdif";
    const requestType = userFile?.type === "text/csv" ? csvRequest : ldifRequest;

    axios
      .post(`${REACT_APP_API_PAM_URL}/integrations/${URL_TYPE}`, requestType, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        setIsImportUser(false);
        setIsLoading(false);
        notification?.open({
          type: "success",
          content: "User onboarded Successful!",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        let errMgs = err.response.data.message;
        notification.open({
          type: "error",
          content: "Unable to unboard User",
        });
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };
  const handleCancel = () => {
    setIsImportUser(false);
  };

  const fetchIssue = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIssuerData(
          res?.data?.map((data) => ({
            value: data.IssuerId,
            label: data.Name,
          }))
        );
      });
  };
  const handleIssuer = (value) => {
    form.setFieldsValue({
      issuerId: value,
    });
  };
  return (
    <>
      <Modal
        open={isImportUser}
        title="Import Users"
        onOk={form.submit}
        onCancel={handleCancel}
        footer={null}
        width={900}
      >
        <div style={{ marginTop: "2rem" }}>
          <Row>
            <Col span={24}>
              <Form form={form} layout="vertical" onFinish={handleOk}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="issuerId"
                      label="Issuer DID"
                      rules={[
                        {
                          required: true,
                          message: "Select issuer DID",
                        },
                      ]}
                    >
                      <Select
                        onChange={handleIssuer}
                        placeholder="Select issuer DID"
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={issuerData}
                      />{" "}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="import"
                      label=""
                      rules={[
                        {
                          required: true,
                          message: "Upload file",
                        },
                      ]}
                    >
                      <Dragger {...props} accept=".csv,.ldif" multiple={false}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                      </Dragger>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item style={{ textAlign: "end", marginTop: "1rem" }}>
                  <Button htmlType="submit" type="primary" loading={isLoading}>
                    Import
                  </Button>
                  ,
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ImportUsers;
