import { Card, Checkbox, Col, Progress, Row } from "antd";
import React, { useEffect, useState } from "react";
import "../../../styles/onboard.scss";
import { Link, useNavigate } from "react-router-dom";

function RiskBasedMFA() {
  const navigate = useNavigate();
  const [percent, setPercent] = useState(25);
  const [checkStatus, setCheckStatus] = useState({
    mfa: true,
    window: false,
    linux: false,
    addUser: false,
  });

  const [selectedCheck, setSelectedCheck] = useState(false);
  useEffect(() => {
    setSelectedCheck(true);
  }, []);

  const handleChangeStatus = (e, val) => {
    setCheckStatus((prevStatus) => ({
      ...prevStatus,
      [val]: e,
    }));
    if (e) {
      setSelectedCheck(e);

      setPercent((prevPercent) => {
        const newPercent = prevPercent + 25;
        if (newPercent > 100) {
          return 100;
        }
        return newPercent;
      });
    } else {
      setSelectedCheck(e);

      setPercent((prevPercent) => {
        const newPercent = prevPercent - 25;
        if (newPercent < 0) {
          return 0;
        }
        return newPercent;
      });
    }
  };
  localStorage.setItem("onboardMFA", percent);

  return (
    <>
      <div className="onboarding-container">
        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <h2 className="title"> Let’s setup MFA</h2>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: "2rem" }}>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[<Progress percent={percent} showInfo={false} />]}
                >
                  <h3 style={style.h3} className="card-header-h3">
                    MFA <br />
                    {percent}% complete{" "}
                  </h3>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Configure Tenant</h3>{" "}
                <Row style={{ margin: "1rem 0" }}>
                  <Col>
                    {" "}
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.mfa}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "mfa");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={`/onboard/setup/tenantConfig?mfa=true`}>
                      Configure MFA{" "}
                    </Link>{" "}
                  </Col>
                </Row>
                <Row style={style.row}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.addUser}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "addUser");
                      }}
                    ></Checkbox>{" "}
                    {/* <a style={style.a}>Add user and setup wallets</a> */}
                    <Link style={style.a} to={`/directory/users?mfa=true`}>
                      Add user and setup wallets{" "}
                    </Link>{" "}
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Endpoints</h3>
                <Row style={style.row}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.window}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "window");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={`/endpoint/addEndpoint?type=windows`}>
                      Configure windows endpoints
                    </Link>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.linux}
                      onChange={(e) => {
                        handleChangeStatus(e.target.checked, "linux");
                        console.log(e);
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={"/endpoint/addEndpoint?type=linux"}>
                      Configure linux endpoint
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
const style = {
  a: {
    fontSize: "18px",
  },
  row: {
    marginBottom: "1rem",
  },
};
export default RiskBasedMFA;
