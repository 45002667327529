import { CopyTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../../../constants";
import { logOutUser } from "../../../../common";
import axios from "axios";
import ReactJoyride from "react-joyride";
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Input,
  List,
  Row,
  Space,
  Tabs,
  Tooltip,
  message,
} from "antd";
import onBoardImg from "../../../../assets/onboard-endpoint.svg";

export const OnboardLinux = () => {
  const [notification, setNotification] = message.useMessage();
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {/* {isOnBoarding && startTour()} */}

        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">Add Linux</h2>
            </Col>
          </Row>
        </Col>
        <Row className="tab-container">
          <Col span={24}>
            <Tabs
              activeKey={currentTab}
              items={[
                {
                  key: 1,
                  label: "Agent Configuration",
                  children: (
                    <AgentConfiguration
                      notification={notification}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />
                  ),
                },
                {
                  key: 2,
                  label: "Google PAM Config",
                  children: (
                    <GooglePAMConfig
                      notification={notification}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />
                  ),
                },
              ]}
              onChange={(key) => {
                setCurrentTab(key);
              }}
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export const AgentConfiguration = ({ notification, setCurrentTab, currentTab }) => {
  const [code, setCode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchAgentKey();
  }, []);

  const fetchAgentKey = () => {
    let keyData = {
      domainId: DOMAIN_ID(),
      expired: false,
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/generateAgentkey`, keyData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        if (res.data) {
          setCode(res.data.agentKey);
        }
      })
      .catch((err) => {
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch agent key",
          });
        }
      });
  };
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
  };

  const items = [
    {
      key: "1",
      label: "Why is this needed?",
      children: (
        <div
          style={{
            paddingLeft: 24,
          }}
        >
          <p>
            Agents communicate to authnull and help local privileged users to be managed through
            AuthNull’s Privileged Access Manager. How does it work?
          </p>
          <img src={onBoardImg} alt="img" width={700} height={700} />
        </div>
      ),
    },
    {
      key: "2",
      label: "OS Download",
      children: (
        <div
          style={{
            paddingLeft: 24,
          }}
        >
          <Descriptions
            bordered
            items={[
              {
                key: "1",
                label: "Red Hat",
                children: (
                  <Link to={"https://authnull-agents.s3.amazonaws.com/linux/linux-build.zip"}>
                    Download
                  </Link>
                ),
                span: 3,
              },
              {
                key: "2",
                label: "Billing Mode",
                children: (
                  <Link to={"https://authnull-agents.s3.amazonaws.com/linux/linux-build.zip"}>
                    Download
                  </Link>
                ),
                span: 3,
              },
            ]}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Configure the following fields on app.env found in the agent folder",
      children: (
        <>
          <Row
            style={{
              paddingLeft: 24,
            }}
          >
            <Col span={20}>
              <Row gutter={16}>
                <Col span={10}>
                  <p className="search-label">Machine Key</p>
                  <Input />
                </Col>
                <Col span={10}>
                  <p className="search-label">
                    Agent Token{" "}
                    <Tooltip title="Agent Token to be used for registering">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                        }}
                      />
                    </Tooltip>
                  </p>
                  <Input
                    value={code}
                    disabled
                    suffix={
                      <Tooltip title="Copy token">
                        <CopyTwoTone onClick={() => handleCopy(code)} />
                      </Tooltip>
                    }
                  />
                </Col>
                <Col span={10}>
                  <p className="search-label">
                    Tenant Id{" "}
                    <Tooltip title="Tenant Id to be updated. Ask your tenant admin for this information">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                        }}
                      />
                    </Tooltip>
                  </p>
                  <Input
                    value={localStorage.getItem("DomainId")}
                    disabled
                    suffix={
                      <Tooltip title="Copy id">
                        <CopyTwoTone onClick={() => handleCopy(localStorage.getItem("DomainId"))} />
                      </Tooltip>
                    }
                  />
                </Col>
                <Col span={10}>
                  <p className="search-label">
                    Organization Id{" "}
                    <Tooltip title="Organization Id to be updated. Ask your tenant admin for this information">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                        }}
                      />
                    </Tooltip>
                  </p>
                  <Input
                    value={localStorage.getItem("OrgId")}
                    disabled
                    suffix={
                      <Tooltip title="Copy id">
                        <CopyTwoTone onClick={() => handleCopy(localStorage.getItem("OrgId"))} />
                      </Tooltip>
                    }
                  />
                </Col>
                <Col span={10}>
                  <p className="search-label">
                    Tenant URL{" "}
                    <Tooltip title="Update your tenant url. Ask your tenant admin for this information">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                        }}
                      />
                    </Tooltip>
                  </p>
                  <Input
                    disabled
                    value={`http://${localStorage.getItem("URL")}`}
                    suffix={
                      <Tooltip title="Copy URL">
                        <CopyTwoTone
                          onClick={() => handleCopy(`http://${localStorage.getItem("URL")}`)}
                        />
                      </Tooltip>
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <>
      <Row className="content-conatiner">
        <Col span={24} className="endpoint-collapse-container">
          <Collapse defaultActiveKey={["1"]} bordered={false} items={items} />
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: "1rem" }}>
        <Col>
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button type="primary" htmlType="submit" onClick={() => setCurrentTab(currentTab + 1)}>
              Next
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export const GooglePAMConfig = ({ notification, currentTab }) => {
  const [code, setCode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchAgentKey();
  }, []);

  const fetchAgentKey = () => {
    let keyData = {
      domainId: DOMAIN_ID(),
      expired: false,
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/generateAgentkey`, keyData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        if (res.data) {
          setCode(res.data.agentKey);
        }
      })
      .catch((err) => {
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch agent key",
          });
        }
      });
  };
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
  };
  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <h3>Screen under development</h3>
        </Col>
      </Row>
      {/* <Row justify="end" style={{ marginTop: "1rem" }}>
        <Col span={10}>
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button type="primary" htmlType="submit" onClick={() => navigate(-1)}>
              Save
            </Button>
          </Space>
        </Col>
      </Row> */}
    </>
  );
};
