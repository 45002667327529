import { useState, useEffect } from "react";
import { Card, Col, Progress, Row } from "antd";
import React from "react";
import Find from "../../assets/find.svg";
import { useNavigate } from "react-router-dom";
import "../../styles/onboard.scss";
import ReactJoyride from "react-joyride";

const ConfigOnboard = () => {
  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    //TODO : Onboarding api call done here
    let status = localStorage.getItem("isUserOnBoarded");
    setIsOnboarding(status !== "false" ? true : false);
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        target: "#onboarding_step1",
        content: <span>Setup any one of this</span>,
        disableBeacon: true,
        hideCloseButton: true,
        hideFooter: true,
      },
    ];

    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };

    return (
      <ReactJoyride
        steps={tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        // scrollOffset={100}
        disableBeacon={true}
        disableOverlayClose={true}
        spotlightClicks={true}
        // showSkipButton
        // showProgress

        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };
  return (
    <>
      <div className="onboarding-container">
        {isOnBoarding && startTour()}
        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col span={8}>
                <h2 className="title">What would you like to do?</h2>
                {/* <h4>Required for everyone</h4> */}
                {/* <Row style={{ marginBottom: "1rem" }}>
                  <Col span={23}>
                    <Card
                      id="onboarding_step1"
                      className="card-container card-border-config"
                      style={{ cursor: "pointer" }}
                      actions={[<Progress percent={50} showInfo={false} />]}
                      onClick={() => navigate("/onboard/setup/tenantConfig")}
                    >
                      <h3 className="card-header-h3">
                        Configure Tenant <br /> 75% complete <br />
                        <br />
                      </h3>
                    </Card>
                  </Col>
                </Row> */}
              </Col>
              {/* <Col span={12}>
                <div style={{ border: "1px solid #000", height: "300", padding: "1rem" }}>
                  <Row justify="space-between">
                    <Col span={4}>
                      <img src={Find} alt="Image" width="150" height="100" />
                    </Col>
                    <Col span={12}>
                      <p>
                        Can’t find what you’re looking for? <br /> <br />
                        Switch to use case view
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col> */}
            </Row>

            <Row gutter={[20, 24]} id="onboarding_step1" style={{ marginTop: "1rem" }}>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[
                    <Progress percent={localStorage.getItem("onboardMFA")} showInfo={false} />,
                  ]}
                  onClick={() => navigate("/onboard/setup/mfa")}
                >
                  <h3 className="card-header-h3">
                    Setup based MFA <br />
                    {localStorage.getItem("onboardMFA")}% complete
                    <br />
                    <br />
                  </h3>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[
                    <Progress
                      percent={localStorage.getItem("onboardLocalAccount")}
                      showInfo={false}
                    />,
                  ]}
                  onClick={() => navigate("/onboard/setup/localAccount")}
                >
                  <h3 className=" card-header-h3">
                    Setup Privileged access for local accounts <br />
                    {localStorage.getItem("onboardLocalAccount")}% complete <br />
                    <br />
                  </h3>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[
                    <Progress
                      percent={localStorage.getItem("onboardServiceAccount")}
                      showInfo={false}
                    />,
                  ]}
                  onClick={() => navigate("/onboard/setup/serviceAccount")}
                >
                  <h3 className="card-header-h3">
                    Setup Privileged access for service accounts
                    <br />
                    {localStorage.getItem("onboardServiceAccount")}% complete{" "}
                  </h3>
                </Card>
              </Col>
              {/* <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[<Progress percent={50} showInfo={false} />]}
                >
                  <h3 style={style.h3}>
                    Setup Privileged access
                    <br />
                    for service accounts
                    <br />
                    75% complete{" "}
                  </h3>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[<Progress percent={50} showInfo={false} />]}
                >
                  <h3 style={style.h3}>
                    Dynamic Authorization
                    <br />
                    75% complete <br />
                    <br />
                  </h3>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[<Progress percent={50} showInfo={false} />]}
                >
                  <h3 style={style.h3}>
                    Just in time Privileged Access
                    <br />
                    75% complete <br />
                    <br />
                  </h3>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[<Progress percent={50} showInfo={false} />]}
                >
                  <h3 style={style.h3}>
                    Setup Endpoint Security
                    <br />
                    75% complete <br />
                    <br />
                  </h3>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ConfigOnboard;
