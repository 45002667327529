import React, { useEffect, useState } from "react";
import { Col, Row, Select, Button, Input, Form, Checkbox, Space, InputNumber } from "antd";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../constants";
import { Option } from "antd/es/mentions";

const PasswordPolicies = (hostName) => {
  const [isLoading, setIsLoading] = useState(false);
  const [iPAddress, setIPAddress] = useState();
  const [policyName, setPolicyName] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [minLength, setMinLength] = useState(0);
  const [maxLength, setMaxLength] = useState(0);
  const [Firstchar, setFirstChar] = useState("");
  const [caseUpperLower, setCaseupperLower] = useState("");
  const [specialChar, setSpecialChar] = useState("");
  const [numericChar, setNumericChar] = useState(0);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const initialValues = {
      templateId: "Default",
      firstCharacter: "Any",
      uppercase: "yes",
    };
    form.setFieldsValue(initialValues);
    setFormData({
      templateId: "Default",
      firstCharacter: "Any",
      uppercase: "yes",
    });
  }, [console.log(caseUpperLower)]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostaddress = queryParams.get("hostaddress");

  const handleSave = (e) => {
    let payload = {
      policyName: policyName,
      templateName: templateName,
      minLen: minLength.toString(),
      maxLen: maxLength.toString(),
      firstChar: Firstchar,
      allowUpperLower: caseUpperLower,
      allowNumeric: numericChar.toString(),
      allowSpecial: specialChar,
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/addPasswordPolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        setIsLoading(false);
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const templateData = [
    {
      value: "default",
      label: "Default",
    },
    {
      value: "template1",
      label: "Template 1",
    },
    {
      value: "template2",
      label: "Template 2",
    },
    {
      value: "template3",
      label: "Template 3",
    },
    {
      value: "template4",
      label: "Template 4",
    },
    {
      value: "createNew",
      label: "Create New",
    },
  ];

  const characterData = [
    {
      value: "any",
      label: "Any",
    },
    {
      value: "alphaNumeric",
      label: "Alpha Numeric",
    },
    {
      value: "alphaCharacters",
      label: "Alpha Characters",
    },
  ];

  const uppercaseData = [
    {
      value: "yes",
      label: "Yes",
    },
    {
      value: "no",
      label: "No",
    },
  ];

  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className="title">{`Password Policy - ${hostaddress} `} </h2>
          </Col>
        </Row>
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item name="templateId" label="Copy from Template">
                    <Select
                      value={formData.templateId}
                      defaultValue={"Default"}
                      tokenSeparators={[","]}
                      options={templateData}
                      onChange={(option) => setTemplateName(option.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="policies"
                    label="Policy Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input policy name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Policy Name"
                      onChange={(e) => setPolicyName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <h4 style={{ marginBottom: "0rem" }}>Set Default</h4>
          </Row>
          <Row>
            <Form.Item style={{ marginTop: "1rem" }}>
              <Checkbox defaultChecked>
                Default for all users and groups, unless overridden (all users will now get this
                password policy)
              </Checkbox>
            </Form.Item>
          </Row>

          <Row>
            <Col>
              <h4>Rules</h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item name="minlength" label="Min Length">
                    <InputNumber
                      defaultValue={1}
                      min={1}
                      max={20}
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => setMinLength(value)}
                      value={minLength}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="maxlength" label="Max Length">
                    <InputNumber
                      defaultValue={4}
                      min={1}
                      max={25}
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => setMaxLength(value)}
                      value={maxLength}
                    />
                  </Form.Item>
                </Col>

                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item name="firstCharacter" label="First Character">
                    <Select
                      value={formData.firstCharacter}
                      tokenSeparators={[","]}
                      options={characterData}
                      onChange={(option) => setFirstChar(option.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="uppercase" label="Allow all uppercase / lowercase">
                    <Select
                      value={formData.uppercase}
                      labelInValue={true}
                      defaultValue={"Yes"}
                      tokenSeparators={[","]}
                      options={uppercaseData}
                      onChange={(option) => setCaseupperLower(option.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item name="numericCharacters" label="How many numeric characters">
                    <InputNumber
                      defaultValue={1}
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => setNumericChar(value)}
                      value={numericChar}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="specialCharacters" label="Allowed special characters">
                    <Input
                      placeholder="Special Characters"
                      onChange={(e) => setSpecialChar(e.target.value)}
                    />{" "}
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row justify="end" style={{ marginTop: "1rem" }}>
            <Col span={8}>
              <Space>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default PasswordPolicies;
