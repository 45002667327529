import { Col, Input, Row, message, Button, Form, Modal, Card, Divider, Space, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_TENANTS_URL } from "../../constants";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import "../../styles/Unauthorized/signup.scss";
const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [notification, setNotification] = message.useMessage();

  const UserId = queryParams.get("userId");

  const fetchSignup = (e) => {
    let pageDetails = {
      ...e,
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/orgsignup`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        form.resetFields();
        notification.open({
          type: "success",
          content: "User registered successfully. Please check you registered email to verify",
        });
        setTimeout(() => {
          window.location.replace("https://authnull.com/");
        }, 4000);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error during signup:", err);
      });
  };

  const validateEmailAndOrg = (mail, org) => {
    const validationData = {
      orgname: org ? org : "",
      email: mail ? mail : "",
    };

    return axios.post(`${REACT_APP_API_TENANTS_URL}/validateemailandorgname`, validationData, {
      headers: {
        withCredentials: true,
      },
    });
  };

  return (
    <>
      {setNotification}

      <div className="signup-container">
        <div class="card-container">
          <Card bordered={false}>
            <h2>30 days free trial</h2>

            <Divider />

            <p style={{ marginBottom: "12px" }}>Setup a 30 days full featured free trial</p>
            <p>
              <CheckCircleTwoTone twoToneColor="hsl(102, 53%, 61%)" /> Unlimited Users
            </p>
            <Divider style={{ margin: "16px 0" }} />

            <p>
              <CheckCircleTwoTone twoToneColor="hsl(102, 53%, 61%)" /> Unlimited Endpoints
            </p>
            <Divider style={{ margin: "16px 0" }} />

            <p>
              <CheckCircleTwoTone twoToneColor="hsl(102, 53%, 61%)" /> Unlimited Logins
            </p>
            <Divider style={{ margin: "16px 0" }} />

            <p>
              <CheckCircleTwoTone twoToneColor="hsl(102, 53%, 61%)" /> Email Support
            </p>
            <Divider style={{ margin: "16px 0" }} />

            <p>
              <CheckCircleTwoTone twoToneColor="hsl(102, 53%, 61%)" /> AD Support
            </p>

            <Space wrap size={16} style={{ margin: "2rem 0" }}>
              <Avatar
                shape="square"
                size={94}
                src={
                  <img
                    style={{
                      display: "flex",
                      margin: "auto",
                    }}
                    alt="Active Directory"
                    src="https://www.silviodibenedetto.com/wp-content/uploads/2019/08/hero_activedirectory.jpg"
                  />
                }
              />
              <Avatar
                shape="square"
                size={94}
                src={
                  <img
                    style={{
                      display: "flex",
                      margin: "auto",
                      width: "220px",
                    }}
                    alt="Active Directory"
                    src="https://1000logos.net/wp-content/uploads/2022/03/Okta-Logo.jpg"
                  />
                }
              />
              <Avatar
                shape="square"
                size={94}
                src={
                  <img
                    style={{
                      display: "flex",
                      margin: "auto",
                      width: "90px",
                    }}
                    alt="Active Directory"
                    src="https://cdn.vectorstock.com/i/preview-1x/15/24/login-icon-in-flat-style-password-access-on-white-vector-34131524.jpg"
                  />
                }
              />
            </Space>
          </Card>
        </div>
        <div className="form-container">
          <h2>Create a Organization Account</h2>
          <div style={{ display: "flex", alignItem: "center", marginBottom: "2rem" }}>
            <span>
              Have an account?{" "}
              <a style={{ color: "blue" }} href="/org/login">
                Log in
              </a>
            </span>
          </div>

          <Form form={form} layout="vertical" onFinish={fetchSignup}>
            <Row align="middle" justify="space-between" style={{ marginBottom: "1rem" }}>
              <Col span={11}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "First name required",
                    },
                  ]}
                >
                  <Input placeholder="Enter first name" size="large" />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Last name required",
                    },
                  ]}
                >
                  <Input placeholder="Enter Last name" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" justify="space-between" style={{ marginBottom: "1rem" }}>
              <Col span={11}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator(rule, value) {
                        return new Promise(async (resolve, reject) => {
                          if (value) {
                            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            const isValid = emailRegex.test(value.trim());
                            if (isValid) {
                              try {
                                const resEmail = await validateEmailAndOrg(value);
                                resEmail?.data?.code === 400
                                  ? reject("Email already exist")
                                  : resolve();
                              } catch {}
                            } else {
                              reject("Please enter valid email address");
                            }
                          } else {
                            reject("Email required");
                          }
                        });
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter Email Address" size="large" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="orgName"
                  label="Organization Name"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator(rule, value) {
                        return new Promise(async (resolve, reject) => {
                          if (value) {
                            try {
                              const resEmail = await validateEmailAndOrg("", value);
                              resEmail?.data?.code === 400
                                ? reject("Organization name already exist")
                                : resolve();
                            } catch {}
                          } else {
                            reject("Organization name required");
                          }
                        });
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter your Orgnization name" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" justify="space-between" style={{ marginBottom: "1rem" }}>
              <Col span={11}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Password required",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    size="large"
                    placeholder="Password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  name="confirmpassword"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Confirm Password required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The new password that you entered do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Confirm password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="start" style={{ marginBottom: "1rem" }}>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  size="large"
                  style={{ width: "10rem" }}
                >
                  Sign up
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Signup;
