import React, { useEffect, useState } from "react";
import { Cascader, Col, Input, Row, Table, Tag, message } from "antd";
import { logOutUser } from "../../../common";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import axios from "axios";
import { useDebounce } from "../../../common/debounce";
import moment from "moment-timezone";

import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const ListSshKeys = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [policyData, setPolicyData] = useState([]);
  const [endpoint, setEndpoint] = useState([]);
  const [endpointGroup, setEndpointGroup] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();

  useEffect(() => {
    fetchCredRotationPolicy();
  }, [paginationParams, debouncedValue, filterBy, filteredValue]);

  useEffect(() => {
    fetchInstance();
    fetchEndpointGroup();
  }, []);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const columns = [
    { headerName: "Policy Name", field: "policyName", key: "policyName", flex: 1 },
    {
      headerName: "Rotation Time Frame",
      field: "rotationInDays",
      key: "rotationInDays",
      flex: 1,
      renderCell: (data) => (
        <span>
          {data.row.rotationInDays} {data.row.rotationInDays === 1 ? "day" : "days"}
        </span>
      ),
    },
    { headerName: "User Type", field: "userType", key: "userType", flex: 1 },
    {
      headerName: "Endpoints Impacted",
      field: "endpoints",
      key: "endpoints",
      flex: 1,
      renderCell: (tags) => (
        <span>
          {tags.row.endpoints.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    { headerName: "Endpoint Groups", field: "endpointGroups", key: "endpointGroups", flex: 1 },
    {
      headerName: "Last Modified",
      field: "lastModified",
      key: "lastModified",
      flex: 1,
      renderCell: (data) => {
        let time = moment(data.row.lastModified)
          .tz("America/Los_Angeles")
          .format("MM-DD-YYYY  HH:mm z");
        return time;
      },
    },
    { headerName: "Status", field: "status", key: "status", flex: 1 },
  ];

  const fetchCredRotationPolicy = (e) => {
    let requestData = {
      pageId: paginationParams?.current,
      pageSize: paginationParams?.pageSize,
      search,
      credentialType: "SSH",
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAllCredentialRotationPolicy`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTotalCount(res?.data?.totalCount);
        const newPolicyData = res?.data?.credentialPolicies?.map((data, index) => ({
          ...data,
          id: index + 1,
        }));
        console.log(policyData);
        setPolicyData(newPolicyData);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };

  const fetchInstance = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoint(
          res?.data?.instances.map((ins) => ({
            value: ins.instanceId,
            label: ins.hostName,
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");

    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/instanceGroup/listEndpointGroup`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setEndpointGroup(
          res?.data?.groups?.map((grp) => ({
            value: grp.groupId,
            label: grp.groupName,
          }))
        );
      })

      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints groups",
          });
        }
      });
  };
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
    setPaginationParams({ current: 1, pageSize: 10 });
  };
  const option = [
    {
      value: "ENDPOINT",
      label: "Endpoints",
      children: endpoint,
    },
    {
      value: "endpointGroup",
      label: "Endpoint Group",
      children: endpointGroup,
    },
    {
      value: "Status",
      label: "Status",
      children: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    },
  ];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Cascader
                      options={option}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      onChange={handleFilter}
                      placeholder="Search to select"
                      showSearch={{
                        filter,
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={policyData}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      current: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              /> */}

              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={policyData}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                page={paginationParams.current}
                pageSize={paginationParams.pageSize}
                onPaginationModelChange={(newPage) => {
                  setPaginationParams({
                    ...paginationParams,
                    current: newPage.page,
                  });
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ListSshKeys;
