// import "./styles/index.scss";
import { useState } from "react";
import { Avatar, Layout, Menu, Popover, Tooltip, Button, Space, Row, Col, Typography } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import Users from "./directory/users/Users";
import { useLocation } from "react-router-dom";
import {
  ControlOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  RadarChartOutlined,
  SolutionOutlined,
  LogoutOutlined,
  IdcardOutlined,
  KeyOutlined,
  DesktopOutlined,
  SettingOutlined,
  RotateRightOutlined,
  PullRequestOutlined,
  CloudServerOutlined,
} from "@ant-design/icons/lib/icons";
import { UserOutlined } from "@ant-design/icons";
import { logOutUser } from "../common";
import Endpoints from "./endUser/Endpoints";
import MyConnections from "./endUser/MyConnections";
import AssignedVc from "./endUser/AssignedVc";
import Groups from "./directory/groups/Groups";
import Devices from "./directory/Devices";
import ActiveDirectory from "./directory/activeDirectory/ActiveDirectory";
import Jumpserver from "./privilegedAccessManagement/jumpServers/Jumpserver";
import AddJumpServer from "./privilegedAccessManagement/jumpServers/AddJumpServer";
import EndpointUsers from "./privilegedAccessManagement/endpointsUsers/EndpointUsers";
import AssignEndpointUser from "./privilegedAccessManagement/endpointsUsers/AssignEndpointUser";
import SessionRecordings from "./privilegedAccessManagement/sessionRecordings/SessionRecordings";
import Connection from "./privilegedAccessManagement/connections/Connection";
import CreateConnection from "./privilegedAccessManagement/connections/CreateConnection";
import PAMEndpoint from "./privilegedAccessManagement/endpoints/Endpoints";
import CheckoutUser from "./privilegedAccessManagement/endpointsUsers/CheckoutUser";
import EndpointGroups from "./privilegedAccessManagement/endpointGroups/EndpointGroups";
import ViewCredentials from "./privilegedAccessManagement/endpointsUsers/ViewCredentials";
import { AddEndpoint } from "./privilegedAccessManagement/endpoints/AddEndpoint";
import CredentialRotationPolicy from "./privilegedAccessManagement/endpointsUsers/CredentialRotationPolicy";
import AddEndpointUser from "./privilegedAccessManagement/endpointsUsers/AddEndpointUser";
import ForceResetCredentials from "./privilegedAccessManagement/endpointsUsers/ForceResetCredentials";
import CredentialRotation from "./privilegedAccessManagement/credentialRotation/CredentialRotation";
import AddPasswordPolicy from "./privilegedAccessManagement/credentialRotation/AddPasswordPolicy";
import AddSshKeyPolicy from "./privilegedAccessManagement/credentialRotation/AddSshKeyPolicy";
import PasswordPolicies from "./privilegedAccessManagement/endpoints/PasswordPolicies";
import DidDashboard from "./did/dashboard";
import DIDS from "./did/dids";
import WalletUsers from "./did/walletUsers";
import Dashboard from "./dashboard";
import Credential from "./did/credentials";
import VerifiableCredential from "./did/verifiableCredential";
import TransactionLog from "./did/transactionLogs";
import EndpointGroupDetails from "./privilegedAccessManagement/endpointGroups/EndpointGroupDetails";
import EndpointDetails from "./privilegedAccessManagement/endpoints/EndpointDetails";
import AddDID from "./did/addUserDID";
import AddHolderrDID from "./did/addHolderDID";
import AddUserDID from "./did/addUserDID";
import UserDetails from "./directory/UserDetails";
import UserDetailsGroups from "./directory/UserDetailsGroups";
import UserDetailsProfile from "./directory/UserDetailsProfile";
import EndpointUserDetails from "./privilegedAccessManagement/endpointsUsers/EndpointUserDetails";
import AddDirectory from "./directory/activeDirectory/addDirectory";
import Step from "./directory/activeDirectory/step";
import ADStep from "./directory/activeDirectory/step";
import ServiceCredentials from "./serviceAccount/ServiceCredentials";
import AddServiceAccount from "./serviceAccount/AddServiceAccount";
import ServiceAccount from "./serviceAccount/ServiceAccount";
import AddWorkloadIdentity from "./workloadIdentity/AddworkloadIdentity";
import WorkloadIdentity from "./workloadIdentity/workloadIdentity";
import WorkloadConditionalAccess from "./workloadIdentity/WorkloadConditionalaccess";
import { REACT_APP_URL } from "../constants";
import Signup from "./unauthorized/Signup";
import WindowsEndpoints from "./windows/windowsPam";
import JitPolicy from "./windows/jitPolicy";
import JitConnections from "./windows/jitConnections";
import EndpointPolicies from "./windows/endpointPolicies";
import CreateWindowsPolicy from "./windows/createWindowsPolicy";
import ApplicationRequest from "./windows/applicationRequest";
import RequestLogs from "./windows/request";
import AuditLogs from "./windows/auditLogs";
import Settings from "./SuperAdmin/settings/Settings";
import AdminDashboard from "./SuperAdmin/Dashboard";
import Createtenant from "./SuperAdmin/CreateTenant";
import OktaSetup from "./SuperAdmin/settings/OktaSetup";
import Details from "./SuperAdmin/Details";
import Tenant from "./SuperAdmin/Tenant";
import AddTenant from "./SuperAdmin/AddTenant";
import AuthenticationPolicy from "./windows/authenticationPolicies";
import CreateEndpointAccessPolicy from "./windows/createEndpointAccessPolicy";
import CreateSimplifiedAccessPolicy from "./windows/simplifiedAccessPolicy";
import TenantConfig from "./windows/tenantConfig";
import Tour from "./tour";
import PolicyList from "./windows/policyList";
import Module from "./onboarding/Module";
import Welcome from "./onboarding/Welcome";
import ConfigOnboard from "./onboarding/ConfigOnboard";
import RiskBasedMFA from "./onboarding/setups/RiskBasedMFA";
import PamServiceAccount from "./onboarding/setups/PamServiceAccount";
import PamLocalAccount from "./onboarding/setups/PamLocalAccount";
import Splunk from "./splunk/Splunk";
import Logs from "./windows/logs";
import { OnboardLinux } from "./privilegedAccessManagement/endpoints/addEndpoint/OnboardLinux";
import { OnboardWindow } from "./privilegedAccessManagement/endpoints/addEndpoint/OnboardWindow";

const { Text } = Typography;
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
function PrimaryLayoutLocal() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole] = useState(localStorage.getItem("UserRole"));
  const [userName] = useState(localStorage.getItem("UserName"));
  const [openKeys, setOpenKeys] = useState([]);
  const [openSubMenuKeys, setOpenSubMenuKeys] = useState([]);
  const [mode, setMode] = useState("pam");
  const [mainMenuSelectedValues, setMainMenuSelectedValues] = useState(null);
  const [subMenuSelectedValues, setSubMenuSelectedValues] = useState(null);
  const currentPath = location.pathname;
  const ENVIRONMENT = process.env.NODE_ENV;
  const handleNavigation = (path) => {
    if (localStorage.getItem("token")) {
      navigate(path);
    } else {
      logOutUser();
    }
  };
  const handleSubMenuOpenChange = (keys) => {
    // Ensure only one submenu is open at a time
    if (keys.length > 1) {
      setOpenKeys([keys.pop()]); // Keep the last opened submenu
      setOpenSubMenuKeys([]);
    } else {
      setOpenKeys(keys);
    }
  };

  // my chnages
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleOptionSelect = () => {
    // Update the state and close the Popover
    setPopoverVisible(false);
  };
  // ends here

  const section = (
    <Row gutter={[16, 16]} align="middle" style={{ width: "305px", marginTop: "-20px" }}>
      <Col span={24}>
        <Row
          gutter={16}
          align="middle"
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            marginLeft: "-12px",
            marginRight: "-12px",
            background: "#000",
          }}
        >
          <Col>
            <Avatar size={32} icon={<UserOutlined />} />
          </Col>
          <Col>
            <Text style={{ color: "#fff" }} strong>
              {localStorage.getItem("UserName")}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          style={{
            borderRight: 0,
          }}
        >
          {userRole === "ADMIN" ||
            ("SUPERADMIN" && (
              <>
                <Menu.Item
                  icon={<ControlOutlined />}
                  key="pam"
                  onClick={() => {
                    handleOptionSelect();
                    handleNavigation("/endpointsUsers");
                    setMode("pam");
                  }}
                  style={{ padding: "16px" }}
                >
                  PAM
                </Menu.Item>
                <Menu.Item
                  icon={<SolutionOutlined />}
                  key="directory"
                  onClick={() => {
                    handleOptionSelect();
                    handleNavigation("/directory/users");
                    setMode("directory");
                  }}
                  style={{ padding: "16px" }}
                >
                  Directory
                </Menu.Item>
                <Menu.Item
                  icon={<SolutionOutlined />}
                  key="Config"
                  onClick={() => {
                    handleOptionSelect();
                    handleNavigation("/onboard/setup/tenantConfig");
                    setMode("tenantConfig");
                  }}
                  style={{ padding: "16px" }}
                >
                  Tenant Config
                </Menu.Item>
                {(REACT_APP_URL === "https://localhost:5173/" || ENVIRONMENT === "testing") && (
                  <>
                    <Menu.Item
                      icon={<SolutionOutlined />}
                      key="windows"
                      onClick={() => {
                        handleOptionSelect();
                        handleNavigation("/windows/endpoints");
                        setMode("window");
                      }}
                      style={{ padding: "16px" }}
                    >
                      Windows
                    </Menu.Item>
                  </>
                )}
              </>
            ))}
          <Menu.Item
            icon={<LogoutOutlined />}
            key="logout"
            onClick={() => logOutUser()}
            style={{ color: "blue", padding: "16px" }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Col>
    </Row>
  );

  const withoutHeaderPage = [
    "/onboard",
    "/onboard/module",
    // "/onboard/config",
    // "/onboard/setup/azureAdUser",
    // "/onboard/setup/mfa",
    // "/onboard/setup/localAccount",
    // "/onboard/setup/tenantConfig",
  ];

  return (
    <>
      <Layout style={{ minHeight: "100vh" }} className="app-container">
        {!withoutHeaderPage.includes(currentPath) && (
          <Header className="header-container">
            <h1 className="logo-container">
              <RadarChartOutlined />
              Authnull
            </h1>

            {/* <Popover
            placement="bottom"
            title={localStorage.getItem("UserName")}
            content={
              <a onClick={() => logOutUser()}>
                <LogoutOutlined /> Log Out
              </a>
            }
          >
            <Avatar size="large" style={{ background: "#fff", color: "#000" }}>
              {userName[0].toLocaleUpperCase()}
            </Avatar>
          </Popover> */}
          </Header>
        )}

        <Layout className="body-container" style={{ flex: 1 }} hasSider>
          {!withoutHeaderPage.includes(currentPath) && (
            <Sider width={280} className="left-menu-container">
              <div className="sidebar__container">
                <div className="sidebar__container__menu">
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    openKeys={openKeys} // Set the openKeys to maintain open submenus
                    onOpenChange={handleSubMenuOpenChange} // Handle submenu open changes
                    style={{
                      borderRight: 0,
                    }}
                    // selectedKeys={mainMenuSelectedValues}
                    // onClick={handleMainMenuClick}
                  >
                    {userRole === "ADMIN" || "SUPERADMIN" ? (
                      <>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key="dashboard"
                          onClick={() => handleNavigation("/")}
                        >
                          Dashboard
                        </Menu.Item>
                        {/* TODO: TOUR IS UNDER DEVELOPMENT */}
                        {(ENVIRONMENT === "development" || "testing") && (
                          <Menu.Item
                            icon={<DashboardOutlined />}
                            key="tour"
                            onClick={() => handleNavigation("/onboard/config")}
                          >
                            Getting Started
                          </Menu.Item>
                        )}
                        {mode === "pam" && (
                          <>
                            <SubMenu key="endpoint" title="Endpoint" icon={<ControlOutlined />}>
                              <Menu.Item
                                key="endpointsUsers"
                                onClick={() => handleNavigation("/endpointsUsers")}
                              >
                                Endpoints Users
                              </Menu.Item>
                              <Menu.Item
                                key="endpointsGroups"
                                onClick={() => handleNavigation("/endpointGroups")}
                              >
                                Endpoints Groups
                              </Menu.Item>
                              <Menu.Item
                                key="endpoints"
                                onClick={() => handleNavigation("/endpoint")}
                              >
                                Endpoints
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu
                              key="jumpServer"
                              title="Jump Server"
                              icon={<CloudServerOutlined />}
                            >
                              <Menu.Item
                                key="jumpServers"
                                onClick={() => handleNavigation("/linuxMachines/jumpServer")}
                              >
                                Jump Servers
                              </Menu.Item>
                              <Menu.Item
                                key="connections"
                                onClick={() => handleNavigation("/connections")}
                              >
                                Connections
                              </Menu.Item>
                              <Menu.Item
                                key="sessionRecording"
                                onClick={() => handleNavigation("/sessionRecordings")}
                              >
                                Session Recording
                              </Menu.Item>
                            </SubMenu>
                            <Menu.Item
                              key="credentialRoration"
                              icon={<RotateRightOutlined />}
                              onClick={() => handleNavigation("/credentialRotation")}
                            >
                              Credential Rotation
                            </Menu.Item>

                            <SubMenu
                              key="authPolicies"
                              icon={<PullRequestOutlined />}
                              title="Authentication Policies"
                            >
                              <Menu.Item
                                key="authenticationPolicy"
                                onClick={() => handleNavigation("/windows/authnPolicy")}
                              >
                                Requests
                              </Menu.Item>
                              <Menu.Item
                                key="policyList"
                                onClick={() => handleNavigation("/windows/policies")}
                              >
                                Policies
                              </Menu.Item>
                              <Menu.Item
                                key="auditLogs"
                                onClick={() => handleNavigation("/windows/logs")}
                              >
                                Authentication Logs
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu
                              key="serviceAccount"
                              icon={<CreditCardOutlined />}
                              title="Service Account"
                            >
                              <Menu.Item
                                key="showServiceAccount"
                                onClick={() => handleNavigation("/serviceCredentials")}
                              >
                                Policies
                              </Menu.Item>
                              <Menu.Item
                                key="listServiceAccount"
                                onClick={() => handleNavigation("/serviceAccount")}
                              >
                                Service Account
                              </Menu.Item>
                            </SubMenu>
                          </>
                        )}
                        {mode === "directory" && (
                          <>
                            <SubMenu key="directory" icon={<SolutionOutlined />} title="Directory">
                              <Menu.Item
                                key="users"
                                onClick={() => handleNavigation("/directory/users")}
                              >
                                Users
                              </Menu.Item>
                              <Menu.Item
                                key="groups"
                                onClick={() => handleNavigation("/directory/groups")}
                              >
                                Groups
                              </Menu.Item>
                              {/* <Menu.Item key="devices" onClick={() => handleNavigation("/directory/devices")}>
                    Devices
                  </Menu.Item> */}
                              <Menu.Item
                                key="activeDirectory"
                                onClick={() => handleNavigation("/directory/activeDirectory")}
                              >
                                Active Directory
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu key="did" icon={<CreditCardOutlined />} title="DID">
                              <Menu.Item
                                key="diddashboard"
                                onClick={() => handleNavigation("/did")}
                              >
                                Dashboard
                              </Menu.Item>
                              <Menu.Item key="dids" onClick={() => handleNavigation("/did/dids")}>
                                DIDs
                              </Menu.Item>
                              <Menu.Item
                                key="WalletUsers"
                                onClick={() => handleNavigation("/did/walletUser")}
                              >
                                Wallet Users
                              </Menu.Item>
                              <Menu.Item
                                key="verifiableCredentials"
                                onClick={() => handleNavigation("/did/verifiableCredentials")}
                              >
                                Verifiable Credentials
                              </Menu.Item>
                              <Menu.Item
                                key="transactionLogs"
                                onClick={() => handleNavigation("/did/transactionLogs")}
                              >
                                Transaction Logs
                              </Menu.Item>
                            </SubMenu>
                          </>
                        )}

                        {(REACT_APP_URL === "https://localhost:5173/" ||
                          ENVIRONMENT === "testing") &&
                          mode === "window" && (
                            <SubMenu key="windows" icon={<SolutionOutlined />} title="Windows EPS">
                              <Menu.Item
                                key="windowsEndpoints"
                                onClick={() => handleNavigation("/windows/endpoints")}
                              >
                                Windows Endpoints
                              </Menu.Item>

                              <Menu.Item
                                key="endpointPolicies"
                                onClick={() => handleNavigation("/windows/endpointPolicies")}
                              >
                                Endpoint Policies
                              </Menu.Item>

                              <Menu.Item
                                key="requestLogs"
                                onClick={() => handleNavigation("/windows/requestLogs")}
                              >
                                Request Logs
                              </Menu.Item>
                              <Menu.Item
                                key="auditLogs"
                                onClick={() => handleNavigation("/windows/auditLogs")}
                              >
                                Audit Logs
                              </Menu.Item>
                            </SubMenu>
                          )}

                        {REACT_APP_URL === "http://localhost:5173/" ||
                          (ENVIRONMENT === "testing" && mode === "dpa" && (
                            <SubMenu key="dfa" icon={<SolutionOutlined />} title="DPA PAM">
                              <Menu.Item
                                key="windowsJit"
                                onClick={() => handleNavigation("/windows/jit")}
                              >
                                JIT Access Policies
                              </Menu.Item>
                              <Menu.Item
                                key="windowsJitConnection"
                                onClick={() => handleNavigation("/windows/jitConnections")}
                              >
                                JIT Connections
                              </Menu.Item>
                            </SubMenu>
                          ))}

                        {REACT_APP_URL === "https://localhost:5173/" ||
                          (ENVIRONMENT === "testing" && mode === "pam" && (
                            <SubMenu
                              key="workload"
                              icon={<IdcardOutlined />}
                              title="Workload Identity"
                            >
                              <Menu.Item
                                key="workloadIdentity"
                                onClick={() => handleNavigation("/workloadIdentity")}
                              >
                                Workload Identity
                              </Menu.Item>
                            </SubMenu>
                          ))}

                        {mode === "tenantConfig" && (
                          <Menu.Item
                            key="showServiceAccount"
                            icon={<SolutionOutlined />}
                            onClick={() => handleNavigation("/onboard/setup/tenantConfig")}
                          >
                            Tenant Config
                          </Menu.Item>
                        )}
                      </>
                    ) : userRole === "SUPERADMIN" ? (
                      <>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key="Dashboard"
                          onClick={() => handleNavigation("/org/dashboard")}
                        >
                          Dashboard
                        </Menu.Item>
                        <Menu.Item
                          icon={<KeyOutlined />}
                          key="tenant"
                          onClick={() => handleNavigation("/tenant")}
                        >
                          Tenant
                        </Menu.Item>
                        <Menu.Item
                          icon={<SettingOutlined />}
                          key="setting"
                          onClick={() => handleNavigation("/setting")}
                        >
                          Settings
                        </Menu.Item>
                      </>
                    ) : (
                      <>
                        <Menu.Item
                          icon={<DesktopOutlined />}
                          key="endpoints"
                          onClick={() => handleNavigation("/endUser/endpoints")}
                        >
                          Endpoints
                        </Menu.Item>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key="myConnections"
                          onClick={() => handleNavigation("/endUser/connections")}
                        >
                          Connections
                        </Menu.Item>
                        <Menu.Item
                          icon={<KeyOutlined />}
                          key="assignedVC's"
                          onClick={() => handleNavigation("/endUser/assignedVcs")}
                        >
                          Assigned VC's
                        </Menu.Item>
                      </>
                    )}
                  </Menu>
                </div>
                <div className="sidebar__container__footer">
                  <Popover
                    placement="topRight"
                    content={section}
                    trigger="click"
                    visible={popoverVisible}
                    onVisibleChange={handlePopoverVisibleChange}
                  >
                    <Button
                      type="text"
                      icon={<SettingOutlined />}
                      onClick={() => setPopoverVisible(!popoverVisible)}
                      style={{
                        color: "#1890ff",
                        paddingLeft: "24px",
                      }}
                    >
                      {userRole === "ADMIN" || ("SUPERADMIN" && mode)
                        ? mode === "pam"
                          ? "PAM"
                          : mode === "window"
                          ? "Windows"
                          : mode === "directory"
                          ? "Directory"
                          : mode === "tenantConfig"
                          ? "Tenant Config"
                          : "Switch"
                        : "Switch"}
                    </Button>
                  </Popover>
                </div>
              </div>
            </Sider>
          )}
          {console.log(ENVIRONMENT)}
          <Content
            className={
              withoutHeaderPage.includes(currentPath)
                ? "without-header page-container"
                : "with-header page-container"
            }
          >
            <Routes>
              {userRole === "ADMIN" || "SUPERADMIN" ? (
                <>
                  <Route path="" element={<Dashboard />} />
                  <Route path="tour" element={<Tour />} />
                  <Route path="directory/*">
                    <Route path="users" element={<Users />} />
                    <Route path="groups" element={<Groups />} />
                    <Route path="devices" element={<Devices />} />
                    <Route path="activeDirectory/addDirectory/step" element={<ADStep />} />
                    <Route path="activeDirectory/addDirectory" element={<AddDirectory />} />
                    <Route path="activeDirectory" element={<ActiveDirectory />} />

                    <Route path="userDetails" element={<UserDetails />} />
                    <Route path="userDetailsGroups" element={<UserDetailsGroups />} />
                    <Route path="userDetailsProfile" element={<UserDetailsProfile />} />
                  </Route>
                  <Route path="windows/*">
                    <Route path="endpoints" element={<WindowsEndpoints />} />
                    <Route path="jit" element={<JitPolicy />} />
                    <Route path="jitConnections" element={<JitConnections />} />
                    <Route path="createWindowPolicy" element={<CreateWindowsPolicy />} />
                    <Route path="logs" element={<Logs />} />

                    <Route path="authnPolicy" element={<AuthenticationPolicy />} />
                    <Route path="policies" element={<PolicyList />} />
                    <Route
                      path="createEndpointAccessPolicy"
                      element={<CreateEndpointAccessPolicy />}
                    />
                    <Route
                      path="createSimplifiedAccessPolicy"
                      element={<CreateSimplifiedAccessPolicy />}
                    />
                    <Route path="tenantConfig" element={<TenantConfig />} />
                    <Route path="applicationRequest" element={<ApplicationRequest />} />
                    <Route path="auditLogs" element={<AuditLogs />} />
                    <Route path="endpointPolicies" element={<EndpointPolicies />} />
                    <Route path="requestLogs" element={<RequestLogs />} />

                    {/* <Route path="endpoints" element={<AddJumpServer />} /> */}
                  </Route>
                  <Route path="linuxMachines/*">
                    <Route path="jumpServer" element={<Jumpserver />} />
                    <Route path="addJumpServer" element={<AddJumpServer />} />
                  </Route>
                  <Route path="endpoint/*">
                    <Route path="" element={<PAMEndpoint />} />
                    <Route path="addEndpoint/*">
                      <Route path="linux" element={<OnboardLinux />} />
                      <Route path="windows" element={<OnboardWindow />} />
                    </Route>
                    <Route path="endpointDetails" element={<EndpointDetails />} />
                    <Route path="passwordPolicies" element={<PasswordPolicies />} />
                  </Route>

                  <Route path="connections/*">
                    <Route path="" element={<Connection />} />
                    <Route path=":publicIpAddress" element={<Connection />} />
                    <Route path="createConnection" element={<CreateConnection />} />
                  </Route>
                  <Route path="endpointsUsers/add" element={<AddEndpointUser />} />
                  <Route path="endpointsUsers" element={<EndpointUsers />}></Route>
                  <Route path="endpointuserDetails" element={<EndpointUserDetails />} />

                  <Route path="checkoutUser/:epmUserId" element={<CheckoutUser />} />
                  <Route
                    path="rotation/:instanceId/:epmUserId"
                    element={<CredentialRotationPolicy />}
                  />
                  <Route path="reset-credentials" element={<ForceResetCredentials />} />
                  <Route
                    path="view-credentials/:instanceId/:epmUserId"
                    element={<ViewCredentials />}
                  />
                  <Route path="endpointGroups/*">
                    <Route path="" element={<EndpointGroups />} />
                    <Route path="endpointGroupDetails" element={<EndpointGroupDetails />} />
                  </Route>
                  <Route path="sessionRecordings/:id" element={<SessionRecordings />} />

                  <Route path="credentialRotation/*">
                    <Route path="" element={<CredentialRotation />} />
                    <Route path="addPassword" element={<AddPasswordPolicy />} />
                    <Route path="addSshKeys" element={<AddSshKeyPolicy />} />
                  </Route>
                  <Route path="sessionRecordings/*">
                    <Route path="" element={<SessionRecordings />} />
                  </Route>
                  <Route path="did/*">
                    <Route path="" element={<DidDashboard />} />
                    <Route path="dids" element={<DIDS />} />
                    <Route path="addUserDID" element={<AddUserDID />} />
                    <Route path="addHolderDID" element={<AddHolderrDID />} />
                    <Route path="walletUser" element={<WalletUsers />} />
                    <Route path="walletUser/view-credentials" element={<Credential />} />
                    <Route path="verifiableCredentials" element={<VerifiableCredential />} />
                    <Route path="transactionLogs" element={<TransactionLog />} />
                  </Route>

                  <Route path="serviceAccount/*">
                    <Route path="" element={<ServiceAccount />} />
                    <Route path="addServiceAccount" element={<AddServiceAccount />} />
                  </Route>
                  <Route path="serviceCredentials" element={<ServiceCredentials />} />
                  <Route path="workloadIdentity/*">
                    <Route path="" element={<WorkloadIdentity />} />
                    <Route path="addWorkloadIdentity" element={<AddWorkloadIdentity />} />
                    <Route
                      path="workloadConditionalaccess"
                      element={<WorkloadConditionalAccess />}
                    />
                  </Route>
                  <Route path="onboard/*">
                    <Route path="" element={<Welcome />} />
                    <Route path="module" element={<Module />} />
                    <Route path="config" element={<ConfigOnboard />} />
                    <Route path="setup/*">
                      <Route path="mfa" element={<RiskBasedMFA />} />
                      <Route path="serviceAccount" element={<PamServiceAccount />} />
                      <Route path="localAccount" element={<PamLocalAccount />} />
                      <Route path="tenantConfig" element={<TenantConfig />} />
                    </Route>
                  </Route>
                  <Route path="splunk" element={<Splunk />} />
                </>
              ) : userRole === "SUPERADMIN" ? (
                <>
                  <Route path="tenant/*">
                    <Route path="" element={<Tenant />} />
                    {/* <Route path="add" element={<Createtenant />} /> */}
                    <Route path="add" element={<AddTenant />} />
                  </Route>
                  <Route path="setting/*">
                    <Route path="" element={<Settings />} />
                    <Route path="okta" element={<OktaSetup />} />
                  </Route>

                  <Route path="org/dashboard/*">
                    <Route path="" element={<AdminDashboard />} />
                    <Route path="details" element={<Details />} />
                  </Route>
                </>
              ) : (
                <>
                  <Route path="endUser/*">
                    <Route path="endpoints" element={<Endpoints />} />
                    <Route path="endpointUsers" element={<EndpointUsers />} />
                    <Route path="connections" element={<MyConnections />} />
                    <Route path="assignedVcs" element={<AssignedVc />} />
                  </Route>
                </>
              )}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default PrimaryLayoutLocal;
