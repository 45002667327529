import {
  Col,
  Row,
  Select,
  Table,
  Button,
  Input,
  message,
  Card,
  Badge,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
} from "../constants";
import { logOutUser } from "../common";

import axios from "axios";

import ReactApexChart from "react-apexcharts";
import { Column, Line } from "@ant-design/plots";
import { CheckBox } from "@mui/icons-material";
const { Option } = Select;
const { Meta } = Card;

const Dashboard = () => {
  const [credShared, setCredShared] = useState(null);
  const [credTrans, setcredTrans] = useState(null);
  const [credAllow, setCredAllow] = useState(null);
  const [notification, setNotification] = message.useMessage();
  const [initialDayVal, setInitialDayVal] = useState("day");
  const [oldUser, setOldUser] = useState(false);
  // useEffect(() => {
  //   fetchCredShared();
  //   fetchTransShared();
  //   fetchAllowedShared();
  // }, [initialDayVal]);

  const fetchCredShared = () => {
    let payload = {
      TimePeriod: initialDayVal,
    };
    axios
      .post(
        `${REACT_APP_API_DID_URL}/walletService/countNewCredentialShared`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      )
      .then((res) => {
        setCredShared(res?.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: "Unable to fetch new credential shared",
        });
      });
  };
  const fetchTransShared = () => {
    let payload = {
      TimePeriod: initialDayVal,
    };
    axios
      .post(
        `${REACT_APP_API_DID_URL}/walletService/countTransactionCredential`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      )
      .then((res) => {
        setcredTrans(res?.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: "Unable to fetch new credential shared",
        });
      });
  };

  const fetchAllowedShared = () => {
    let payload = {
      TimePeriod: initialDayVal,
    };
    axios
      .post(
        `${REACT_APP_API_DID_URL}/walletService/AllowedVsDeniedCredential`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      )
      .then((res) => {
        setCredAllow(res?.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: "Unable to fetch new credential shared",
        });
      });
  };

  const [data, setData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const fetchEPMUsers = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 10,
      token: AUTH_TOKEN(),
      instanceIds: [],
      filter: {
        filterBy: "",
        Value: "",
      },
      search: "",
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        // Assuming res?.data?.epmUsers is an array of objects
        const originalEpmUsers = res?.data?.epmUsers;
        if (originalEpmUsers.length > 0) {
          setOldUser(true);
        } else {
          setOldUser(false);
        }
        // Adding an 'id' property to each object
        const epmUsersWithId = originalEpmUsers.map((user, index) => ({
          ...user,
          id: index + 1, // You can use a different logic to generate IDs if needed
        }));

        // Now, you can store the updated array with 'id' in the state
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          // logOutUser();
        } else {
          setOldUser(false);
        }
      });
  };
  useEffect(() => {
    asyncFetch();
    fetchLineData();
    

  }, [initialDayVal, oldUser]);
  useEffect(()=>{
    fetchEPMUsers();
  },[])

  const asyncFetch = () => {
    if (oldUser) {
      initialDayVal === "day"
        ? setData([
            {
              name: "Requested",
              xField: "Monday",
              yField: 56.9,
            },
            {
              name: "Requested",
              xField: "Tuesday",
              yField: 78.8,
            },
            {
              name: "Requested",
              xField: "Wednesday",
              yField: 83.3,
            },
            {
              name: "Requested",
              xField: "Thursday",
              yField: 81.4,
            },
            {
              name: "Requested",
              xField: "Friday",
              yField: 87,
            },
            {
              name: "Requested",
              xField: "Saturday",
              yField: 89.3,
            },
            {
              name: "Requested",
              xField: "Sunday",
              yField: 91,
            },

            {
              name: "Accepted",
              xField: "Monday",
              yField: 34.5,
            },
            {
              name: "Accepted",
              xField: "Tuesday",
              yField: 69.7,
            },
            {
              name: "Accepted",
              xField: "Wednesday",
              yField: 52.6,
            },
            {
              name: "Accepted",
              xField: "Thursday",
              yField: 76.5,
            },
            {
              name: "Accepted",
              xField: "Friday",
              yField: 37.4,
            },
            {
              name: "Accepted",
              xField: "Saturday",
              yField: 70.4,
            },
            {
              name: "Accepted",
              xField: "Sunday",
              yField: 88.4,
            },

            {
              name: "Rejected",
              xField: "Monday",
              yField: 12.4,
            },
            {
              name: "Rejected",
              xField: "Tuesday",
              yField: 12.4,
            },
            {
              name: "Rejected",
              xField: "Wednesday",
              yField: 24.4,
            },
            {
              name: "Rejected",
              xField: "Thursday",
              yField: 12.4,
            },
            {
              name: "Rejected",
              xField: "Friday",
              yField: 24.4,
            },
            {
              name: "Rejected",
              xField: "Saturday",
              yField: 16.4,
            },
            {
              name: "Rejected",
              xField: "Sunday",
              yField: 54,
            },
          ])
        : initialDayVal === "month"
        ? setData([
            {
              name: "Requested",
              xField: "01-01-2024",
              yField: 18.9,
            },
            {
              name: "Accepted",
              xField: "01-01-2024",
              yField: 10.4,
            },
            {
              name: "Rejected",
              xField: "01-01-2024",
              yField: 11.4,
            },
            {
              name: "Requested",
              xField: "01-02-2024",
              yField: 28.8,
            },
            {
              name: "Accepted",
              xField: "01-02-2024",
              yField: 23.2,
            },
            {
              name: "Rejected",
              xField: "01-02-2024",
              yField: 29.4,
            },
            {
              name: "Requested",
              xField: "01-03-2024",
              yField: 39.3,
            },
            {
              name: "Accepted",
              xField: "01-03-2024",
              yField: 34.5,
            },
            {
              name: "Rejected",
              xField: "01-03-2024",
              yField: 12.4,
            },
            {
              name: "Requested",
              xField: "01-04-2024",
              yField: 81.4,
            },
            {
              name: "Accepted",
              xField: "01-04-2024",
              yField: 78.7,
            },
            {
              name: "Rejected",
              xField: "01-04-2024",
              yField: 31.4,
            },
            {
              name: "Requested",
              xField: "01-05-2024",
              yField: 67,
            },
            {
              name: "Accepted",
              xField: "01-05-2024",
              yField: 52.6,
            },
            {
              name: "Rejected",
              xField: "01-05-2024",
              yField: 24.4,
            },
            {
              name: "Requested",
              xField: "01-06-2024",
              yField: 99.7,
            },
            {
              name: "Accepted",
              xField: "01-06-2024",
              yField: 55.5,
            },
            {
              name: "Rejected",
              xField: "01-06-2024",
              yField: 14.4,
            },

            {
              name: "Requested",
              xField: "01-07-2024",
              yField: 46,
            },
            {
              name: "Accepted",
              xField: "01-07-2024",
              yField: 37.4,
            },
            {
              name: "Rejected",
              xField: "01-07-2024",
              yField: 6.4,
            },
            {
              name: "Requested",
              xField: "01-08-2024",
              yField: 35.6,
            },
            {
              name: "Accepted",
              xField: "01-08-2024",
              yField: 42.4,
            },
            {
              name: "Rejected",
              xField: "01-08-2024",
              yField: 42.4,
            },
            {
              name: "Requested",
              xField: "01-09-2024",
              yField: 35.6,
            },
            {
              name: "Accepted",
              xField: "01-09-2024",
              yField: 12.4,
            },
            {
              name: "Rejected",
              xField: "01-09-2024",
              yField: 1.4,
            },
            {
              name: "Requested",
              xField: "01-10-2024",
              yField: 76.4,
            },
            {
              name: "Accepted",
              xField: "01-10-2024",
              yField: 35.6,
            },
            {
              name: "Rejected",
              xField: "01-10-2024",
              yField: 16.6,
            },

            {
              name: "Requested",
              xField: "01-11-2024",
              yField: 65.6,
            },
            {
              name: "Accepted",
              xField: "01-11-2024",
              yField: 52.4,
            },
            {
              name: "Rejected",
              xField: "01-11-2024",
              yField: 15.6,
            },

            {
              name: "Requested",
              xField: "01-12-2024",
              yField: 98.4,
            },

            {
              name: "Accepted",
              xField: "01-12-2024",
              yField: 67.4,
            },
            {
              name: "Rejected",
              xField: "01-12-2024",
              yField: 35.6,
            },
            {
              name: "Requested",
              xField: "01-13-2024",
              yField: 89.2,
            },

            {
              name: "Accepted",
              xField: "01-13-2024",
              yField: 77.4,
            },
            {
              name: "Rejected",
              xField: "01-13-2024",
              yField: 15.6,
            },

            {
              name: "Requested",
              xField: "01-14-2024",
              yField: 95.6,
            },
            {
              name: "Accepted",
              xField: "01-14-2024",
              yField: 64.4,
            },
            {
              name: "Rejected",
              xField: "01-14-2024",
              yField: 35.6,
            },
            {
              name: "Requested",
              xField: "01-15-2024",
              yField: 69.6,
            },

            {
              name: "Accepted",
              xField: "01-15-2024",
              yField: 35.6,
            },
            {
              name: "Rejected",
              xField: "01-15-2024",
              yField: 12.6,
            },
            {
              name: "Requested",
              xField: "01-16-2024",
              yField: 75.6,
            },
            {
              name: "Accepted",
              xField: "01-16-2024",
              yField: 35.6,
            },

            {
              name: "Rejected",
              xField: "01-16-2024",
              yField: 15.6,
            },
            {
              name: "Requested",
              xField: "01-17-2024",
              yField: 85.6,
            },
            {
              name: "Accepted",
              xField: "01-17-2024",
              yField: 35.6,
            },

            {
              name: "Rejected",
              xField: "01-17-2024",
              yField: 12.6,
            },

            {
              name: "Requested",
              xField: "01-18-2024",
              yField: 99.6,
            },
            {
              name: "Accepted",
              xField: "01-18-2024",
              yField: 77.6,
            },
            {
              name: "Rejected",
              xField: "01-18-2024",
              yField: 11.6,
            },
            {
              name: "Rejected",
              xField: "01-19-2024",
              yField: 35.6,
            },
            {
              name: "Requested",
              xField: "01-19-2024",
              yField: 65.6,
            },
            {
              name: "Accepted",
              xField: "01-19-2024",
              yField: 25.6,
            },
            {
              name: "Rejected",
              xField: "01-20-2024",
              yField: 5.6,
            },
            {
              name: "Requested",
              xField: "01-20-2024",
              yField: 69.6,
            },
            {
              name: "Accepted",
              xField: "01-20-2024",
              yField: 45.6,
            },
            {
              name: "Rejected",
              xField: "01-21-2024",
              yField: 5.8,
            },
            {
              name: "Requested",
              xField: "01-21-2024",
              yField: 69.6,
            },
            {
              name: "Accepted",
              xField: "01-21-2024",
              yField: 55.6,
            },

            {
              name: "Requested",
              xField: "01-22-2024",
              yField: 63.6,
            },
            {
              name: "Accepted",
              xField: "01-22-2024",
              yField: 12.6,
            },
            {
              name: "Rejected",
              xField: "01-22-2024",
              yField: 3.6,
            },

            {
              name: "Requested",
              xField: "01-23-2024",
              yField: 79,
            },
            {
              name: "Accepted",
              xField: "01-23-2024",
              yField: 35.6,
            },
            {
              name: "Rejected",
              xField: "01-23-2024",
              yField: 15.6,
            },
            {
              name: "Requested",
              xField: "01-24-2024",
              yField: 67.6,
            },
            {
              name: "Accepted",
              xField: "01-24-2024",
              yField: 35.6,
            },

            {
              name: "Rejected",
              xField: "01-24-2024",
              yField: 35.6,
            },
            {
              name: "Requested",
              xField: "01-25-2024",
              yField: 96.6,
            },

            {
              name: "Accepted",
              xField: "01-25-2024",
              yField: 35.6,
            },
            {
              name: "Rejected",
              xField: "01-25-2024",
              yField: 16.6,
            },
            {
              name: "Requested",
              xField: "01-26-2024",
              yField: 78.6,
            },
            {
              name: "Accepted",
              xField: "01-26-2024",
              yField: 35.6,
            },
            {
              name: "Rejected",
              xField: "01-26-2024",
              yField: 15.6,
            },

            {
              name: "Requested",
              xField: "01-27-2024",
              yField: 91.6,
            },
            {
              name: "Accepted",
              xField: "01-27-2024",
              yField: 81.6,
            },
            {
              name: "Accepted",
              xField: "01-27-2024",
              yField: 10.6,
            },

            {
              name: "Requested",
              xField: "01-28-2024",
              yField: 85.6,
            },
            {
              name: "Accepted",
              xField: "01-28-2024",
              yField: 65.6,
            },
            {
              name: "Rejected",
              xField: "01-28-2024",
              yField: 15.6,
            },

            {
              name: "Requested",
              xField: "01-29-2024",
              yField: 75.6,
            },
            {
              name: "Accepted",
              xField: "01-29-2024",
              yField: 45.6,
            },
            {
              name: "Rejected",
              xField: "01-29-2024",
              yField: 35.6,
            },

            {
              name: "Requested",
              xField: "01-30-2024",
              yField: 99.6,
            },
            {
              name: "Accepted",
              xField: "01-30-2024",
              yField: 85.6,
            },
            {
              name: "Rejected",
              xField: "01-30-2024",
              yField: 14.6,
            },
          ])
        : setData([
            {
              name: "Requested",
              xField: "10 mins",
              yField: 78.9,
            },
            {
              name: "Requested",
              xField: "20 mins",
              yField: 79.8,
            },
            {
              name: "Requested",
              xField: "30 mins",
              yField: 80.3,
            },
            {
              name: "Requested",
              xField: "40 mins",
              yField: 85.4,
            },
            {
              name: "Requested",
              xField: "50 mins",
              yField: 90,
            },
            {
              name: "Requested",
              xField: "60 mins",
              yField: 97.3,
            },

            {
              name: "Accepted",
              xField: "10 mins",
              yField: 53.5,
            },
            {
              name: "Accepted",
              xField: "20 mins",
              yField: 64.7,
            },
            {
              name: "Accepted",
              xField: "30 mins",
              yField: 68.6,
            },
            {
              name: "Accepted",
              xField: "40 mins",
              yField: 64.5,
            },
            {
              name: "Accepted",
              xField: "50 mins",
              yField: 68.4,
            },
            {
              name: "Accepted",
              xField: "60 mins",
              yField: 70.4,
            },

            {
              name: "Rejected",
              xField: "10 mins",
              yField: 12.4,
            },
            {
              name: "Rejected",
              xField: "20 mins",
              yField: 18.4,
            },
            {
              name: "Rejected",
              xField: "30 mins",
              yField: 4.4,
            },
            {
              name: "Rejected",
              xField: "40 mins",
              yField: 14.4,
            },
            {
              name: "Rejected",
              xField: "50 mins",
              yField: 24.4,
            },
            {
              name: "Rejected",
              xField: "60 mins",
              yField: 12.4,
            },
          ]);
    } else {
      initialDayVal === "day"
        ? setData([
            {
              name: "Requested",
              xField: "Monday",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "Tuesday",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "Wednesday",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "Thursday",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "Friday",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "Saturday",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "Sunday",
              yField: 0,
            },

            {
              name: "Accepted",
              xField: "Monday",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "Tuesday",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "Wednesday",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "Thursday",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "Friday",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "Saturday",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "Sunday",
              yField: 0,
            },

            {
              name: "Rejected",
              xField: "Monday",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "Tuesday",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "Wednesday",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "Thursday",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "Friday",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "Saturday",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "Sunday",
              yField: 0,
            },
          ])
        : initialDayVal === "month"
        ? setData([
            {
              name: "Requested",
              xField: "01-01-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-01-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-01-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-02-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-02-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-02-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-03-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-03-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-03-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-04-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-04-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-04-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-05-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-05-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-05-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-06-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-06-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-06-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-07-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-07-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-07-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-08-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-08-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-08-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-09-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-09-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-09-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-10-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-10-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-10-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-11-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-11-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-11-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-12-2024",
              yField: 0,
            },

            {
              name: "Accepted",
              xField: "01-12-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-12-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-13-2024",
              yField: 0,
            },

            {
              name: "Accepted",
              xField: "01-13-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-13-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-14-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-14-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-14-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-15-2024",
              yField: 0,
            },

            {
              name: "Accepted",
              xField: "01-15-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-15-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-16-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-16-2024",
              yField: 0,
            },

            {
              name: "Rejected",
              xField: "01-16-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-17-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-17-2024",
              yField: 0,
            },

            {
              name: "Rejected",
              xField: "01-17-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-18-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-18-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-18-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-19-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-19-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-19-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-20-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-20-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-20-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-21-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-21-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-21-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-22-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-22-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-22-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-23-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-23-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-23-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-24-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-24-2024",
              yField: 0,
            },

            {
              name: "Rejected",
              xField: "01-24-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-25-2024",
              yField: 0,
            },

            {
              name: "Accepted",
              xField: "01-25-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-25-2024",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "01-26-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-26-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-26-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-27-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-27-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-27-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-28-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-28-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-28-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-29-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-29-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-29-2024",
              yField: 0,
            },

            {
              name: "Requested",
              xField: "01-30-2024",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "01-30-2024",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "01-30-2024",
              yField: 0,
            },
          ])
        : setData([
            {
              name: "Requested",
              xField: "10 mins",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "20 mins",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "30 mins",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "40 mins",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "50 mins",
              yField: 0,
            },
            {
              name: "Requested",
              xField: "60 mins",
              yField: 0,
            },

            {
              name: "Accepted",
              xField: "10 mins",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "20 mins",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "30 mins",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "40 mins",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "50 mins",
              yField: 0,
            },
            {
              name: "Accepted",
              xField: "60 mins",
              yField: 0,
            },

            {
              name: "Rejected",
              xField: "10 mins",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "20 mins",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "30 mins",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "40 mins",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "50 mins",
              yField: 0,
            },
            {
              name: "Rejected",
              xField: "60 mins",
              yField: 0,
            },
          ]);
    }
  };
  const fetchLineData = () => {
    if (oldUser) {
      initialDayVal === "day"
        ? setLineData([
            { year: "Monday", value: 3 },
            { year: "Tuesday", value: 4 },
            { year: "Wednesday", value: 3.5 },
            { year: "Thursday", value: 5 },
            { year: "Friday", value: 4.9 },
            { year: "Saturday", value: 6 },
            { year: "Sunday", value: 7 },
          ])
        : initialDayVal === "month"
        ? setLineData([
            { year: "01-01-2024", value: 3 },
            { year: "01-05-2024", value: 7 },
            { year: "01-10-2014", value: 12 },
            { year: "01-15-2024", value: 3.5 },
            { year: "01-20-2024", value: 5 },
            { year: "01-25-2024", value: 4.9 },
            { year: "01-30-2024", value: 6 },
          ])
        : setLineData([
            { year: "10 mins", value: 4 },
            { year: "20 mins", value: 2 },
            { year: "30 mins", value: 3.5 },
            { year: "40 mins", value: 5.5 },
            { year: "50 mins", value: 6 },
            { year: "60 mins", value: 5.7 },
          ]);
    } else {
      initialDayVal === "day"
        ? setLineData([
            { year: "Monday", value: 0 },
            { year: "Tuesday", value: 0 },
            { year: "Wednesday", value: 0 },
            { year: "Thursday", value: 0 },
            { year: "Friday", value: 0 },
            { year: "Saturday", value: 0 },
            { year: "Sunday", value: 0 },
          ])
        : initialDayVal === "month"
        ? setLineData([
            { year: "01-01-2024", value: 0 },
            { year: "01-05-2024", value: 0 },
            { year: "01-10-2014", value: 0 },
            { year: "01-15-2024", value: 30 },
            { year: "01-20-2024", value: 0 },
            { year: "01-25-2024", value: 0 },
            { year: "01-30-2024", value: 0 },
          ])
        : setLineData([
            { year: "10 mins", value: 0 },
            { year: "20 mins", value: 0 },
            { year: "30 mins", value: 0 },
            { year: "40 mins", value: 0 },
            { year: "50 mins", value: 0 },
            { year: "60 mins", value: 0 },
          ]);
    }
  };
  const barConfig = {
    data,
    group: true,
    xField: "xField",
    yField: "yField",
    colorField: "name",
    color: ["#2389FF", "#0DCCCC", "#FF5722"],
  };
  const config = {
    data: lineData,
    xField: "year",
    yField: "value",
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
  };
  return (
    <Row className="content-conatiner">
      {setNotification}

      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Dashboard</h2>
          </Col>
          <Col span={6}>
            <Select
              showSearch
              optionFilterProp="children"
              onChange={(val) => setInitialDayVal(val)}
              defaultValue={initialDayVal}
              onClear
              options={[
                {
                  value: "month",
                  label: "Last 30 Days",
                },
                {
                  value: "day",
                  label: "Last 7 Days",
                },
                {
                  value: "hour",
                  label: "Last Hour",
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              title="Authentication requests over time"
              style={{ marginBottom: 20 }}
            >
              {/* <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={320} /> */}
              <Column {...barConfig} />
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              title=""
              style={{
                padding: "3rem 0",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Authentication Logs
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 20, marginTop: 10 }}>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Link to={"/windows/authnPolicy"}>View all</Link>
          </Col>
        </Row>

        <Row gutter={[16, 24]} style={{ marginBottom: "1rem" }}>
          <Col span={8}>
            <Card title="Policies active over time">
              <Line {...config} height={250} />

              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                {oldUser ? "4200" : "0"}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Unapproved authentication requests">
              <Line {...config} height={250} />
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                {oldUser ? "5000" : "0"}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Tenant Status" className="dashboard-card-container">
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                <Badge
                  status="error"
                  style={{
                    paddingRight: "10px",
                  }}
                />
                Audit Mode
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Total endpoints">
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                {oldUser ? "1200" : "0"}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Directory users">
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                {oldUser ? "1300" : "0"}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Endpoint users">
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                {oldUser ? "10000" : "0"}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Service account users" style={{ height: 450 }}>
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                {oldUser ? "10000" : "0"}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Service account auth over time">
              <Line {...config} height={250} />

              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                {oldUser ? "10000" : "0"}
              </h3>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
