import axios from "axios";
import {
  AUTH_TOKEN,
  REACT_APP_API_OKTA,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_URL,
} from "../constants";

const CheckAuthnMethod = () => {
  let CheckUrl = localStorage.getItem("URL");

  const data = {
    url: CheckUrl,
  };
  axios.post(`${REACT_APP_API_TENANTS_URL}/getAuthMethod`, data).then((res) => {
    let method = res.data.authenticationmethod;
    localStorage.clear();
    if (method.toUpperCase() === "NORMAL") {
      window.location.replace(`https://${CheckUrl}/tenant/login`);
    } else if (method.toUpperCase() === "OKTA") {
      oktaLogout(res.data.ssoUrl);
    } else {
      window.location.replace(`https://${CheckUrl}/tenant/login`);
    }
  });
};

export const logOutUser = () => {
  let CheckUrl = localStorage.getItem("URL");
  
  localStorage.setItem("signedIn", "false");
  if (CheckUrl === "test.authnull.com") {
    localStorage.clear();
    window.location.replace(`https://${CheckUrl}/org/login`);
  } else {
    CheckAuthnMethod();
  }
};

const oktaLogout = (url) => {
  axios
    .get(`${REACT_APP_API_OKTA}/okta/Logout`, {
      headers: {
        withCredentials: true,
        "X-Authorization": AUTH_TOKEN(),
      },
    })
    .then(() => {
      localStorage.clear();
      window.location.replace(url);
    })
    .catch((err) => {
      console.log(err);
    });
};
