import {
  Col,
  Row,
  Select,
  Table,
  Button,
  Input,
  message,
  Dropdown,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  AUTH_TOKEN,
  ORG_ID,
  REACT_APP_API_PAM_URL,
  DOMAIN_ID,
} from "../../../constants";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";

import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;
const ActiveDirectory = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignConnection, setIsAssignConnection] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const PAGESIZE = 10;
  const [filter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  useEffect(() => {
    fetchLog();
  }, [filter, debouncedValue, currentPage]);
  const fetchLog = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage,
      pageSize: PAGESIZE,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: search,
      },
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/listAllActiveDirectories`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data;
        if (res.data) {
          Data = res.data.map((ad, i) => ({
            id: i + 1,
            text: ad.DirectoryName,
            type: ad.IntegrationType,
            userCount: ad.UsersCount.length,
            groupCount: ad.GroupCount.length,
          }));
        }
        setCredentialData(Data);
        setTotalCount(res?.data?.totalGroups);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const columns = [
    { field: "text", headerName: "Directory Name", dataIndex: "text", flex: 1 },
    {
      field: "type",
      headerName: "Integration Type",
      dataIndex: "type",
      flex: 1,
    },
    {
      field: "userCount",
      headerName: "User Count",
      dataIndex: "userCount",
      flex: 1,
    },
    {
      field: "groupCount",
      headerName: "Group Count",
      dataIndex: "groupCount",
      flex: 1,
    },

    // Add dataIndex for other columns if needed
  ];

  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Active Directory</h2>
            </Col>
            <Col>
              <Link to={"addDirectory"}>
                <Button type="primary">Add Directory</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row
                style={{ marginBottom: "2rem", gap: "2rem" }}
                className="search-box-container"
              >
                {/* <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Filter by status"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onFilterChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOption.map((option) => (
                        <Option
                          key={option.value}
                          value={option.value}
                          label={option.key}
                        >
                          {option.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DataGrid
                  components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
            rows={credentialData}
            paginationMode="server"
            columns={columns}
            rowCount={totalCount}
            page={currentPage}
            onPaginationModelChange={(newPage) => {
              setCurrentPage(newPage.page);
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            style={{ border: "none" }}
            loading={isLoading}
          />
          {/* <Table
            loading={isLoading}
            columns={columns}
            dataSource={credentialData}
            pagination={{
              showTotal: (total) => `Total ${total} items`,
              pageSize: PAGESIZE,
              total: totalCount,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
          /> */}
        </Col>
      </Row>
    </>
  );
};

export default ActiveDirectory;
