import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Tag,
  Button,
  Input,
  message,
  Cascader,
  notification,
  Popconfirm,
  Spin,
  Empty,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useLocation, Link, useParams } from "react-router-dom";

import {
  AUTH_TOKEN,
  REACT_APP_API_DID_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import { logOutUser } from "../../../common";
import axios from "axios";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const Context = React.createContext({
  name: "Default",
});

const Connection = () => {
  const [connectionData, setConnectionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [endpoints, setEndpoints] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  let userEmail = localStorage.getItem("UserName");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  // const [notify, contextHolder] = notification.useNotification();
  const [isNotification, setNotification] = message.useMessage();
  const [endpointFilter, setEndpointFilter] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const { publicIpAddress } = useParams();
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();

  let endpointId = [];
  // let found;

  useEffect(() => {
    fetchConnection();
    return () => {
      if (hostAddress) {
        connectionData.some((item) =>
          item.endpoints.some((endpoint) => {
            if (endpoint.publicIpAddress === hostAddress) {
              endpointId = item.endpoints.map((end) => end.instanceId);
              console.log(endpointId);
            }
          })
        );

        console.log(endpointId);
      }
    };
  }, [
    filterBy,
    filteredValue,
    debouncedValue,
    endpointFilter,
    paginationParams,
  ]);
  useEffect(() => {
    fetchInstance();
    fetchWallet();
  }, []);
  const handleDelete = (record) => {
    let connectId = [];
    connectId.push(record.jumpServerConnectionId);
    let payload = {
      orgId: ORG_ID(),
      jumpSeverConnectionId: connectId,
      token: AUTH_TOKEN(),
      domainId: DOMAIN_ID(),
    };
    axios
      .delete(`${REACT_APP_PAM_API}/connections/deleteConnection`, {
        data: payload,
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        fetchConnection();
        isNotification.open({
          type: "success",
          content: "Connection deleted successful",
        });
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "success",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to delete connection",
          });
        }
      });
  };

  const columns = [
    {
      field: "publicIpAddress",
      headerName: "Jump Server Url",
      key: "publicIpAddress",
      flex: 1,
    },
    { field: "user", headerName: "User", key: "user", flex: 1 },
    {
      field: "walletuser",
      headerName: "Wallet User",
      key: "walletuser",
      flex: 1,
      renderCell: (val) => (
        <span>
          <Tag color="blue" key={val.row.walletuser}>
            {val.row.walletuser}
          </Tag>
        </span>
      ),
    },
    { field: "protocol", headerName: "Protocol", key: "protocol", flex: 1 },
    {
      field: "endpoints",
      headerName: "Endpoints",
      key: "endpoints",
      flex: 1,
      renderCell: (val) => (
        <span>
          {val.row.endpoints.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    { field: "status", headerName: "Status", key: "status", flex: 1 },
    {
      field: "connect",
      headerName: "Connect",
      key: "connect",
      flex: 1,
      renderCell: (val) => (
        <span>
          <Link
            disabled={val.row?.status === "false" ? true : false}
            onClick={() => {
              api["info"]({
                message: "Entering Servlet:",
                description:
                  "Please approve credentials to proceed and Session will be recorded.",
                placement: "topLeft",
              });
              return window.open(
                `https://api.authnull.authnull.com/guacamole-servlet/?hostName=${
                  val.row.endpoints[0].publicIpAddress
                }&userName=${val.row.user}&ipAddress=${
                  val.row.publicIpAddress
                }&bearerToken=${AUTH_TOKEN()}&loggedInUser=${userEmail}&protocol=${
                  val.row.protocol
                }&hostlabel=${val.row.endpoints[0].hostname}`,
                `width=1024`,
                `height=768`
              );
            }}
          >
            Connect Now
          </Link>
        </span>
      ),
    },
    {
      field: "delete",
      headerName: "Action",
      key: "delete",
      flex: 1,
      render: (_, record) => (
        <Popconfirm
          title="Sure to Delete Connection?"
          onConfirm={() => handleDelete(record)}
        >
          <DeleteOutlined style={{ color: "red" }} />
        </Popconfirm>
      ),
    },
  ];
  const fetchConnection = () => {
    let pageDetails = {
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      search,
      filter: {
        filterBy: publicIpAddress ? "publicipaddress" : filterBy,
        value: publicIpAddress ?? filteredValue,
      },
      endpoints: filterBy === "endpoint" ? endpointFilter : [],
      token: AUTH_TOKEN(),
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/connections/listConnections`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTotalCount(res?.data?.total);
        const data = res?.data?.jumpServerConnections.map((data, index) => {
          return {
            ...data,
            status: data?.endpoints?.map((i) =>
              i.status === false ? "Inactive" : "Active"
            ),
            id: index + 1,
          };
        });
        setConnectionData(data);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "success",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch connections",
          });
        }
      });
  };
  const fetchInstance = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoints(
          res?.data?.instances.map((ins) => ({
            value: ins.instanceId,
            label: ins.hostName,
            ipAddress: ins.publicIpAddress,
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };
  const fetchWallet = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageNumber: 1,
      pageSize: 100,
      filter: "",
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        console.log(res);
        setWalletData(
          res?.data?.users?.map((user) => ({
            value: user.walletId,
            label: user.emailAddress ? user.emailAddress : "-",
          }))
        );
      });
  };
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const option = [
    {
      value: "endpoint",
      label: "Endpoints",
      children: endpoints,
    },
    {
      value: "walletuser",
      label: "Wallet User",
      children: walletData,
    },
    {
      value: "protocol",
      label: "Protocol",
      children: [
        {
          value: 1,
          label: "SSH",
        },
        {
          value: 2,
          label: "RDP",
        },
        {
          value: 3,
          label: "VNC",
        },
        {
          value: 4,
          label: "Telnet",
        },
      ],
    },
  ];
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
    setEndpointFilter(data ? [data[1]?.value] : []);
    if (paginationParams.current !== 1) {
      setPaginationParams({ current: 1, pageSize: 10 });
    }
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {contextHolder}

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Connections on Jump Server</h2>
            </Col>
            <Col>
              <Link to={"/connections/createConnection"}>
                <Button type="primary">Create Connection</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      {/* <div>
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Endpoint"
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={console.log("c")}
                          onSearch={console.log("a")}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "endpoint",
                              label: "Endpoints",
                            },
                            {
                              value: "endpointUser",
                              label: "Endpoints User",
                            },
                          ]}
                        />
                      </div> */}

                      <Cascader
                        options={option}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (paginationParams.current !== 1) {
                              setPaginationParams({ current: 1, pageSize: 10 });
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* {isLoading ? (
                <Spin
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "200px",
                  }}
                  indicator={<LoadingOutlined style={{ fontSize: 34 }} spin />}
                  size="large"
                />
              ) : ( */}
              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={connectionData}
                paginationMode="server"
                columns={columns}
                rowCount={totalCount}
                page={paginationParams.current}
                onPaginationModelChange={(newPage) => {
                  setPaginationParams({
                    current: newPage.page,
                    ...paginationParams,
                  });
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
              {/* )} */}

              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={connectionData}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      current: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              /> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Connection;
