import React, { useState } from "react";
import "../../../styles/Unauthorized/signup.scss";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, message } from "antd";
import axios from "axios";
import { REACT_APP_API } from "../../../constants";
import { useNavigate } from "react-router";
import ResetPassword from "./ResetPassword";

function Login() {
  const [IsLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [loginDetails, setLoginDetails] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const validateOnboarding = () => {
    //TODO: onboarding api call
    const onBoardingStatus = false; //Dummy variable need to change after API call
    setIsLoading(false);
    if (onBoardingStatus) {
      localStorage.setItem("isUserOnBoarded", false);
      navigate("/onboard");
    } else {
      navigate("/");
    }
  };

  const handleFinish = (values) => {
    setLoginDetails(values);
    const loginData = {
      ...values,
      requestId: localStorage.getItem("requestId"),
      url: localStorage.getItem("URL"),
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API}/okta/normalLogin`, loginData, {
        headers: {
          withCredentials: true,
        },
      })
      .then((res) => {
        console.log(res);

        localStorage.setItem("token", res.data.access_token);
        if (res.data.first_login === "0") {
          setIsResetPassword(true);
        } else {
          validateOnboarding();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to login, Please try again later",
        });
      });
  };

  return (
    <>
      {setNotification}
      {!isResetPassword ? (
        <div className="login-container">
          <div className="login-box">
            <h2 className="title">Sign in to Tenant</h2>

            <Form
              form={form}
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={handleFinish}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email",
                  },
                  {
                    type: "email",
                    message: "Please enter valid email",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <div className="login-form-button">
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={IsLoading}
                  >
                    Sign in
                  </Button>
                  <br />
                </Form.Item>
                <span>Or</span> <br />
                <br />
                <a href="">Forgot password ?</a>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <ResetPassword loginDetails={loginDetails} />
      )}
    </>
  );
}

export default Login;
